import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

const Confirmation = ({ mainQuestion = "", description = "" }) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-center mt-4">
        <FontAwesomeIcon
          icon={faQuestion}
          color="#17a2b8"
          className="mr-1"
          style={{ fontSize: "56" }}
        />
      </div>

      <Modal.Body>
        <div className="d-flex align-items-center justify-content-center mt-2">
          <h4 className="text-muted">{mainQuestion}</h4>
        </div>
        <div className="d-flex align-items-center justify-content-center mt-2">
          <h6>{description}</h6>
        </div>
      </Modal.Body>
    </>
  );
};

export const CustomModal = props => {
  const handleAccept = () => {
    props.onAccept();
  };
  let modalContent = null;
  if (props.type === "confirmation") {
    modalContent = (
      <Confirmation
        mainQuestion={props.mainQuestion}
        description={props.description}
      />
    );
  }
  if (props.type === "component") {
    modalContent = props.component;
  }
  return (
    <Modal
      animation={false}
      show={props.show}
      onHide={() => props.onClose()}
      backdrop="static"
    >
      {modalContent}
      <Modal.Footer as={Row} className="mr-1" style={{ border: "0px" }}>
        <Button
          variant="secondary"
          onClick={() => props.onClose()}
          block
          as={Col}
          lg={5}
          className="mr-1"
        >
          CANCELAR
        </Button>
        <span />
        <Button
          variant="info"
          onClick={handleAccept}
          block
          as={Col}
          lg={6}
          className="mr-1"
        >
          ACEPTAR
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
