import React, {useState} from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImage, faFileVideo } from "@fortawesome/free-solid-svg-icons";
import { UploadFile } from "../commons/upload.file";
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

// ClassicEditor.builtinPlugins.map( plugin => {
//   console.log(plugin.pluginName)
// } );

export const ArtistForm = props => {
  let {
    handleSubmit,
    values,
    touched,
    handleChange,
    handleBlur,
    errors,
    setFieldValue,
    setFieldTouched,
    btnTxt,
    btnIcon
  } = props;

  return (
    <Form className="mb-4 ml-4 mr-2" onSubmit={handleSubmit}>
      <Form.Row as={Row}>
        <Form.Group as={Col} xs={6}>
          <Form.Label>Nombre del Artista</Form.Label>
          <Form.Control
            name="name"
            type="text"
            placeholder="Ingrese el nombre del artista. Ej: Dj Theo"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            isValid={touched.name && !errors.name}
            isInvalid={touched.name && !!errors.name}
          />
          <Form.Control.Feedback>Entrada correcta!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {errors.name}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} xs={6}>
          <Form.Label>Facebook</Form.Label>
          <Form.Control
            name="facebook"
            type="text"
            placeholder="Perfil de Facebook. Ej: djtheo2341"
            value={values.facebook}
            onChange={handleChange}
            onBlur={handleBlur}
            isValid={touched.facebook && !errors.facebook}
            isInvalid={touched.facebook && !!errors.facebook}
          />
          <Form.Control.Feedback>Entrada correcta!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {errors.facebook}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} xs={6}>
          <Form.Label>Twitter</Form.Label>
          <Form.Control
            name="twitter"
            type="text"
            placeholder="Perfil de Twitter. Ej: djtheo2341"
            value={values.twitter}
            onChange={handleChange}
            onBlur={handleBlur}
            isValid={touched.twitter && !errors.twitter}
            isInvalid={touched.twitter && !!errors.twitter}
          />
          <Form.Control.Feedback>Entrada correcta!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {errors.twitter}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} xs={6}>
          <Form.Label>Instagram</Form.Label>
          <Form.Control
            name="instagram"
            type="text"
            placeholder="Perfil de Instagram. Ej: djtheo2341"
            value={values.instagram}
            onChange={handleChange}
            onBlur={handleBlur}
            isValid={touched.instagram && !errors.instagram}
            isInvalid={touched.instagram && !!errors.instagram}
          />
          <Form.Control.Feedback>Entrada correcta!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {errors.instagram}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} xs={12}>
          <Form.Label>Biografía</Form.Label>
          <CKEditor
            editor={ ClassicEditor }
            data={values.biography}
            name="biography"
            config={ {
              removePlugins: [ 'MediaEmbed', 'EasyImage', 'ImageUpload', 'Table' ]
          } }
            onChange={ ( event, editor ) => {
                const data = editor.getData();
                setFieldTouched("biography")
                setFieldValue("biography", data)
            } }
        />
        {(touched.biography && !!errors.biography) && <p style={{marginBottom:'0px', lineHeight:'15px'}}><span className='small text-danger'>{errors.biography}</span></p>}
          {/* <Form.Control
            as="textarea"
            rows="4"
            name="biography"
            value={values.biography}
            onChange={handleChange}
            onBlur={handleBlur}
            isValid={touched.biography && !errors.biography}
            isInvalid={touched.biography && !!errors.biography}
          /> */}
          {/* <Form.Control.Feedback>Entrada correcta!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {errors.biography}
          </Form.Control.Feedback> */}
        </Form.Group>
        <Form.Group as={Col} xs={6}>
          <UploadFile
            icon={faFileImage}
            placeholder="Foto del artista"
            handleChange={setFieldValue}
            value={values.picture}
            name="picture"
            isValid={touched.picture && !errors.picture}
            isInvalid={touched.picture && !!errors.picture}
            errors={errors.picture}
            label="Fotografía"
            key="2"
            handleTouched={setFieldTouched}
          />
        </Form.Group>
        <Form.Group as={Col} xs={6}>
          <UploadFile
            icon={faFileVideo}
            placeholder="Entrevista del artista"
            handleChange={setFieldValue}
            value={values.interview}
            name="interview"
            isValid={touched.interview && !errors.interview}
            isInvalid={touched.interview && !!errors.interview}
            errors={errors.interview}
            label="Entrevista"
            key="1"
            handleTouched={setFieldTouched}
          />
        </Form.Group>
        <Form.Group as={Col} xs={6} className="mr-4">
          <Button variant="info" type="submit">
            <FontAwesomeIcon icon={btnIcon} color="white" className="mr-2" />
            {btnTxt}
          </Button>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};
