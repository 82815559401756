import React, { useContext } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import logo from "../../../images/logo_INBEAT_Color.png";
import whiteLogo from "../../../images/logo_INbeat_White.png";
import iconGmial from "../../../images/GMAIL.png";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import "./site.login.css";
import { Formik } from "formik";
import * as Yup from "yup";
import { SiteAuthContext } from "../../../context/site/siteAuth/site.auth.state";
import { Redirect } from "react-router-dom";

const validationSchema = Yup.object().shape({
  password: Yup.string().required("La contraseña es un campo requerido"),
  email: Yup.string()
    .email("Debe introducir un correo electrónico válido")
    .required("El correo es un campo requerido")
});

export const SiteLogin = () => {
  const {
    login,
    loginError,
    loginErrorMsg,
    doLogin,
    googleLogin,
    facebookLogin
  } = useContext(SiteAuthContext);

  const handleDoSiteLogin = async (values, resetForm, setSubmitting) => {
    await doLogin({ email: values.email, password: values.password });
    if (loginError) {
      resetForm();
      setSubmitting(false);
    }
  };
  const responseGoogle = response => {
    const { email, googleId } = response.profileObj;
    googleLogin({ email, third_party_id: googleId });
  };
  const responseFacebook = response => {
    // Uncomment this in production
    // const { userID } = response;
    const { userID } = response;
    facebookLogin({ third_party_id: userID });
  };

  if (login) {
    return (
      <Redirect
        to={{
          pathname: "/home"
        }}
      />
    );
  }

  return (
    <Row>
      <Col
        lg="6"
        style={{
          minHeight: "100vh",
          maxHeight: "100vh",
          backgroundColor: "#7b2a7c"
        }}
        className="m-0 p-0"
      >
        <div
          style={{ height: "100%", width: "100%" }}
          className="d-flex justify-content-center align-items-center"
        >
          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              handleDoSiteLogin(values, resetForm, setSubmitting);
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setSubmitting
            }) => (
              <Form onSubmit={handleSubmit} method="post">
                {loginError && (
                  <Form.Text
                    className="mt-4 pt-1 mb-4 pb-1"
                    style={{ color: "white", fontSize: "8pt" }}
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <span>{loginErrorMsg}</span>
                    </div>
                  </Form.Text>
                )}
                <Form.Group style={{ minWidth: "25vw" }}>
                  <Form.Label style={{ color: "#C3C0BF", fontSize: "14pt" }}>
                    CORREO ELECTRÓNICO
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Correo electrónico"
                    style={{
                      borderColor: "#606263",
                      backgroundColor: "transparent",
                      color: "white"
                    }}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.email && !errors.email}
                    isInvalid={touched.email && !!errors.email}
                  />
                  <Form.Control.Feedback>
                    Entrada correcta!
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Form.Label style={{ color: "#C3C0BF", fontSize: "14pt" }}>
                    PASSWORD
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Password"
                    name="password"
                    style={{
                      borderColor: "#606263",
                      backgroundColor: "transparent",
                      color: "white"
                    }}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.password && !errors.password}
                    isInvalid={touched.password && !!errors.password}
                  />
                  <Form.Control.Feedback>
                    Entrada correcta!
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Text
                  className="mb-1 float-right"
                  style={{ color: "#C3C0BF", fontSize: "14pt" }}
                >
                  <a
                    href="#/site-recovery-pass"
                    style={{ color: "#C3C0BF", textDecoration: "none" }}
                  >
                    Olvidó su contraseña
                  </a>
                </Form.Text>
                <div style={{ clear: "right" }}></div>
                <Button
                  type="submit"
                  block
                  className="mt-4 box-orange"
                  disabled={isSubmitting}
                >
                  INICIAR SESIÓN
                </Button>
                <Form.Text
                  className="mt-4 pt-1 mb-4 pb-1"
                  style={{ color: "#C3C0BF", fontSize: "14pt" }}
                >
                  <div className="d-flex justify-content-center align-items-center">
                    <span> O conectar con</span>
                  </div>
                </Form.Text>
                <Form.Text
                  className="mt-4 pt-1 mb-4 pb-1"
                  style={{ color: "#C3C0BF", fontSize: "14pt" }}
                >
                  <div className="d-flex justify-content-center align-items-center">
                    <GoogleLogin
                      clientId="536600311982-21j7nlfal3671m21io0956mckmdb5d97.apps.googleusercontent.com"
                      render={renderProps => (
                        <img
                          src={iconGmial}
                          width="40vw"
                          height="40vh"
                          className="mr-4"
                          onClick={renderProps.onClick}
                          disabled={renderProps.disabled}
                          style={{ cursor: "pointer" }}
                          title="Login con Google"
                          alt="google"
                        />
                      )}
                      buttonText="Login"
                      onSuccess={responseGoogle}
                      onFailure={responseGoogle}
                      cookiePolicy={"single_host_origin"}
                    />
                    <FacebookLogin
                      appId="680389002606632"
                      autoLoad={false}
                      callback={responseFacebook}
                      cssClass="my-facebook-button-class"
                      textButton=""
                      icon="fa-facebook"
                      disableMobileRedirect={true}
                      // icon={face}
                      style={{
                        fontFamily:
                          "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
                      }}
                    />
                  </div>
                </Form.Text>
                <Form.Text
                  className="mb-1 d-flex justify-content-center align-items-center"
                  style={{ color: "#C3C0BF" }}
                >
                  <span style={{ color: "#C3C0BF", fontSize: "6pt" }}>
                    Si presenta problemas con su registro o inicio de sesión.
                  </span>
                </Form.Text>
                <Form.Text
                  className="d-flex justify-content-center align-items-center"
                  style={{ color: "#C3C0BF" }}
                >
                  <span style={{ color: "#C3C0BF", fontSize: "6pt" }}>
                    Contáctenos a soporte@inbeat.live
                  </span>
                </Form.Text>
                <Form.Text
                  className="d-flex justify-content-center align-items-center"
                  style={{ color: "#C3C0BF" }}
                >
                <p style={{ color: "#C3C0BF", fontSize: "6pt" }}> 
                  <a href="#/terms-conditions" style={{color:"white"}}> Términos y Condiciones</a>
                </p>   
                </Form.Text>
                <Form.Text className="mt-2 pt-4">
                  <div className="d-flex justify-content-center align-items-center">
                    <img src={whiteLogo} height="45" width="105" alt="INBEAT" />
                  </div>
                </Form.Text>
              </Form>
            )}
          </Formik>
        </div>
      </Col>
      <Col
        lg="6"
        style={{
          minHeight: "100vh",
          maxHeight: "100vh",
          backgroundColor: "#e5e5e5"
        }}
        className="m-0 p-0"
      >
        <div
          style={{ height: "100%" }}
          className="d-flex justify-content-center align-items-center"
        >
          <img src={logo} height="100vh" width="40%" alt="INBEAT" />
        </div>
      </Col>
    </Row>
  );
};
