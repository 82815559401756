import React, { createContext, useReducer } from "react";
import PlayListReducer from "./playlist.reducer";
import { useHttpRequest } from "../../../hooks/useHttpRequest";
import { httpRequest } from "../../utils/http";

const initialState = {
  playlists: [],
  current: {},
  playListForm: {
    show: false,
    action: "add",
    txt: "",
    plid: -1
  }
};

export const PlayListContext = createContext(initialState);

export const PlayListProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PlayListReducer, initialState);
  const { execute } = useHttpRequest(httpRequest, false);

  const getPlayLists = async () => {
    try {
      let formData = new FormData();
      formData.append(
        "user_id",
        JSON.parse(localStorage.getItem("main-site-inbeat")).user_id
      );
      let result = await execute({
        url: "playlist/get",
        data: formData
      });
      dispatch({
        type: "GET_PLAYLISTS",
        payload: result.data
      });
    } catch (e) {
      // showNotification(notificationsMessages.error);
    }
  };
  const showPlayListAction = action => {
    dispatch({
      type: "SHOW_PLAYLIST_ACTION",
      payload: action
    });
  };
  const showPlayListEdit = (plid, name) => {
    dispatch({
      type: "SHOW_PLAYLIST_EDIT",
      payload: { plid, name }
    });
  };
  const hidePlayListAction = () => {
    dispatch({
      type: "HIDE_PLAYLIST_ACTION"
    });
  };
  const addPlayList = async name => {
    try {
      if (state.playListForm.action === "add") {
        let formData = new FormData();
        formData.append(
          "user_id",
          JSON.parse(localStorage.getItem("main-site-inbeat")).user_id
        );
        formData.append("name", name);
        let result = await execute({
          url: "playlist/create_playlist",
          data: formData
        });
        dispatch({
          type: "ADD_PLAYLIST",
          payload: result.data
        });
      }
      if (state.playListForm.action === "edit") {
        let formData = new FormData();
        formData.append(
          "user_id",
          JSON.parse(localStorage.getItem("main-site-inbeat")).user_id
        );
        formData.append("name", name);
        formData.append("playlist_id", state.playListForm.plid);
        await execute({
          url: "playlist/modify_playlist",
          data: formData
        });
        dispatch({
          type: "EDIT_PLAYLIST",
          payload: { plid: state.playListForm.plid, name }
        });
      }
    } catch (e) {
      // showNotification(notificationsMessages.error);
    }
  };
  const removeContentFromPlayList = async (plid, cid) => {
    try {
      let formData = new FormData();
      formData.append(
        "user_id",
        JSON.parse(localStorage.getItem("main-site-inbeat")).user_id
      );
      formData.append("playlist_id", plid);
      formData.append("content_id", cid);
      await execute({
        url: "playlist/remove_content",
        data: formData
      });
      dispatch({
        type: "REMOVE_CONTENT_FROM_PLAYLIST",
        payload: { plid, cid }
      });
    } catch (e) {
      // showNotification(notificationsMessages.error);
    }
  };
  const removePlayList = async plid => {
    try {
      let formData = new FormData();
      formData.append(
        "user_id",
        JSON.parse(localStorage.getItem("main-site-inbeat")).user_id
      );
      formData.append("playlist_id", plid);
      await execute({
        url: "playlist/delete_playlist",
        data: formData
      });
      dispatch({
        type: "REMOVE_PLAYLIST",
        payload: plid
      });
    } catch (e) {
      // showNotification(notificationsMessages.error);
    }
  };
  return (
    <PlayListContext.Provider
      value={{
        playlists: state.playlists,
        playListForm: state.playListForm,
        current: state.current,
        showPlayListAction,
        hidePlayListAction,
        removeContentFromPlayList,
        removePlayList,
        showPlayListEdit,
        addPlayList,
        getPlayLists
      }}
    >
      {children}
    </PlayListContext.Provider>
  );
};
