const getPlayLists = (state, action) => {
  return {
    ...state,
    playlists: action.payload
  };
};
const removePlayList = (state, action) => {
  return {
    ...state,
    playlists: state.playlists.filter(_ => _.id !== action.payload)
  };
};
const removeContentFromPlayList = (state, action) => {
  return {
    ...state,
    playlists: state.playlists.map(_ => {
      if (_.id === action.payload.plid) {
        return {
          ..._,
          content: _.content.filter(_ => _.id !== action.payload.cid)
        };
      }
      return _;
    })
  };
};
const addPlayList = (state, action) => {
  return {
    ...state,
    playListForm: { ...state.playListForm, show: false, action: "add" },
    playlists: [...state.playlists, action.payload]
  };
};
const editPlayList = (state, action) => {
  return {
    ...state,
    playListForm: {
      ...state.playListForm,
      show: false,
      action: "add",
      txt: "",
      plid: -1
    },
    playlists: state.playlists.map(_ => {
      if (_.id === action.payload.plid) {
        return { ..._, name: action.payload.name };
      }
      return _;
    })
  };
};
const showPlayListAction = (state, action) => {
  return {
    ...state,
    playListForm: { ...state.playListForm, show: true, action: action.payload }
  };
};
const showPlayListEdit = (state, action) => {
  return {
    ...state,
    playListForm: {
      ...state.playListForm,
      show: true,
      action: "edit",
      txt: action.payload.name,
      plid: action.payload.plid
    }
  };
};
const hidePlayListAction = (state, action) => {
  return {
    ...state,
    playListForm: { ...state.playListForm, show: false, action: "add" }
  };
};

export default (state, action) => {
  const actions = {
    GET_PLAYLISTS: getPlayLists,
    SHOW_PLAYLIST_ACTION: showPlayListAction,
    HIDE_PLAYLIST_ACTION: hidePlayListAction,
    ADD_PLAYLIST: addPlayList,
    REMOVE_CONTENT_FROM_PLAYLIST: removeContentFromPlayList,
    REMOVE_PLAYLIST: removePlayList,
    EDIT_PLAYLIST: editPlayList,
    SHOW_PLAYLIST_EDIT: showPlayListEdit
  };
  return actions[action.type](state, action) || state;
};
