import React, { useContext, useEffect, useState } from "react";
import { Row, Col, InputGroup, FormControl, Container } from "react-bootstrap";
import { HeaderLogin } from "../landing-page/components/header.login";
import { Footer } from "../landing-page/components/footer";
import { AudioPlayer } from "./audio.player";
import { MusicContext } from "../../../context/site/music/music.state";
import { CustomModal } from "./modal";
import { SiteAuthProvider } from "../../../context/site/siteAuth/site.auth.state";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "./music.css";
import SitePagination from "../commons/pagination";
import { serverPath } from "../../../context/utils/file.path";
import "../commons/paginationWhite.css";
import { LandingMusicBox } from "../landing-page/components/landing.music.box";
import playCircle from "../../../images/play_circulo.png";
const url = `${serverPath}contents/`;

const itemPerPage = 12;
export const Music = () => {
  const {
    music,
    getMusic,
    countReproductions,
    addFavorite,
    removeFavorite,
    voteMusic,
    getPlayLists,
    playlists,
    addToPlayList
  } = useContext(MusicContext);

  let [showModal, setShowModal] = useState(false);
  let [songId, setSongId] = useState(-1);
  let [currentSongInfo, setCurrentSongInfo] = useState({});
  const [filterText, setFilterText] = useState("");
  const [page, setPage] = useState(1);

  const [showMusic, setshowMusic] = useState(false);
  const [src, setSrc] = useState("");
  const [currentSong, setCurrentSong] = useState(-1);

  useEffect(() => {
    getMusic();
    getPlayLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddToPlayList = plid => {
    addToPlayList(songId, plid);
    handleClose();
  };

  const handleShowModal = id => {
    setSongId(id);
    setShowModal(true);
  };
  const handleClose = () => {
    setSongId(-1);
    setShowModal(false);
  };

  const handleToogleClick = (show, content, info) => {
    setshowMusic(show);
    setSrc(content);
    info.artist_name = info.artist
    setCurrentSongInfo(info)
  };
  const handleCloseAudio = () => {
    setCurrentSong(-1);
  };
  function* getColorBox() {
    let counter = 0;
    const map = ["box-yellow", "box-orange", "box-purple", "box-blue"];
    while (true) {
      yield map[counter++];
      if (counter > 3) {
        counter = 0;
      }
    }
  }
  let filteredItems = music.slice(
    (page - 1) * itemPerPage,
    (page - 1) * itemPerPage + itemPerPage
  );
  filteredItems = filteredItems.filter(
    item =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );
  const hasNextPage = () => {
    let tempArray = music.slice(
      page * itemPerPage,
      page * itemPerPage + itemPerPage
    );
    return tempArray.length > 0;
  };
  const gen = getColorBox();
  return (
    <>
      {showMusic && (
        <LandingMusicBox
          showMusic={setshowMusic}
          src={`${url}${src}`}
          onClose={handleCloseAudio}
          info = {currentSongInfo}
        />
      )}
      <SiteAuthProvider>
        <HeaderLogin />
      </SiteAuthProvider>
      <div className="top-rated" style={{ backgroundColor: "#e5e5e5" }}>
        <Container>
          <Row className="ml-4 mb-4">
            <Col
              lg={{ span: 3, offset: 8 }}
              xs={{ span: 8, offset: 3 }}
              style={{ paddingRight: "0" }}
            >
              <InputGroup className="mt-2 mb-1">
                <FormControl
                  id="inlineFormInputGroup"
                  size="sm"
                  type="text"
                  placeholder="Buscar música"
                  value={filterText}
                  onChange={e => setFilterText(e.target.value)}
                />
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FontAwesomeIcon
                      icon={faSearch}
                      color="13133c"
                      className="mr-1"
                    />
                  </InputGroup.Text>
                </InputGroup.Prepend>
              </InputGroup>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="ml-1 mr-1">
            {filteredItems.map((song, index) => {
              return (
                <Col
                  lg={{ offset: 1, span: 10 }}
                  className="mt-2 col-music"
                  key={song.id}
                  style={{
                    backgroundColor: "#e5e5e5",
                    paddingLeft: 0,
                    paddingRight: 0,
                    marginBottom: "20px"
                  }}
                >
                  <div className="d-flex aling-items-center justify-content-between img-comtainer-music">
                    
                    {/* <img
                      src={`${url}${song.image}`}
                      alt="Artista"
                      className="song-img-music"
                    />{" "} */}
                    
         <div style={{width: "165px", height: "105.330px", backgroundImage: `url("${url}${song.image}")`, backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}/>
                    {currentSong !== song.id && (
                      <img
                        src={playCircle}
                        width="40px"
                        height="50px"
                        title="Reproducir"
                        alt="Reproducir"
                        style={{
                          position: "absolute",
                          top: "30px",
                          left: "70px"
                        }}
                        onClick={() => {
                          handleToogleClick(true, song.content, song);
                          setCurrentSong(song.id);
                          countReproductions(song.id);
                        }}
                        className="pointer img-music"
                      />
                    )}
                    <div className="ml-2 nameAuthor" style={{ height: "80%" }}>
                      <p
                        style={{
                          marginBottom: 0,
                          color: "#14123c",
                          fontWeight: "bolder",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "28vw"
                        }}
                        className="txt-music"
                      >
                        {song.name}
                      </p>
                      <p
                        style={{
                          marginBottom: 0,
                          color: "#5a587",
                          fontWeight: "bolder",

                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "28vw"
                        }}
                        className="subtxt-music"
                      >
                        De: {song.artist}
                      </p>
                    </div>
                    <AudioPlayer
                      content={song.content}
                      id={song.id}
                      artist={song.name}
                      color={gen.next().value}
                      countReproductions={countReproductions}
                      isFavorite={song.is_favorite}
                      addFavorite={addFavorite}
                      removeFavorite={removeFavorite}
                      voteMusic={voteMusic}
                      handleShowModal={handleShowModal}
                      handleClick={handleToogleClick}
                      current={currentSong}
                      setSong={setCurrentSong}
                      userVoted={song.user_voted}
                    />
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
        <Row>
          <Col lg={{ span: 12, offset: 0 }} className="mt-2 mb-2">
            <div className="d-flex justify-content-center align-items-center">
              <SitePagination
                page={page}
                setPage={setPage}
                hasNextPage={hasNextPage}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
      <CustomModal
        show={showModal}
        playlists={playlists}
        handleClose={handleClose}
        handleAddToPlayList={handleAddToPlayList}
      />
    </>
  );
};
