import React, { createContext, useReducer } from "react";
import PromotedReducer from "./promoted.reducer";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useModal } from "../../hooks/useModal";
import { useNotification } from "../../hooks/useNotification";
import { httpRequest } from "../utils/http";

const FILE_SIZE = 2097152;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const initialState = {
  promotions: [],
  contentFiltered: [],
  isFiltering: false,
  countPromotes: 0,
  modalConfig: {
    type: "confirmation",
    mainQuestion: "",
    description: "",
    action: "idle",
    component: null,
    table: ""
  },
  image: null,
  isInvalid: false,
  errors: "",
  currentTable: ""
};

const notificationsMessages = {
  error: {
    msg: "Error. Oopps! Algo salió mal!",
    status: "dark"
  },
  promote: {
    msg: "Éxito. El contenido se ha promocionado satisfactoriamente!",
    status: "success"
  },
  unpromote: {
    msg: "Éxito. El contenido se ha dejado de promocionar!",
    status: "success"
  }
};

export const PromotedContext = createContext(initialState);

export const PromotedProvider = ({ children }) => {
  const [state, dispatch] = useReducer(PromotedReducer, initialState);
  const { execute, loading } = useHttpRequest(httpRequest, false);
  const { showModal, show, action } = useModal();
  const { showNotification, notify, notificationConfig } = useNotification();

  const getPromotions = async () => {
    try {
      let result = await execute({ url: "admin/get_promotions" });
      dispatch({
        type: "GET_PROMOTIONS",
        payload: result.data
      });
    } catch (e) {
      showNotification(notificationsMessages.error);
    }
  };

  const doActions = async () => {
    try {
      if (!state.image && state.modalConfig.action === "promote") {
        dispatch({
          type: "IMAGE_VALIDATE",
          payload: "require"
        });
        return;
      }
      if (state.modalConfig.action === "promote" && state.isInvalid) {
        return;
      }
      showNotification();
      showModal();
      const endpoint = {
        unpromote: "promotions/unpromote",
        promote: "promotions/promote"
      };
      let payload = {};
      if (state.modalConfig.action === "promote") {
        let formData = new FormData();
        formData.append("content_id", state.modalConfig.id);
        formData.append("picture", state.image);
        formData.append("table", state.currentTable);
        await execute({
          url: endpoint[state.modalConfig.action],
          data: formData
        });
        const promotions = await execute({ url: "admin/get_promotions" });
        payload = {
          eid: state.modalConfig.id,
          action: state.modalConfig.action,
          promotions: promotions.data
        };
      }
      if (state.modalConfig.action === "unpromote") {
        let formData = new FormData();
        formData.append("promotion_id", state.modalConfig.id);
        await execute({
          url: endpoint[state.modalConfig.action],
          data: formData
        });
        payload = {
          eid: state.modalConfig.id,
          action: state.modalConfig.action,
          table: state.modalConfig.table
        };
      }
      dispatch({
        type: "DO_ACTIONS",
        payload
      });
      showNotification(notificationsMessages[action]);
    } catch (e) {
      showNotification(notificationsMessages.error);
    }
  };
  const filterContent = async (text, cathegory) => {
    try {
      showNotification();
      setFilteringToTrue();
      let formData = new FormData();
      formData.append("text", text);
      formData.append("table", cathegory);

      const result = await execute({
        url: "promotions/get_contents",
        data: formData
      });
      dispatch({
        type: "FILTER_CONTENT",
        payload: { data: result.data, table: cathegory }
      });
    } catch (error) {
      showNotification(notificationsMessages.error);
    }
  };
  const setFilteringToTrue = () => {
    dispatch({
      type: "SET_FILTERING_TO_TRUE"
    });
  };
  const showModalWithConfig = (option, id, component = null, table) => {
    dispatch({
      type: "SET_MODAL_OPTION",
      payload: { option, id, component, table }
    });
    showModal(option, id);
  };
  const handleImageChange = img => {
    let hasError = false;
    if (!img) {
      hasError = true;
      dispatch({
        type: "IMAGE_VALIDATE",
        payload: "require"
      });
    }
    if (!hasError && img && !SUPPORTED_FORMATS.includes(img.type)) {
      hasError = true;
      dispatch({
        type: "IMAGE_VALIDATE",
        payload: "format"
      });
    }
    if (!hasError && img && img.size > FILE_SIZE) {
      hasError = true;
      dispatch({
        type: "IMAGE_VALIDATE",
        payload: "size"
      });
    }
    dispatch({
      type: "SET_IMAGE",
      payload: { img, hasError }
    });
  };
  return (
    <PromotedContext.Provider
      value={{
        promotions: state.promotions,
        contentFiltered: state.contentFiltered,
        isFiltering: state.isFiltering,
        countPromotes: state.countPromotes,
        modalConfig: state.modalConfig,
        image: state.image,
        isInvalid: state.isInvalid,
        errors: state.errors,
        isLoading: loading,
        getPromotions,
        doActions,
        notify,
        notificationConfig,
        show,
        showModal,
        showNotification,
        filterContent,
        showModalWithConfig,
        handleImageChange
      }}
    >
      {children}
    </PromotedContext.Provider>
  );
};
