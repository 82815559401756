import React, { useContext, useEffect, useState } from "react";
import { Row, Col, InputGroup, FormControl, Container } from "react-bootstrap";
import { HeaderLogin } from "../landing-page/components/header.login";
import { Footer } from "../landing-page/components/footer";
import { ArtistContext } from "../../../context/site/artist/artist.state";
import { serverPath } from "../../../context/utils/file.path";
import { SiteAuthProvider } from "../../../context/site/siteAuth/site.auth.state";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import SitePagination from "../commons/pagination";
const urlContents = `${serverPath}artists/`;
const itemPerPage = 12;
export const Artist = () => {
  const { artists, getArtists } = useContext(ArtistContext);
  const [filterText, setFilterText] = useState("");
  const [page, setPage] = useState(1);

  useEffect(() => {
    getArtists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function* getColorBox() {
    let counter = 0;
    const map = ["box-yellow", "box-orange", "box-purple", "box-blue"];
    while (true) {
      yield map[counter++];
      if (counter > 3) {
        counter = 0;
      }
    }
  }
  const gen = getColorBox();
  let filteredItems = artists.slice(
    (page - 1) * itemPerPage,
    (page - 1) * itemPerPage + itemPerPage
  );
  filteredItems = artists.filter(
    item =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );
  const hasNextPage = () => {
    let tempArray = artists.slice(
      page * itemPerPage,
      page * itemPerPage + itemPerPage
    );
    return tempArray.length > 0;
  };
  return (
    <>
      <SiteAuthProvider>
        <HeaderLogin />
      </SiteAuthProvider>
      <div className="single-news">
        <Container>
          <Row className="ml-4">
            <Col
              lg={{ span: 3, offset: 8 }}
              xs={{ span: 8, offset: 3 }}
              style={{ paddingRight: "0" }}
            >
              <InputGroup className="mt-2 mb-1">
                <FormControl
                  id="inlineFormInputGroup"
                  size="sm"
                  type="text"
                  placeholder="Buscar artista"
                  value={filterText}
                  onChange={e => setFilterText(e.target.value)}
                />
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FontAwesomeIcon
                      icon={faSearch}
                      color="13133c"
                      className="mr-1"
                    />
                  </InputGroup.Text>
                </InputGroup.Prepend>
              </InputGroup>
            </Col>
          </Row>
        </Container>
        <Row className="ml-1 mr-1">
          {filteredItems.map((artist, index) => {
            const lg =
              index % 4 === 0 ? { span: 2, offset: 2 } : { span: 2, offset: 0 };
            return (
              <Col lg={lg} className="mt-4 video-img-size" key={artist.id}>
                <div className="w-100 h-100 pos-relative">
                  <a href={`#/site-artist/${artist.id}`}>
                    {/* <img
                      src={`${urlContents}${artist.picture}`}
                      alt="corazon"
                      height="80%"
                      width="100%"
                    /> */}
                    <div style={{width: "100%", height:"80%", backgroundImage: `url("${urlContents}${artist.picture}")`, backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }} />
                  </a>
                  <div
                    className={`d-flex justify-content-between align-items-center player-box ${
                      gen.next().value
                    }`}
                  >
                    <div></div>
                    <span
                      className="music-song-title"
                      style={{ fontSize: "11pt" }}
                    >
                      <a href={`#/site-artist/${artist.id}`} style={{textDecoration: 'none', color:'white'}}>
                        {artist.name}
                      </a>
                    </span>
                    <div></div>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col lg={{ span: 12, offset: 0 }} className="mt-2 mb-2">
            <div className="d-flex justify-content-center align-items-center">
              <SitePagination
                page={page}
                setPage={setPage}
                hasNextPage={hasNextPage}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};
