import React from "react";
import ReactLoading from "react-loading";

const LoadingPage = WrappedComponent => props => {
  let condition = null;
  if (props.data instanceof Array) {
    condition = props.data.length === 0;
  }
  if (typeof props.data === "boolean") {
    condition = props.data === true;
  }
  const dimensions = props.dimensions || 3;
  const msg = props.msg || "Espere. Por favor ...";
  return condition ? (
    <div
      className="p-3 d-flex align-items-center justify-content-center mb-5"
      style={{ height: "50vh" }}
    >
      <ReactLoading
        type="spin"
        color="#9e9e9e"
        height={`${dimensions}%`}
        width={`${dimensions}%`}
        className="mr-1 mb-5"
      />
      <h5 className="text-uppercase mb-3 ml-2 font-weight-bold">{msg}</h5>
    </div>
  ) : (
    <WrappedComponent {...props} />
  );
};
export default LoadingPage;
