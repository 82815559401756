import React from "react";
import { CustomActions } from "./table.actions";
import { serverPath } from "../../context/utils/file.path";
const url = `${serverPath}`;

const Columns = [
  {
    // name: "Imagen",
    grow: 0,
    cell: row => (
      <img
        height="84px"
        width="56px"
        alt={row.name}
        src={`${url}contents/${row.image}`}
      />
    )
  },
  {
    name: "Nombre",
    cell: row => (
      <a
        href={`${url}contents/${row.content}`}
        target="_blank"
        rel="noopener noreferrer"
        download
      >
        {row.name}
      </a>
    )
  },
  {
    name: "Artista",
    selector: "artist_name"
  },
  {
    name: "Creado",
    cell: row => new Date(row.created_at).toLocaleDateString()
  },
  {
    name: "Preview",
    cell: row => (
      <a
        href={`${url}contents/${row.preview}`}
        target="_blank"
        rel="noopener noreferrer"
        download
      >
        Preview
      </a>
    )
  },
  {
    name: "Géneros",
    cell: row => row.genres.map(_ => _.name).join(", "),
    wrap: true,
    width: "25%"
  },
  {
    name: "Estado",
    cell: row => <StatusBadge status={row.status_name} />
  },
  {
    name: "Acciones",
    cell: row => <CustomActions id={row.id} status={row.status_name} />
  }
];

const StatusBadge = ({ status }) => {
  const mapClass = {
    Activo: "badge-success",
    Eliminado: "badge-danger"
  };
  return (
    <span className={`badge ${mapClass[status]}`}>{status.toUpperCase()}</span>
  );
};

const paginationOptions = {
  rowsPerPageText: "Filas por página",
  rangeSeparatorText: "de",
  selectAllRowsItem: true,
  selectAllRowsItemText: "Todos"
};

export { Columns, paginationOptions };
