import React from "react";
import { GenresCard } from "./genres.card";
import { Row } from "react-bootstrap";

export const CardList = props => {
  const { categories, subHeaderFilterForm, handleShowModal } = props;
  return (
    <>
      {subHeaderFilterForm}
      <Row>
        {categories.map((cat, index) => {
          return (
            <GenresCard
              key={cat.id}
              name={cat.name}
              date={new Date(cat.created_at)}
              status={cat.status}
              showModal={handleShowModal}
              id={cat.id}
            />
          );
        })}
      </Row>
    </>
  );
};
