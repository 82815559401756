import React from "react";
import { Pagination } from "react-bootstrap";
import "./pagination.css";
export default function SitePagination({ page, setPage, hasNextPage }) {
  function adjustPage(amount) {
    setPage(prevPage => prevPage + amount);
  }

  return (
    <Pagination bsPrefix="marlon">
      {page !== 1 && <Pagination.Prev onClick={() => adjustPage(-1)} />}
      {page !== 1 && (
        <Pagination.Item onClick={() => setPage(1)} className={'paginationItemStyle'} id="marlon">1</Pagination.Item>
      )}
      {page > 2 && <Pagination.Ellipsis />}
      {page > 2 && (
        <Pagination.Item onClick={() => adjustPage(-1)}>
          {page - 1}
        </Pagination.Item>
      )}
      <Pagination.Item active>{page}</Pagination.Item>
      {hasNextPage() && (
        <Pagination.Item onClick={() => adjustPage(1)}>
          {page + 1}
        </Pagination.Item>
      )}
      {hasNextPage() && (
        <Pagination.Next
          onClick={() => adjustPage(1)}
          className="p-next-arrow"
        />
      )}
    </Pagination>
  );
}
