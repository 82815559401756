import React from "react";
import { withRouter, Switch, Route } from "react-router-dom";
import { Index } from "./page/index";
import { PrivateRoute } from "./routes/private.routes";
import { PublicRoutes } from "./routes/public.routes";
import { PublicSiteRoutes } from "./routes/public.site.routes";
import { PrivateSiteRoute } from "./routes/private.site.routes";
import { NotFound } from "./page/404";
import { MainPage } from "./page/main";
import { Home } from "./page/home";
import { MusicPage } from "./page/music";
import { VideoPage } from "./page/video";
import { NewsPage } from "./page/news";
import { SingleVideo } from "./page/single.video";
import { SingleNewsPage } from "./page/single.news";
import { ArtistPage } from "./page/artist";
import { SingleArtistPage } from "./page/single.artist";
import { PlayListPage } from "./page/playlist";
import { PageSiteLogin } from "./page/site.login";
import { PageSiteRegister } from "./page/site.register";
import { PageSiteForgotPass } from "./page/site.forgot.pass";
import { PageSiteRecoveryPass } from "./page/site.recovery.pass";
import { PageTermsConditions } from "./page/terms.conditions";

const SingleVideoComponent = props => <SingleVideo {...props} />;

const PageSiteRegisterComponent = props => <PageSiteRegister {...props} />;

const RoutePages = props => {
  return (
    <>
      <Switch>
        <PublicRoutes
          path="/admin-page"
          exact
          component={() => <Index name="login" />}
        />
        <PrivateRoute
          path="/genres"
          exact
          component={() => <Index name="genres" />}
        />
        <PrivateRoute
          path="/content"
          exact
          component={() => <Index name="content" {...props} />}
        />
        <PrivateRoute
          path="/news"
          exact
          component={() => <Index name="news" {...props} />}
        />
        <PrivateRoute
          path="/artist"
          exact
          component={() => <Index name="artist" {...props} />}
        />
        <PrivateRoute
          path="/promoted"
          exact
          component={() => <Index name="promoted" {...props} />}
        />
        <PrivateRoute
          path="/add-artist"
          exact
          component={() => <Index name="addArtist" />}
        />
        <PrivateRoute
          path="/add-news"
          exact
          component={() => <Index name="addNews" />}
        />
        <PrivateRoute
          path="/add-content"
          exact
          component={() => <Index name="addContent" />}
        />
        <PrivateRoute
          path="/edit-artist/:aid"
          component={props => <Index name="editArtist" {...props} />}
        />
        <PrivateRoute
          path="/edit-news/:nid"
          component={props => <Index name="editNews" {...props} />}
        />
        <PrivateRoute
          path="/edit-content/:cid"
          component={props => <Index name="editContent" {...props} />}
        />
        <PublicSiteRoutes path="/" exact component={MainPage} />
        <PrivateSiteRoute path="/home" exact component={Home} />
        <PrivateSiteRoute path="/site-music" exact component={MusicPage} />
        <PrivateSiteRoute path="/site-video" exact component={VideoPage} />
        <PrivateSiteRoute
          path="/site-video/:vid"
          exact
          component={SingleVideoComponent}
        />
        <PrivateSiteRoute path="/site-news" exact component={NewsPage} />
        <PrivateSiteRoute
          path="/site-news/:nid"
          exact
          component={SingleNewsPage}
        />
        <PrivateSiteRoute path="/site-artist" exact component={ArtistPage} />
        <PrivateSiteRoute
          path="/site-artist/:aid"
          exact
          component={SingleArtistPage}
        />
        <PrivateSiteRoute path="/myplaylist" exact component={PlayListPage} />
        <PublicSiteRoutes path="/site-login" exact component={PageSiteLogin} />
        <PublicSiteRoutes
          path="/site-register/:token"
          exact
          component={PageSiteRegisterComponent}
        />
        <PublicSiteRoutes
          path="/site-recovery-pass"
          exact
          component={PageSiteForgotPass}
        />
        <PublicSiteRoutes
          path="/recovery-password/:token"
          exact
          component={PageSiteRecoveryPass}
        />
        <Route path="/terms-conditions" exact component={PageTermsConditions} />
        <Route component={NotFound} />
      </Switch>
    </>
  );
};

export default withRouter(RoutePages);
