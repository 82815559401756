import React from "react";
import { RecoveryPass } from "../components/public-site/login/site.recovery.pass";
import { SiteAuthProvider } from "../context/site/siteAuth/site.auth.state";
export const PageSiteRecoveryPass = (props) => {
  return (
    <SiteAuthProvider>
      <RecoveryPass {...props}/>
      </SiteAuthProvider>
  );
};
