const siteRegister = (state, action) => {
  return {
    ...state,
    registered: true
  };
};
const siteRegisterError = (state, action) => {
  return {
    ...state,
    registerError: true,
    registerErrorMsg: action.payload
    
  };
};
const doLogin = (state, action) => {
  localStorage.setItem("main-site-inbeat", JSON.stringify(action.payload));
  return {
    ...state,
    login: true,
    loginError: false
  };
};
const recoveryPass = (state, action) => {
  return {
    ...state,
    send: true,
    sendError: false
  };
};
const changePass = (state, action) => {
  return {
    ...state,
    change: true,
    changeError: false
  };
};
const recoveryPassError = (state, action) => {
  return {
    ...state,
    send: false,
    sendError: true
  };
};
const changePassError = (state, action) => {
  return {
    ...state,
    change: false,
    changeError: true
  };
};
const doLoginError = (state, action) => {
  return {
    ...state,
    loginError: true,
    loginErrorMsg: action.payload
  };
};
const doLogout = (state, action) => {
  localStorage.removeItem("main-site-inbeat");
  return {
    ...state,
    login: false,
    loginError: false,
    logoutError: false
  };
};
const doLogoutError = (state, action) => {
  return {
    ...state,
    logoutError: true
  };
};

export default (state, action) => {
  const actions = {
    SITE_REGISTER: siteRegister,
    GOOGLE_REGISTER: siteRegister,
    FACEBOOK_REGISTER: siteRegister,
    SITE_REGISTER_ERROR: siteRegisterError,
    DO_LOGIN: doLogin,
    DO_LOGOUT: doLogout,
    LOGOUT_ERROR: doLogoutError,
    LOGIN_ERROR: doLoginError,
    REQUEST_PASSWORD_RECOVERY: recoveryPass,
    REQUEST_PASSWORD_RECOVERY_ERROR: recoveryPassError,
    PASSWORD_RECOVERY: changePass,
    PASSWORD_RECOVERY_ERROR: changePassError
  };
  return actions[action.type](state, action) || state;
};
