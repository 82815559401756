const getVideo = (state, action) => {
  return {
    ...state,
    video: action.payload
  };
};
const getVideoById = (state, action) => {
  return {
    ...state,
    current: action.payload
  };
};

export default (state, action) => {
  const actions = {
    GET_VIDEO: getVideo,
    GET_VIDEO_BY_ID: getVideoById
  };
  return actions[action.type](state, action) || state;
};
