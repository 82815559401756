import React from "react";
import { Row, Col } from "react-bootstrap";
export const PageTitle = ({ title, ml = "ml-4" }) => {
  return (
    <Row>
      <Col>
        <h5 className={`mt-4 ${ml} mb-4`} style={{ color: "#6c757d" }}>
          {title}
        </h5>
      </Col>
    </Row>
  );
};
