import React from "react";
import { Video } from "../components/public-site/video/video";
import { VideoProvider } from "../context/site/video/video.state";

export const VideoPage = () => {
  return (
    <VideoProvider>
      <Video />
    </VideoProvider>
  );
};
