import React from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandSparkles } from "@fortawesome/free-solid-svg-icons";

export const FilterForm = ({
  filterText,
  onFilter,
  onClear,
  label,
  placeholder
}) => {
  return (
    <Form className="mb-4 ml-4">
      <Form.Row as={Row} className="align-items-end">
        <Form.Group as={Col} xs={5}>
          <Form.Label style={{ color: "#6c757d" }}>{label}</Form.Label>
          <Form.Control
            name="search"
            type="text"
            placeholder={placeholder}
            value={filterText}
            onChange={onFilter}
          />
        </Form.Group>
        <Form.Group as={Col} xs={1} className="mr-4">
          <Button variant="secondary" onClick={onClear}>
            <FontAwesomeIcon
              icon={faHandSparkles}
              title="Limpiar"
              color="white"
            />
          </Button>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};
