import React, { useContext, useRef } from "react";
import {
  Form,
  Col,
  InputGroup,
  Button,
  FormControl,
  Modal
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImage } from "@fortawesome/free-solid-svg-icons";
import { PromotedContext } from "../../context/promoted/promoted.state";

export const PromotedAdd = () => {
  const { handleImageChange, image, isInvalid, errors } = useContext(
    PromotedContext
  );
  const inputEl = useRef(null);
  const value = image && image.name ? image.name : "";
  const handleClick = () => {
    inputEl.current.click();
  };
  return (
    <>
      <div className="d-flex align-items-center justify-content-center mt-4">
        <FontAwesomeIcon
          icon={faFileImage}
          color="#6c757d"
          className="mr-1"
          style={{ fontSize: "40" }}
        />
      </div>
      <div className="d-flex align-items-center justify-content-center mt-4">
        <div className="d-flex align-items-center justify-content-center mt-2">
          <h4 className="text-muted">PROMOCIONAR</h4>
        </div>
      </div>
      <Modal.Body>
        <Form className="mb-2">
          <Form.Group as={Col} xs={12}>
            <InputGroup className="mb-2 mr-sm-2">
              <InputGroup.Prepend>
                <Button
                  variant="secondary"
                  onClick={handleClick}
                  style={{ display: "block" }}
                >
                  <FontAwesomeIcon
                    icon={faFileImage}
                    color="white"
                    className="mr-1"
                  />
                  Examinar
                </Button>
              </InputGroup.Prepend>
              <FormControl
                placeholder="Imagen a utilizar en la promoción"
                isInvalid={isInvalid}
                value={value}
                readOnly
              />
              <Form.Control.Feedback type="invalid">
                {errors}
              </Form.Control.Feedback>
            </InputGroup>
            <input
              ref={inputEl}
              type="file"
              style={{ display: "none" }}
              onChange={e => {
                handleImageChange(e.target.files[0]);
              }}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
    </>
  );
};
