import React from "react";
import playIcon from "../../../images/play.png";
import pauseIcon from "../../../images/pause.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as faHeartR } from "@fortawesome/free-regular-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

export const AudioPlayer = ({
  content,
  name,
  color,
  handleClick,
  current,
  id,
  setSong,
  removeFavorite,
  addFavorite,
  isFavorite
}) => {
  const favIcon = isFavorite ? faHeart : faHeartR;
  const refHandleFavorite = isFavorite ? removeFavorite : addFavorite;
  return (
    <>
      <div
        className={`d-flex justify-content-between align-items-center mt-2 player-box ${color}`}
        style={{ minHeight: "4.5vh" }}
      >
        {current !== id && (
          <img
            src={playIcon}
            width="10%"
            height="45%"
            className="pointer"
            title="Reproducir"
            alt="Reproducir"
            onClick={() => {
              handleClick(true, content);
              setSong(id);
            }}
          />
        )}
        {current === id && (
          <img
            src={pauseIcon}
            width="10%"
            height="45%"
            onClick={() => {
              handleClick(false, null);
              setSong(-1);
            }}
            className="pointer"
            title="Detener"
            alt="Detener"
          />
        )}
        <span
          className="music-song-title"
          style={{
            fontSize: "11pt",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
        >
          {name}
        </span>

        {/* <img
          src={noFavorite}
          width="10%"
          height="45%"
          className="pointer"
          title="Agregar a favoritos"
          alt="Agregar a favoritos"
        /> */}
        <FontAwesomeIcon
          icon={favIcon}
          color="white mr-2"
          className="pointer"
          title="Agregar a favoritos"
          alt="Agregar a favoritos"
          style={{ cursor: "pointer" }}
          onClick={() => refHandleFavorite(id)}
        />
      </div>
    </>
  );
};
