import React, { createContext, useReducer } from "react";
import ContentReducer from "./content.reducer";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useModal } from "../../hooks/useModal";
import { useNotification } from "../../hooks/useNotification";
import { httpRequest } from "../utils/http";

const initialState = {
  contents: [],
  redirect: false,
  toEdit: {},
  modalConfig: {
    type: "confirmation",
    mainQuestion: "",
    description: "",
    action: "idle"
  },
  cathalogs: {}
};

const notificationsMessages = {
  error: {
    msg: "Error. Oopps! Algo salió mal!",
    status: "dark"
  },
  warning: {
    msg:
      "Para poder adicionar contenido deben haber géneros y artistas activos.",
    status: "warning"
  },
  delete: {
    msg: "Éxito. El contenido se ha eliminado satisfactoriamente!",
    status: "success"
  },
  activate: {
    msg: "Éxito. El contenido se ha activado satisfactoriamente!",
    status: "success"
  }
};

export const ContentContext = createContext(initialState);

export const ContentProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ContentReducer, initialState);
  const { execute, loading, error } = useHttpRequest(httpRequest, false);
  const { showModal, show } = useModal();
  const { showNotification, notify, notificationConfig } = useNotification();

  const getContents = async msg => {
    try {
      let result = await execute({ url: "contents/get" });
      if (msg) {
        showNotification({ msg, status: "success" });
      }
      dispatch({
        type: "GET_CONTENTS",
        payload: result.data
      });
    } catch (e) {
      showNotification(notificationsMessages.error);
    }
  };

  const showModalWithConfig = (option, id) => {
    dispatch({
      type: "SET_MODAL_OPTION",
      payload: { option, id }
    });
    showModal(option, id);
  };

  const doActions = async () => {
    try {
      showNotification();
      let formData = new FormData();
      formData.append("content_id", state.modalConfig.id);
      const endpoint = {
        delete: "contents/activate",
        activate: "contents/delete"
      };
      showModal();
      await execute({
        url: endpoint[state.modalConfig.action],
        data: formData
      });
      dispatch({
        type: "DO_ACTIONS",
        payload: { eid: state.modalConfig.id, action: state.modalConfig.action }
      });
      showNotification(notificationsMessages[state.modalConfig.action]);
    } catch (e) {
      showNotification(notificationsMessages.error);
      showModal();
    }
  };

  const getCathalogs = async () => {
    try {
      const allData = await execute([
        { url: "cathalogs/get" },
        { url: "admin/get_artist" },
        { url: "genres/get" }
      ]);

      let [cathalog, artists, genres] = allData;
      artists = artists.data.data.filter(_ => _.status === "Activo");
      genres = genres.data.data.filter(_ => _.status === "Activo");
      let cath = {
        content_type: cathalog.data.data.content_type,
        artists,
        genres
      };
      if (artists.length === 0 || genres.length === 0) {
        showNotification(notificationsMessages.warning);
      }
      dispatch({
        type: "GET_CONTENT_CATHALOGS",
        payload: cath
      });
    } catch (error) {
      showNotification(notificationsMessages.error);
    }
  };

  const addContent = async content => {
    try {
      showNotification();
      let formData = new FormData();
      formData.append("content_name", content.name);
      formData.append("artist_id", content.artist);
      formData.append("content_type_id", content.content_type);
      formData.append("file", content.file);
      formData.append("genres", JSON.stringify(content.genres));
      formData.append("preview", content.preview);
      formData.append("image", content.image);
      await execute({
        url: "contents/create",
        data: formData
      });
      dispatch({
        type: "ADD_CONTENT"
      });
    } catch (error) {
      showNotification(notificationsMessages.error);
    }
  };

  const editContent = async content => {
    try {
      showNotification();
      let formData = new FormData();
      formData.append("content_name", content.name);
      formData.append("artist_id", content.artist);
      formData.append("genres", JSON.stringify(content.genres));
      formData.append("content_id", JSON.stringify(content.id));
      if (Object.keys(content).includes("file")) {
        formData.append("file", content.file);
      }
      if (Object.keys(content).includes("image")) {
        formData.append("image", content.image);
      }
      if (Object.keys(content).includes("preview")) {
        formData.append("preview", content.preview);
      }
      if (Object.keys(content).includes("content_type")) {
        formData.append("content_type_id", content.content_type);
      }
      await execute({
        url: "contents/modify",
        data: formData
      });
      dispatch({
        type: "EDIT_CONTENT"
      });
    } catch (error) {
      showNotification(notificationsMessages.error);
    }
  };

  const getContentById = async cid => {
    try {
      let formData = new FormData();
      formData.append("content_id", cid);
      const allData = await execute([
        { url: "cathalogs/get" },
        { url: "admin/get_artist" },
        { url: "genres/get" },
        {
          url: "admin/get_content_by_id",
          data: formData
        }
      ]);

      let [cathalog, artists, genres, contentToEdit] = allData;
      let cath = {
        content_type: cathalog.data.data.content_type,
        artists: artists.data.data,
        genres: genres.data.data
      };
      dispatch({
        type: "GET_CONTENT_TO_EDIT",
        payload: {
          cath,
          toEdit: { ...contentToEdit.data.data }
        }
      });
    } catch (error) {
      showNotification(notificationsMessages.error);
    }
  };

  return (
    <ContentContext.Provider
      value={{
        contents: state.contents,
        isLoading: loading,
        redirect: state.redirect,
        toEdit: state.toEdit,
        modalConfig: state.modalConfig,
        cathalogs: state.cathalogs,
        notify,
        notificationConfig,
        show,
        error,
        getContents,
        showModal,
        showNotification,
        showModalWithConfig,
        doActions,
        getCathalogs,
        addContent,
        getContentById,
        editContent
      }}
    >
      {children}
    </ContentContext.Provider>
  );
};
