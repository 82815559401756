import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebookF,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";
import { serverPath } from "../../context/utils/file.path";
const fileUrl = `${serverPath}artists/`;

const styleElements = {
  image: { width: 60, height: 60 },
  artistName: { color: "#6c757c", fontSize: "15px" },
  icons: { cursor: "pointer" }
};

export const ArtistCardContent = props => {
  const { picture, facebook, twitter, instagram, name } = props;
  const { image, artistName, icons } = styleElements;
  return (
    <Row>
      <Col lg={2} md={2} xs={2}>
        <Image src={`${fileUrl}${picture}`} style={image} className="ml-1" />
      </Col>
      <Col lg={8} md={6} xs={6}>
        <p className="mb-0">
          <b style={artistName}>{name}</b>
        </p>
        <a href={`https://facebook.com/${facebook}`}>
          <FontAwesomeIcon
            icon={faFacebookF}
            className="mr-4 text-secondary"
            style={icons}
          />
        </a>
        <a href={`https://twitter.com/${twitter}`}>
          <FontAwesomeIcon
            icon={faTwitter}
            className="mr-4 text-secondary"
            style={icons}
          />
        </a>
        <a href={`https://www.instagram.com/${instagram}`}>
          <FontAwesomeIcon
            icon={faInstagram}
            className="text-secondary"
            style={icons}
          />
        </a>
      </Col>
    </Row>
  );
};
