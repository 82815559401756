import React, { createContext, useReducer } from "react";
import ArtistReducer from "./artist.reducer";
import { useHttpRequest } from "../../../hooks/useHttpRequest";
import { httpRequest } from "../../utils/http";

const initialState = {
  artists: [],
  page: 1,
  current: {}
};

export const ArtistContext = createContext(initialState);

export const ArtistProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ArtistReducer, initialState);
  const { execute } = useHttpRequest(httpRequest, false);

  const getArtists = async () => {
    try {
      let result = await execute({
        url: "artists/get"
      });
      dispatch({
        type: "GET_ARTISTS",
        payload: result.data
      });
    } catch (e) {
      // showNotification(notificationsMessages.error);
    }
  };
  const getArtistById = async id => {
    try {
      let formData = new FormData();
      formData.append("artist_id", id);
      let result = await execute({
        url: "artists/get_by_id",
        data: formData
      });
      dispatch({
        type: "GET_ARTIST_BY_ID",
        payload: result.data
      });
    } catch (e) {
      // showNotification(notificationsMessages.error);
    }
  };
  const addFavorite = async cid => {
    try {
      let formData = new FormData();
      formData.append(
        "user_id",
        JSON.parse(localStorage.getItem("main-site-inbeat")).user_id
      );
      formData.append("content_id", cid);
      await execute({
        url: "playlist/add_favorite",
        data: formData
      });
      dispatch({
        type: "ADD_FAVORITE",
        payload: cid
      });
    } catch (e) {
      // showNotification(notificationsMessages.error);
    }
  };
  const removeFavorite = async cid => {
    try {
      let formData = new FormData();
      formData.append(
        "user_id",
        JSON.parse(localStorage.getItem("main-site-inbeat")).user_id
      );
      formData.append("content_id", cid);
      await execute({
        url: "playlist/remove_favorite",
        data: formData
      });
      dispatch({
        type: "REMOVE_FAVORITE",
        payload: cid
      });
    } catch (e) {
      // showNotification(notificationsMessages.error);
    }
  };
  return (
    <ArtistContext.Provider
      value={{
        artists: state.artists,
        page: state.page,
        current: state.current,
        getArtists,
        getArtistById,
        addFavorite,
        removeFavorite
      }}
    >
      {children}
    </ArtistContext.Provider>
  );
};
