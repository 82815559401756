import React, { useContext } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import logo from "../../../images/logo_INBEAT_Color.png";
import whiteLogo from "../../../images/logo_INbeat_White.png";
import "./site.login.css";
import { Formik } from "formik";
import * as Yup from "yup";
import { SiteAuthContext } from "../../../context/site/siteAuth/site.auth.state";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Debe introducir un correo electrónico válido")
    .required("El correo es un campo requerido")
});

export const SiteForgotPass = () => {
  const { send, sendError, recoveryPass } = useContext(SiteAuthContext);

  const handleRecoveryPass = async (values, setSubmitting, resetForm) => {
    const { email } = values;
    await recoveryPass(email);
    setSubmitting(false);
    resetForm({
      email: ""
    });
  };

  return (
    <Row>
      <Col
        lg="6"
        style={{
          minHeight: "100vh",
          maxHeight: "100vh",
          backgroundColor: "#7b2a7c"
        }}
        className="m-0 p-0"
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            paddingLeft: "10vw",
            paddingRight: "10vw"
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <Formik
            initialValues={{
              email: ""
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);

              handleRecoveryPass(values, setSubmitting, resetForm);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <Form onSubmit={handleSubmit} method="post">
                {send && !sendError && (
                  <Form.Text
                    className="mt-4 pt-1 mb-4 pb-1"
                    style={{ color: "white", fontSize: "12pt" }}
                  >
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ maxWidth: "400px" }}
                    >
                      <span>
                        ENVIO EXITOSO. Un correo se ha enviado con los pasos a
                        seguir para el cambio de credenciales.
                      </span>
                    </div>
                  </Form.Text>
                )}
                {sendError && (
                  <Form.Text
                    className="mt-4 pt-1 mb-4 pb-1"
                    style={{ color: "white", fontSize: "12pt" }}
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <span>
                        ENVIO FALLIDO. Por favor revise los datos ingresados.
                      </span>
                    </div>
                  </Form.Text>
                )}
                <Form.Group
                  controlId="formBasicEmail"
                  style={{ minWidth: "25vw" }}
                >
                  <Form.Label style={{ color: "#C3C0BF", fontSize: "14pt" }}>
                    OLVIDÓ SU PASSWORD
                  </Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Correo electrónico"
                    style={{
                      borderColor: "#606263",
                      backgroundColor: "transparent",
                      color: "white"
                    }}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.email && !errors.email}
                    isInvalid={touched.email && !!errors.email}
                  />
                  <Form.Control.Feedback>
                    Entrada correcta!
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>

                {/* <Form.Text
                  className="mb-4 pb-1 float-right"
                  style={{ color: "#C3C0BF", fontSize: "14pt" }}
                >
                  Regístrate
                </Form.Text> */}
                <Button
                  type="submit"
                  block
                  className="mt-4 box-orange"
                  disabled={isSubmitting}
                >
                  CONTINUAR
                </Button>
                <Form.Text
                  className="mt-2 mb-4 pb-4"
                  style={{ color: "#C3C0BF", fontSize: "8pt" }}
                >
                  <div style={{ maxWidth: "400px" }}>
                    <p>
                      *Enviaremos un correo para restablecer la contraseña.
                      Recuerde que esta funcionalidad es solo para los usuarios
                      que se registraron en la plataforma (no usó facebook o
                      google para registrarse).
                    </p>
                  </div>
                </Form.Text>
                <Form.Text className="mt-4 pt-4">
                  <div className="d-flex justify-content-center align-items-center">
                    <img src={whiteLogo} height="45" width="105" alt="INBEAT" />
                  </div>
                </Form.Text>
              </Form>
            )}
          </Formik>
        </div>
      </Col>
      <Col
        lg="6"
        style={{
          minHeight: "100vh",
          maxHeight: "100vh",
          backgroundColor: "#e5e5e5"
        }}
        className="m-0 p-0"
      >
        <div
          style={{ height: "100%" }}
          className="d-flex justify-content-center align-items-center"
        >
          <img src={logo} height="100vh" width="40%" alt="INBEAT" />
        </div>
      </Col>
    </Row>
  );
};
