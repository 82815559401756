import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandSparkles } from "@fortawesome/free-solid-svg-icons";

const FilterComponent = ({ filterText, onFilter, onClear, helpTxt }) => (
  <>
    <div className="form-group col-lg-4">
      <div className="form-group">
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder={helpTxt}
            value={filterText}
            onChange={onFilter}
          />
          <div
            className="input-group-append"
            onClick={onClear}
            style={{ backgroundColor: "#6c757d !important" }}
          >
            <span className="input-group-text">
              <FontAwesomeIcon
                // className="text-primary"
                icon={faHandSparkles}
                title="LIMPIAR"
                style={{ color: "white", cursor: "pointer" }}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  </>
);
export default FilterComponent;
