import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { MusicBox } from "./music.box";
import { PlayListFooterBox } from "./play.list.footer.box";
import showMore from "../../../images/mostrar_mas.png";
import showMoreOpen from "../../../images/mostrar_mas_desplegado.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons";

export const PlayListBox = ({
  name,
  id,
  content,
  color,
  handleRemoveContent,
  handleRemovePlayList,
  handleShowPlayListEdit,
  playPlayList,
  setshowMusic,
  currentIndexSong,
  setCurrentIndexSong,
  globalPlay,
  setGlobalPlay
}) => {
  const [collapsed, setCollased] = useState(false);
  // const [globalPlay, setGlobalPlay] = useState(false);
  function* getColorBox() {
    let counter = 0;
    const map = [
      "color-1o",
      "color-2pl",
      "color-3pb",
      "color-4bb",
      "color-5ol"
    ];
    while (true) {
      yield map[counter++];
      if (counter > 4) {
        counter = 0;
      }
    }
  }
  const iconBox = collapsed ? showMoreOpen : showMore;
  const globlaPlayIcon = globalPlay ? faPause : faPlay;
  const gen = getColorBox();
  return (
    <>
      <Row
        className="mr-1 ml-1 mt-1"
        // onClick={() => setCollased(!collapsed)}
        // style={{ cursor: "pointer" }}
      >
        <Col
          lg={{ span: 8, offset: 2 }}
          style={{ maxHeight: "12vh" }}
          className={`${color}`}
        >
          <div className="d-flex justify-content-between align-items-center pt-1 pb-1">
            <span
              style={{
                fontFamily: '"Montserrat", sans-serif',

                fontSize: "11pt",
                fontWeight: "bold",
                color: "white"
              }}
              className="mr-3"
            >
              {content.length > 0 && (
                <FontAwesomeIcon
                  icon={globlaPlayIcon}
                  color="white"
                  style={{
                    marginRight: "40px",
                    fontSize: "10pt",
                    // marginLeft: "70px",
                    cursor: "pointer"
                  }}
                  title="Reproducir Playlist"
                  onClick={() => {
                    if (!collapsed) {
                      setCollased(true);
                    }
                    if (!globalPlay) {
                      setGlobalPlay(!globalPlay);
                      playPlayList(content);
                      setshowMusic(true);
                      setCurrentIndexSong(0);
                    }
                    if (globalPlay) {
                      setGlobalPlay(!globalPlay);
                      playPlayList({});
                      setshowMusic(false);
                      setCurrentIndexSong(-1);
                    }
                  }}
                />
              )}
              {name}
            </span>
            <div>
              <span
                className="mr-4"
                style={{
                  fontFamily: '"Montserrat", sans-serif',
                  fontSize: "11pt",
                  color: "white"
                }}
              >
                {content.length} canciones
              </span>
              <img
                src={iconBox}
                width="20vw"
                height="15%"
                alt="Play List"
                className="mr-1"
                onClick={() => setCollased(!collapsed)}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
        </Col>
      </Row>
      {/* CANCIONES */}
      {collapsed && (
        <>
          {content.map((_, index) => {
            return (
              <MusicBox
                gen={gen}
                item={_}
                key={_.id}
                index={++index}
                handleRemoveContent={handleRemoveContent}
                plId={id}
                currentIndexSong={currentIndexSong}
              />
            );
          })}

          <PlayListFooterBox
            name={name}
            handleRemovePlayList={handleRemovePlayList}
            handleShowPlayListEdit={handleShowPlayListEdit}
            id={id}
          />
        </>
      )}
    </>
  );
};
