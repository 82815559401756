import React, { createContext, useReducer } from "react";
import VideoReducer from "./video.reducer";
import { useHttpRequest } from "../../../hooks/useHttpRequest";
import { httpRequest } from "../../utils/http";

const initialState = {
  video: [],
  page: 1,
  current: {}
};

export const VideoContext = createContext(initialState);

export const VideoProvider = ({ children }) => {
  const [state, dispatch] = useReducer(VideoReducer, initialState);
  const { execute } = useHttpRequest(httpRequest, false);

  const getVideo = async () => {
    try {
      let formData = new FormData();
      formData.append("table", "video");
      let result = await execute({
        url: "contents/get_to_show",
        data: formData
      });
      dispatch({
        type: "GET_VIDEO",
        payload: result.data
      });
    } catch (e) {
      // showNotification(notificationsMessages.error);
    }
  };
  const getVideoById = async id => {
    try {
      let formData = new FormData();
      formData.append("content_id", id);
      let result = await execute({
        url: "contents/get_by_id",
        data: formData
      });
      dispatch({
        type: "GET_VIDEO_BY_ID",
        payload: result.data
      });
    } catch (e) {
      // showNotification(notificationsMessages.error);
    }
  };
  return (
    <VideoContext.Provider
      value={{
        video: state.video,
        page: state.page,
        current: state.current,
        getVideo,
        getVideoById
      }}
    >
      {children}
    </VideoContext.Provider>
  );
};
