import React from "react";
import "./music.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart as faHeartR,
  faThumbsUp as faThumbsUpR
} from "@fortawesome/free-regular-svg-icons";
import {
  faThList,
  faThumbsUp,
  faHeart
} from "@fortawesome/free-solid-svg-icons";

export const AudioPlayer = ({
  content,
  artist,
  color,
  id,
  countReproductions,
  addFavorite,
  removeFavorite,
  isFavorite,
  voteMusic,
  handleShowModal,
  handleClick,
  current,
  setSong,
  userVoted
}) => {
  const handleAddFavorite = () => {
    addFavorite(id);
  };
  const handleRemoveFavorite = () => {
    removeFavorite(id);
  };
  const handleVoteMusic = () => {
    voteMusic(id);
  };

  const favIcon = isFavorite ? faHeart : faHeartR;
  const thumbsIcon = userVoted ? faThumbsUp : faThumbsUpR;
  const refHandleFavorite = isFavorite
    ? handleRemoveFavorite
    : handleAddFavorite;
  const refHandleVoteMusic = userVoted ? null : handleVoteMusic;
  return (
    <>
      <div className="" style={{ paddingLeft: "5px" }}>
        <FontAwesomeIcon
          icon={favIcon}
          color="white"
          className="pointer mr-2"
          title="Agregar a favoritos"
          alt="Agregar a favoritos"
          style={{ cursor: "pointer", color: "#14123c" }}
          onClick={refHandleFavorite}
        />
        <FontAwesomeIcon
          icon={thumbsIcon}
          color="white"
          className="pointer mr-2"
          title="Votar"
          alt="Votar"
          style={{ cursor: "pointer", color: "#14123c" }}
          onClick={refHandleVoteMusic}
        />
        <FontAwesomeIcon
          icon={faThList}
          color="white"
          className="pointer mr-2"
          title="Adicionar a Mi Playlist"
          alt="Adicionar a Mi Playlist"
          style={{ cursor: "pointer", color: "#14123c" }}
          onClick={() => handleShowModal(id)}
        />
      </div>
    </>
  );
};
