import React from "react";

const AuthTitle = ({ title }) => (
  <div className="mb mt-3 login-items">
    <h3>{title}</h3>
  </div>
);

const AuthDescription = ({ txt, hasError }) => (
  <div className="mb-3 login-items">
    <small className={`p-1 pl-2 pr-2 login-help-text ${hasError && 'text-danger'}`}>
      {hasError ? hasError : txt}
    </small>
  </div>
)
;
const SuccessMsg = ({ txt }) => (
  <div className="mb-3 login-items">
    <small className={`p-1 pl-2 pr-2 login-help-text text-success`}>
      {txt}
    </small>
  </div>
);

export { AuthTitle, AuthDescription, SuccessMsg };
