import React, { useRef } from "react";
import { Form, InputGroup, Button, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

export const UploadFile = props => {
  const inputEl = useRef(null);
  let { icon, placeholder, value } = props;
  icon = icon ? icon : faUpload;
  value = value && value.name ? value.name : "";

  const handleClick = () => {
    inputEl.current.click();
  };

  return (
    <>
      <Form.Label>{props.label}</Form.Label>
      <InputGroup className="mb-2 mr-sm-2">
        <InputGroup.Prepend>
          <Button
            variant="secondary"
            onClick={handleClick}
            style={{ display: "block" }}
          >
            <FontAwesomeIcon icon={icon} color="white" className="mr-1" />
            Examinar
          </Button>
        </InputGroup.Prepend>
        <FormControl
          placeholder={placeholder}
          isValid={props.isValid}
          isInvalid={props.isInvalid}
          value={value}
          readOnly
        />
        <Form.Control.Feedback>Entrada correcta!</Form.Control.Feedback>
        <Form.Control.Feedback type="invalid">
          {props.errors}
        </Form.Control.Feedback>
      </InputGroup>
      <input
        ref={inputEl}
        type="file"
        style={{ display: "none" }}
        onChange={e => {
          props.handleTouched(props.name);
          props.handleChange(props.name, e.target.files[0]);
        }}
        name={props.name}
      />
    </>
  );
};
