import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Modal, Form } from "react-bootstrap";

export const AddGenreModalForm = ({ handleGenreName }) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-center mt-4">
        <FontAwesomeIcon
          icon={faAlignJustify}
          color="#6c757d"
          className="mr-1"
          style={{ fontSize: "40" }}
        />
      </div>
      <div className="d-flex align-items-center justify-content-center mt-4">
        <div className="d-flex align-items-center justify-content-center mt-2">
          <h4 className="text-muted">ADICIONAR GENERO</h4>
        </div>
      </div>

      <Modal.Body>
        <Form className="mb-4 ml-1">
          <Form.Row as={Row} className="align-items-center">
            <Form.Group as={Col}>
              <Form.Control
                name="genreName"
                type="text"
                placeholder="Entre el nombre del Genéro. Ej: Pop"
                onChange={e => handleGenreName(e)}
              />
            </Form.Group>
          </Form.Row>
        </Form>
      </Modal.Body>
    </>
  );
};
