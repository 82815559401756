import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const iconStyle = { cursor: "pointer" };

const Container = ({ icon, txt }) => {
  return (
    <>
      <FontAwesomeIcon
        icon={icon || faPlus}
        color="white"
        className="mr-1"
        style={iconStyle}
      />
      {txt}
    </>
  );
};

export const AddElement = ({ action, txt, icon }) => {
  const element = typeof action === "string" ? "link" : "button";
  let content = (
    <Button
      variant="info"
      className="float-right mr-4 mt-2"
      onClick={() => action()}
    >
      <Container txt={txt} icon={icon} />
    </Button>
  );
  if (element === "link") {
    content = (
      <>
        <Link className="btn btn-info mr-4 mt-2 float-right" to={`/${action}`}>
          <Container txt={txt} icon={icon} />
        </Link>
      </>
    );
  }
  return content;
};
