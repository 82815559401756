import * as Yup from "yup";

const FILE_SIZE = 2097152;
const FILE_VIDEO_SIZE = 536870912;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];
const SUPPORTED_VIDEO_FORMATS = ["video/webm", "video/mp4", "video/ogg"];

const commonSchema = {
  name: Yup.string()
    .min(3, "Debe introducir un nombre de al menos 3 caracteres")
    .max(64, "El nombre no debe superar los 64 caracteres")
    .required("Este campo es requerido."),
  facebook: Yup.string()
    .min(3, "Debe introducir un nombre de perfil de al menos 3 caracteres")
    .max(64, "El nombre de perfil no debe superar los 64 caracteres"),
  instagram: Yup.string()
    .min(3, "Debe introducir un nombre de perfil de al menos 3 caracteres")
    .max(64, "El nombre de perfil no debe superar los 64 caracteres"),
  twitter: Yup.string()
    .min(3, "Debe introducir un nombre de perfil de al menos 3 caracteres")
    .max(64, "El nombre de perfil no debe superar los 64 caracteres"),
  biography: Yup.string()
    .min(10, "La biografía debe tener al menos 10 caracteres")
    .required("Este campo es requerido.")
};

const addSchema = {
  picture: Yup.mixed()
    .required("La foto del artista es requerida.")
    .test(
      "picture-format",
      "Solo se aceptan los siguientes formatos .jpg, .jpeg, .png",
      value => !(value && !SUPPORTED_FORMATS.includes(value.type))
    )
    .test(
      "picture-size",
      "La imagen no debe superar los 2MB",
      value => !(value && value.size > FILE_SIZE)
    ),
  interview: Yup.mixed()
    .test(
      "valid-format-int",
      "Solo se aceptan los siguientes formatos .mp4, .webm, .ogg",
      value => !(value && !SUPPORTED_VIDEO_FORMATS.includes(value.type))
    )
    .test(
      "valid-size-int",
      "La entrevista no debe superar los 512MB",
      value => !(value && value.size > FILE_VIDEO_SIZE)
    )
};

const editSchema = {
  picture: Yup.mixed()
    .test(
      "picture-format",
      "Solo se aceptan los siguientes formatos .jpg, .jpeg, .png",
      value => !(value && !SUPPORTED_FORMATS.includes(value.type))
    )
    .test(
      "picture-size",
      "La imagen no debe superar los 2MB",
      value => !(value && value.size > FILE_SIZE)
    ),
  interview: Yup.mixed()
    .test(
      "valid-format-int",
      "Solo se aceptan los siguientes formatos .mp4, .webm, .ogg",
      value => !(value && !SUPPORTED_VIDEO_FORMATS.includes(value.type))
    )
    .test(
      "valid-size-int",
      "La entrevista no debe superar los 512MB",
      value => !(value && value.size > FILE_VIDEO_SIZE)
    )
};

const getSchema = type => {
  const toAssign = type === "add" ? addSchema : editSchema;
  return Yup.object(Object.assign(commonSchema, toAssign));
};

let initialValues = {
  name: "",
  facebook: "",
  twitter: "",
  instagram: "",
  biography: "",
  picture: "",
  interview: ""
};

export {
  getSchema,
  initialValues,
  FILE_SIZE,
  FILE_VIDEO_SIZE,
  SUPPORTED_FORMATS,
  SUPPORTED_VIDEO_FORMATS
};
