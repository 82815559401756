import React from "react";
import { NewsProvider } from "../context/site/news/news.state";
import { SingleNews } from "../components/public-site/news/single.news";
export const SingleNewsPage = props => {
  return (
    <NewsProvider>
      <SingleNews {...props} />
    </NewsProvider>
  );
};
