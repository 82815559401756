import React from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { Row, Col } from "react-bootstrap";
import { EmptyTable } from "./empty.result";

createTheme("solarized", {
  background: {
    default: "#f8f9fa"
  },
  context: {
    background: "#f8f9fa"
  }
});

export const DataTableComponent = props => (
  <Row className="ml-2 mr-2">
    <Col>
      <DataTable
        title={props.title}
        columns={props.columns}
        data={props.items}
        pagination
        paginationResetDefaultPage={props.paginationResetDefaultPage}
        paginationComponentOptions={props.paginationComponentOptions}
        subHeader
        subHeaderComponent={props.subHeaderComponent}
        expandableRows={props.expandableRows || false}
        expandOnRowClicked={props.expandOnRowClicked || false}
        expandableRowsComponent={
          props.expandableRowsComponent ? props.expandableRowsComponent : <></>
        }
        noDataComponent={<EmptyTable />}
        theme="solarized"
      />
    </Col>
  </Row>
);
