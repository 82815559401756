import React from "react";
import { Music } from "../components/public-site/music/music";
import { MusicProvider } from "../context/site/music/music.state";

export const MusicPage = () => {
  return (
    <MusicProvider>
      <Music />
    </MusicProvider>
  );
};
