import React, { createContext, useReducer } from "react";
import LandingReducer from "./landing.reducer";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { httpRequest } from "../utils/http";

const initialState = {
  promotions: [],
  mostVoted: [],
  mostListened: [],
  promotedNews: []
};

export const LandingContext = createContext(initialState);

export const LandingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(LandingReducer, initialState);
  const { execute } = useHttpRequest(httpRequest, false);

  const getPromotions = async () => {
    try {
      let result = await execute({ url: "promotions/get" });
      dispatch({
        type: "GET_PROMOTIONS",
        payload: result.data
      });
    } catch (e) {
      // showNotification(notificationsMessages.error);
    }
  };
  const getMostVoted = async () => {
    try {
      let result = await execute({ url: "contents/most_voted" });
      dispatch({
        type: "GET_MOST_VOTED",
        payload: result.data
      });
    } catch (e) {
      // showNotification(notificationsMessages.error);
    }
  };
  const getMostListened = async () => {
    try {
      let result = await execute({ url: "contents/most_listened" });
      dispatch({
        type: "GET_MOST_LISTENED",
        payload: result.data
      });
    } catch (e) {
      // showNotification(notificationsMessages.error);
    }
  };
  const getPromotedNews = async () => {
    try {
      let result = await execute({ url: "promotions/news" });
      dispatch({
        type: "GET_PROMOTED_NEWS",
        payload: result.data
      });
    } catch (e) {
      // showNotification(notificationsMessages.error);
    }
  };
  return (
    <LandingContext.Provider
      value={{
        promotions: state.promotions,
        mostVoted: state.mostVoted,
        mostListened: state.mostListened,
        promotedNews: state.promotedNews,
        getPromotions,
        getMostVoted,
        getMostListened,
        getPromotedNews
      }}
    >
      {children}
    </LandingContext.Provider>
  );
};
