import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormElement } from "../commons/form.element";

const validationSchema = Yup.object().shape({
  password: Yup.string().required("La contraseña es un campo requerido"),
  email: Yup.string()
    .email("Debe introducir un correo electrónico válido")
    .required("El correo es un campo requerido")
});

const ElementInfo = {
  password: {
    label: "CONTRASEÑA",
    helpText: ""
  },
  email: {
    label: "CORREO ELECTRÓNICO",
    helpText: ""
  }
};

const getElementClass = (touched, errors, key) => {
  let map = {
    container: {
      default: "form-group",
      onError: "has-danger",
      onSucces: "has-success"
    },
    input: {
      default: "form-control form-control-lg",
      onError: "is-invalid",
      onSuccess: "is-valid"
    }
  };
  if (touched && errors) {
    return `${map[key].default} ${map[key].onError}`;
  }
  if (touched && !errors) {
    return `${map[key].default} ${map[key].onSuccess}`;
  }
  return `${map[key].default}`;
};

const getCustomProperties = (element, touched, errors) => {
  return {
    label: ElementInfo[element].label,
    wrapperClass: getElementClass(
      touched[element],
      errors[element],
      "container"
    ),
    helpText: ElementInfo[element].helpText,
    error: errors[element]
  };
};

export const AuthForm = props => {
  const login = async (values, setSubmitting, resetForm) => {
    setSubmitting(true);
    await props.handleDoLogin(values);
    // doLogin(values);
    // if (user.error) {
    //   setSubmitting(false);
    //   props.handleLoading(false);
    // }
  };
  return (
    <>
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) =>
          login(values, setSubmitting, resetForm)
        }
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting
        }) => (
          <form onSubmit={handleSubmit}>
            <FormElement custom={getCustomProperties("email", touched, errors)}>
              <input
                type="email"
                className={getElementClass(
                  touched.email,
                  errors.email,
                  "input"
                )}
                name="email"
                placeholder="usurio@gmail.com.ec"
                onChange={handleChange}
                value={values.email}
                onBlur={handleBlur}
              />
            </FormElement>
            <FormElement
              custom={getCustomProperties("password", touched, errors)}
            >
              <input
                type="password"
                className={getElementClass(
                  touched.password,
                  errors.password,
                  "input"
                )}
                name="password"
                placeholder="************"
                onChange={handleChange}
                value={values.password}
                onBlur={handleBlur}
              />
            </FormElement>
            <div className="form-group">
              <button
                type="submit"
                className="btn btn-primary btn-lg btn-block login"
                disabled={isSubmitting}
              >
                <span className="mb-1">
                  INGRESAR
                  <FontAwesomeIcon
                    className="ml-3 login-btn-icon"
                    icon={faLongArrowAltRight}
                  />
                </span>
              </button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};
