const getPromotions = (state, action) => {
  return {
    ...state,
    promotions: action.payload
  };
};
const getMostVoted = (state, action) => {
  return {
    ...state,
    mostVoted: action.payload
  };
};
const getMostListened = (state, action) => {
  return {
    ...state,
    mostListened: action.payload
  };
};
const getPromotedNews = (state, action) => {
  return {
    ...state,
    promotedNews: action.payload
  };
};

export default (state, action) => {
  const actions = {
    GET_PROMOTIONS: getPromotions,
    GET_MOST_VOTED: getMostVoted,
    GET_MOST_LISTENED: getMostListened,
    GET_PROMOTED_NEWS: getPromotedNews
  };
  return actions[action.type](state, action) || state;
};
