import React, { createContext, useReducer } from "react";
import MusicReducer from "./music.reducer";
import { useHttpRequest } from "../../../hooks/useHttpRequest";
import { httpRequest } from "../../utils/http";

const initialState = {
  music: [],
  playlists: [],
  page: 1
};

export const MusicContext = createContext(initialState);

export const MusicProvider = ({ children }) => {
  const [state, dispatch] = useReducer(MusicReducer, initialState);
  const { execute } = useHttpRequest(httpRequest, false);

  const getMusic = async () => {
    try {
      let formData = new FormData();
      formData.append("table", "music");
      let result = await execute({
        url: "contents/get_to_show",
        data: formData
      });
      dispatch({
        type: "GET_MUSIC",
        payload: result.data
      });
    } catch (e) {
      // showNotification(notificationsMessages.error);
    }
  };
  const addFavorite = async cid => {
    try {
      let formData = new FormData();
      formData.append(
        "user_id",
        JSON.parse(localStorage.getItem("main-site-inbeat")).user_id
      );
      formData.append("content_id", cid);
      await execute({
        url: "playlist/add_favorite",
        data: formData
      });
      dispatch({
        type: "ADD_FAVORITE",
        payload: cid
      });
    } catch (e) {
      // showNotification(notificationsMessages.error);
    }
  };
  const removeFavorite = async cid => {
    try {
      let formData = new FormData();
      formData.append(
        "user_id",
        JSON.parse(localStorage.getItem("main-site-inbeat")).user_id
      );
      formData.append("content_id", cid);
      await execute({
        url: "playlist/remove_favorite",
        data: formData
      });
      dispatch({
        type: "REMOVE_FAVORITE",
        payload: cid
      });
    } catch (e) {
      // showNotification(notificationsMessages.error);
    }
  };
  const voteMusic = async cid => {
    try {
      let formData = new FormData();
      formData.append(
        "user_id",
        JSON.parse(localStorage.getItem("main-site-inbeat")).user_id
      );
      formData.append("content_id", cid);
      await execute({
        url: "interactions/vote_content",
        data: formData
      });
      dispatch({
        type: "VOTED",
        payload: cid
      });
    } catch (e) {
      // showNotification(notificationsMessages.error);
    }
  };
  const countReproductions = async cid => {
    try {
      let formData = new FormData();
      formData.append(
        "user_id",
        JSON.parse(localStorage.getItem("main-site-inbeat")).user_id
      );
      formData.append("content_id", cid);
      formData.append("token", "asdasdasdasd");
      await execute({
        url: "interactions/count_reproduction",
        data: formData
      });
    } catch (e) {
      // showNotification(notificationsMessages.error);
    }
  };
  const getPlayLists = async () => {
    try {
      let formData = new FormData();
      formData.append(
        "user_id",
        JSON.parse(localStorage.getItem("main-site-inbeat")).user_id
      );
      let result = await execute({
        url: "playlist/get",
        data: formData
      });
      dispatch({
        type: "GET_PLAYLISTS",
        payload: result.data
      });
    } catch (e) {
      // showNotification(notificationsMessages.error);
    }
  };
  const addToPlayList = async (cid, plid) => {
    try {
      let formData = new FormData();
      formData.append(
        "user_id",
        JSON.parse(localStorage.getItem("main-site-inbeat")).user_id
      );
      formData.append("content_id", cid);
      formData.append("playlist_id", plid);
      await execute({
        url: "playlist/add_content",
        data: formData
      });
    } catch (e) {
      // showNotification(notificationsMessages.error);
    }
  };
  return (
    <MusicContext.Provider
      value={{
        music: state.music,
        page: state.page,
        playlists: state.playlists,
        getMusic,
        addFavorite,
        removeFavorite,
        voteMusic,
        countReproductions,
        getPlayLists,
        addToPlayList
      }}
    >
      {children}
    </MusicContext.Provider>
  );
};
