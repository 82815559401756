import React from "react";
import Route from "./routes";
import { HashRouter } from "react-router-dom";
import "./App.css";
function App() {
  return (
    <HashRouter>
      <Route />
    </HashRouter>
  );
}

export default App;
