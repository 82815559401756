import React from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointer } from "@fortawesome/free-solid-svg-icons";

const Confirmation = ({ mainQuestion = "", description = "" }) => {
  return (
    <>
      <div className="d-flex align-items-center justify-content-center mt-4">
        <FontAwesomeIcon
          icon={faHandPointer}
          color="#a63958"
          className="mr-1"
          style={{ fontSize: "40" }}
        />
      </div>

      <Modal.Body>
        <div className="d-flex align-items-center justify-content-center mt-2">
          <h4 className="text-muted">Adicionar al playlist:</h4>
        </div>
        <div className="d-flex align-items-center justify-content-center mt-2">
          <h6>{description}</h6>
        </div>
      </Modal.Body>
    </>
  );
};

export const CustomModal = props => {
  let modalContent = null;
  function* getColorBox() {
    let counter = 0;
    const map = [
      "color-5ol",
      "color-4bb",
      "color-3pb",
      "color-2pl",
      "color-1o"
    ];
    while (true) {
      yield map[counter++];
      if (counter > 4) {
        counter = 0;
      }
    }
  }
  const gen = getColorBox();
  modalContent = (
    <Confirmation
      mainQuestion={props.mainQuestion}
      description={props.description}
    />
  );
  return (
    <Modal
      animation={false}
      show={props.show}
      onHide={() => props.handleClose()}
      backdrop="static"
    >
      {modalContent}
      <Row
        style={{
          width: "90%",
          marginLeft: "10%",
          color: "white",
          fontWeight: "bold",
          fontSize: "10pt"
        }}
      >
        {props.playlists.map(pl => {
          return (
            <Col
              style={{
                maxHeight: "12vh",
                margin: "10px",
                minHeight: "4vh",
                cursor: "pointer",
                paddingTop: "1vh"
              }}
              className={`${gen.next().value}`}
              lg="5"
              key={pl.id}
              onClick={() => props.handleAddToPlayList(pl.id)}
            >
              {pl.name}
            </Col>
          );
        })}
      </Row>
      <Modal.Footer as={Row} className="mr-1" style={{ border: "0px" }}>
        <Button
          variant="secondary"
          onClick={() => props.handleClose()}
          block
          as={Col}
          lg={5}
          className="mr-1"
        >
          CANCELAR
        </Button>
        <span />
      </Modal.Footer>
    </Modal>
  );
};
