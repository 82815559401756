import React from "react";
import { ViewArtist } from "../components/public-site/artist/artist.view";
import { ArtistProvider } from "../context/site/artist/artist.state";

export const SingleArtistPage = props => {
  return (
    <ArtistProvider>
      <ViewArtist {...props} />
    </ArtistProvider>
  );
};
