import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../../../../images/logo_INBEAT_Color.png";
export const Header = () => {
  return (
    <div className="header">
      <Container>
        <Row>
          <Col
            md={{ span: 2, offset: 1 }}
            xs={6}
            className="d-flex align-items-center justify-content-center"
            style ={{paddingBottom: '30px'}}
          >
            <img src={logo} height="65" width="150" alt="INBEAT" />
          </Col>
          <Col
            md={{ span: 2, offset: 6 }}
            xs={6}
            className="d-flex align-items-center justify-content-end login-box"
            style ={{paddingBottom: '20px'}}
          >
            <a
              href="#/site-login"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              Iniciar Sesión
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
