import { useReducer, useCallback } from "react";

const ACTIONS = {
  TOGGLE_SHOW_NOTIFICATION: "toggle-show-notification"
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.TOGGLE_SHOW_NOTIFICATION:
      return {
        ...state,
        notify: Object.prototype.hasOwnProperty.call(action.payload, "notify")
          ? action.payload.notify
          : !state.notify,
        msg: action.payload.msg,
        status: action.payload.status
      };
    default:
      return state;
  }
};

export const useNotification = () => {
  const [state, dispatch] = useReducer(reducer, {
    notify: false,
    msg: "",
    status: "idle"
  });

  const showNotification = useCallback(param => {
    if (!param) {
      param = { msg: "", status: "idle", notify: false };
    }
    dispatch({
      type: ACTIONS.TOGGLE_SHOW_NOTIFICATION,
      payload: param
    });
  }, []);

  return {
    showNotification,
    notificationConfig: { msg: state.msg, status: state.status },
    notify: state.notify
  };
};
