import React from "react";
import { Card, Accordion } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo, faBold, faStream } from "@fortawesome/free-solid-svg-icons";
import { serverPath } from "../../context/utils/file.path";
import ReactHtmlParser from 'react-html-parser'
const url = `${serverPath}`;
const styleElements = {
  accordion: { fontSize: "14px", cursor: "pointer" },
  iconSize: { fontSize: "14px" },
  interviewLink: { fontSize: "14px", fontWeight: 600, color: "#17a2b8" },
  videoSize: { fontSize: "12px" }
};


export const ArtistCardFooter = props => {
  const { accordion, iconSize, interviewLink, videoSize } = styleElements;
  const { contents, interview, biography } = props;

  return (
    <div className="d-flex justify-content-between">
      <div>
        <Accordion>
          <Accordion.Toggle
            as={"b"}
            size="sm"
            className="mr-3 text-info"
            eventKey="1"
            style={accordion}
          >
            <FontAwesomeIcon
              icon={faBold}
              className="mr-1 ml-2 text-info"
              style={iconSize}
            />
            Biografía
          </Accordion.Toggle>

          <Accordion.Toggle
            as={"b"}
            size="sm"
            className="text-info"
            eventKey="2"
            disabled={!contents.length}
            style={accordion}
          >
            <FontAwesomeIcon
              icon={faStream}
              className="mr-1 text-info"
              style={iconSize}
            />
            Contenido
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body className="p-1 m-0 mt-1">{ReactHtmlParser(biography)}</Card.Body>
          </Accordion.Collapse>
          <Accordion.Collapse eventKey="2">
            <Card.Body className="p-1 m-0 mt-1">
              <ol>
                {contents.map(_ => (
                  <li key={_.id}>
                    <a
                      href={`${url}contents/${_.content}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      {_.name}
                    </a>
                  </li>
                ))}
              </ol>
            </Card.Body>
          </Accordion.Collapse>
        </Accordion>
      </div>
      <div>
        <a
          href={`${url}artists/${interview}`}
          target="_blank"
          rel="noopener noreferrer"
          download
          style={interviewLink}
          className="mr-1"
        >
          <FontAwesomeIcon icon={faVideo} className="mr-1" style={videoSize} />
          Entrevista
        </a>
      </div>
    </div>
  );
};
