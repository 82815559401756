import React from "react";
import { Row, Alert, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBug,
  faThumbsUp,
  faRadiation
} from "@fortawesome/free-solid-svg-icons";
export const Notification = ({
  notify,
  notificationConfig,
  showNotification
}) => {
  const textColor =
    notificationConfig.status === "dark" ? "#721c24" : "#155724";
  const iconList = { dark: faBug, success: faThumbsUp, warning: faRadiation };
  const icon = iconList[notificationConfig.status];
  return (
    <>
      {notify && (
        <Row style={{ minWidth: "80%" }} className="float-left">
          <Col lg="6">
            <Alert
              variant={notificationConfig.status}
              className="mt-2 ml-4"
              style={{ color: textColor }}
              dismissible
              onClose={() => showNotification()}
            >
              <FontAwesomeIcon icon={icon} color={textColor} className="mr-1" />
              {notificationConfig.msg}
            </Alert>
          </Col>
        </Row>
      )}
    </>
  );
};
