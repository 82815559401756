import React from "react";
import { CustomActions } from "./table.actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAd } from "@fortawesome/free-solid-svg-icons";
import { serverPath } from "../../context/utils/file.path";
const url = `${serverPath}news/`;

// titulo, fecha, imagen, resumen(128) , cuerpo de la noticia, estado, promote

const Columns = [
  {
    // name: "Imagen",
    grow: 0,
    cell: row => (
      <img
        height="84px"
        width="56px"
        alt={row.title}
        src={`${url}${row.image}`}
      />
    )
  },
  {
    name: "Título",
    cell: row => <CustomTitle row={row} />
  },
  {
    name: "Resumen",
    selector: "summary",
    maxWidth: "500px",
    cell: row => <CustomSummary row={row} />
  },

  {
    name: "Estado",
    cell: row => <StatusBadge status={row.status_name} />
  },
  {
    name: "Acciones",
    cell: row => (
      <CustomActions
        id={row.id}
        status={row.status_name}
        isPromoted={row.promoted}
      />
    )
  },
  {
    cell: row =>
      row.promoted ? (
        <FontAwesomeIcon
          className="text-info mr-2"
          icon={faAd}
          title="PROMOCIONADO"
          style={{ fontSize: "24px" }}
        />
      ) : (
        <></>
      ),
    width: "56px" // custom width for icon button
  }
];

const CustomTitle = ({ row }) => (
  <div>
    {}
    <div>{row.title}</div>
    <div>
      <div
        style={{
          color: "grey",
          overflow: "hidden",
          whiteSpace: "wrap",
          textOverflow: "ellipses"
        }}
      >
        {}
        {new Date(row.created_at).toLocaleDateString()}
      </div>
    </div>
  </div>
);
const CustomSummary = ({ row }) => (
  <div>
    {}
    <div>
      <div
        style={{
          color: "grey",
          overflow: "hidden",
          whiteSpace: "wrap",
          textOverflow: "ellipses"
        }}
      >
        {}
        {row.summary}
      </div>
    </div>
  </div>
);

const StatusBadge = ({ status }) => {
  const mapClass = {
    Activo: "badge-success",
    Eliminado: "badge-danger"
  };
  return (
    <span className={`badge ${mapClass[status]}`}>{status.toUpperCase()}</span>
  );
};

const ExpanableComponent = ({ data }) => (
  <div
    style={{
      marginLeft: "4rem",
      fontSize: "12px",
      marginTop: "1rem",
      marginBottom: "1rem"
    }}
  >
    <div>{data.body}</div>
  </div>
);

const paginationOptions = {
  rowsPerPageText: "Filas por página",
  rangeSeparatorText: "de",
  selectAllRowsItem: true,
  selectAllRowsItemText: "Todos"
};

export { Columns, paginationOptions, ExpanableComponent };
