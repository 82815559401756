import React, { useContext, useEffect } from "react";
import { PageTitle } from "../commons/page.title";
import { Notification } from "../commons/notification";
import LoadingPage from "../commons/loading.page";
import { Row } from "react-bootstrap";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { ArtistContext } from "../../context/artist/artist.state";
import { Formik } from "formik";
import { Redirect } from "react-router-dom";
import * as EditSchema from "./artist.schema";
import { ArtistForm } from "./artist.form";

const LoadingEmptyComponent = LoadingPage(<></>);

export const ArtistEdit = props => {
  const {
    isLoading,
    editArtist,
    notify,
    showNotification,
    notificationConfig,
    redirect,
    getArtistById,
    toEdit
  } = useContext(ArtistContext);

  const { aid } = props.match.params;

  useEffect(() => {
    getArtistById(aid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let redirectComponent = (
    <Redirect
      to={{
        pathname: "/artist",
        state: {
          show: true,
          message: "Exito! El artista se ha editado",
          status: "success"
        }
      }}
    ></Redirect>
  );

  if (redirect) {
    return redirectComponent;
  }

  if (isLoading === true) {
    return <LoadingEmptyComponent data={isLoading} />;
  }
  if (Object.keys(toEdit).length > 0 && isLoading === false) {
    let initialValues = EditSchema.initialValues;
    initialValues = toEdit;
    initialValues.picture = "";
    initialValues.interview = "";
    return (
      <>
        <Row>
          <Notification
            notify={notify}
            notificationConfig={notificationConfig}
            showNotification={showNotification}
          />
        </Row>
        <PageTitle title="+ Editar Artista" />

        <Formik
          validationSchema={EditSchema.getSchema("edit")}
          onSubmit={values => {
            const editedArtist = {
              id: values.id,
              name: values.name,
              facebook: values.facebook,
              instagram: values.instagram,
              twitter: values.twitter,
              biography: values.biography
            };
            if (values.picture) {
              editedArtist.picture = values.picture;
            }
            if (values.interview) {
              editedArtist.interview = values.interview;
            }
            editArtist(editedArtist);
          }}
          initialValues={initialValues}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            values,
            touched,
            isValid,
            errors
          }) => (
            <ArtistForm
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              touched={touched}
              errors={errors}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              btnTxt="GUARDAR CAMBIOS"
              btnIcon={faSave}
            />
          )}
        </Formik>
      </>
    );
  }

  return (
    <Row>
      <Notification
        notify={notify}
        notificationConfig={notificationConfig}
        showNotification={showNotification}
      />
    </Row>
  );
};
