import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { PromotedContext } from "../../context/promoted/promoted.state";
import { PromotedAdd } from "./promoted.add";

const paginationOptions = {
  rowsPerPageText: "Filas por página",
  rangeSeparatorText: "de",
  selectAllRowsItem: true,
  selectAllRowsItemText: "Todos"
};

const Columns = [
  {
    name: "Título",
    selector: "name"
  },
  {
    name: "Fecha",
    cell: row => new Date(row.created_at).toLocaleDateString()
  },
  {
    name: "Acciones",
    cell: row => <CustomActions id={row.id} promoted={row.promoted} />
  }
];

const CustomActions = ({ id, promoted, table }) => {
  const { showModalWithConfig, countPromotes } = useContext(PromotedContext);

  return (
    <>
      {countPromotes < 5 && !promoted && (
        <div
          onClick={() => showModalWithConfig("promote", id, <PromotedAdd />)}
          style={{ cursor: "pointer" }}
        >
          <FontAwesomeIcon
            className="text-dark mr-2"
            icon={faLink}
            title="PROMOCIONAR"
            style={{ fontSize: "16px" }}
          />
          <span className="font-weight-bold text-info"> PROMOCIONAR </span>
        </div>
      )}
    </>
  );
};

export { Columns, paginationOptions };
