import { GenresProvider } from "./genres/genres.state";
import { ArtistProvider } from "./artist/artist.state";
import { ContentProvider } from "./content/content.state";
import { AuthProvider } from "./auth/auth.state";
import { NewsProvider } from "./news/news.state";
import { PromotedProvider } from "./promoted/promoted.state";
import { LandingProvider } from "./site/landing.state";
export default {
  GenresProvider,
  ArtistProvider,
  ContentProvider,
  AuthProvider,
  NewsProvider,
  PromotedProvider,
  LandingProvider
};
