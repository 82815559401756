import React from "react";
import { SiteRegister } from "../components/public-site/login/site.register";
import { SiteAuthProvider } from "../context/site/siteAuth/site.auth.state";
export const PageSiteRegister = props => {
  return (
    <div>
      <SiteAuthProvider>
        <SiteRegister {...props} />
      </SiteAuthProvider>
    </div>
  );
};
