const config = {
  unpromote: {
    type: "confirmation",
    mainQuestion: "Está seguro?",
    description:
      "Tenga en cuenta que esta acción afecta el comportamiento de la página principal del sitio. Está seguro que desea dejar de promocionar el contenido?",
    action: "unpromote"
  },
  promote: {
    type: "component",
    action: "promote"
  },
  default: {
    type: "confirmation",
    mainQuestion: "",
    description: ""
  }
};

const getPromotions = (state, action) => {
  return {
    ...state,
    promotions: action.payload,
    countPromotes: action.payload.length
  };
};

const filterContent = (state, action) => {
  return {
    ...state,
    contentFiltered: action.payload.data,
    currentTable: action.payload.table,
    isFiltering: false
  };
};

const setFilteringToTrue = (state, action) => {
  return {
    ...state,
    isFiltering: true
  };
};

const imageValidate = (state, action) => {
  const mapErrors = {
    require: "La imagen es requerida",
    format: "Solo se aceptan los siguientes formatos .jpg, .jpeg, .png",
    size: "La imagen no debe superar los 2MB"
  };
  return {
    ...state,
    isInvalid: true,
    errors: mapErrors[action.payload]
  };
};

const setImage = (state, action) => {
  return {
    ...state,
    image: action.payload.img,
    isInvalid: action.payload.hasError
  };
};

const SetModalOption = (state, action) => {
  return {
    ...state,
    modalConfig: {
      ...config[action.payload.option],
      id: action.payload.id,
      component: action.payload.component,
      table: action.payload.table
    }
  };
};

const doActions = (state, action) => {
  if (action.payload.action === "unpromote") {
    const toUnpromte = state.promotions.filter(
      _ => _.id === action.payload.eid
    )[0];
    let tempFiltered = state.contentFiltered;
    if (state.currentTable && action.payload.table === state.currentTable) {
      tempFiltered = state.contentFiltered.map(_ => {
        if (_.id === toUnpromte.content_id) {
          const temp = { ..._ };
          temp.promoted = !temp.promoted;
          return temp;
        }
        return _;
      });
    }
    const promotionsFiltered = state.promotions.filter(
      _ => _.id !== action.payload.eid
    );

    return {
      ...state,
      promotions: promotionsFiltered,
      countPromotes: promotionsFiltered.length,
      contentFiltered: tempFiltered
    };
  }
  if (action.payload.action === "promote") {
    return {
      ...state,
      promotions: action.payload.promotions,
      contentFiltered: state.contentFiltered.map(_ => {
        if (_.id === action.payload.eid) {
          const temp = { ..._ };
          temp.promoted = !temp.promoted;
          return temp;
        }
        return _;
      }),
      countPromotes: state.countPromotes + 1,
      image: null,
      isInvalid: false,
      errors: ""
    };
  }
  return { ...state };
};

export default (state, action) => {
  const actions = {
    GET_PROMOTIONS: getPromotions,
    DO_ACTIONS: doActions,
    FILTER_CONTENT: filterContent,
    SET_FILTERING_TO_TRUE: setFilteringToTrue,
    SET_MODAL_OPTION: SetModalOption,
    IMAGE_VALIDATE: imageValidate,
    SET_IMAGE: setImage
  };
  return actions[action.type](state, action) || state;
};
