import React, { useContext, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { HeaderLogin } from "../landing-page/components/header.login";
import { Footer } from "../landing-page/components/footer";
import { NewsContext } from "../../../context/site/news/news.state";
import { serverPath } from "../../../context/utils/file.path";
import { SiteAuthProvider } from "../../../context/site/siteAuth/site.auth.state";

const urlContents = `${serverPath}news/`;
export const News = () => {
  const { news, getNews } = useContext(NewsContext);

  useEffect(() => {
    getNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function* getColorBox() {
    let counter = 0;
    const map = [
      "box-yellow",
      "box-yellow",
      "box-orange",
      "box-orange",
      "box-purple",
      "box-purple",
      "box-blue",
      "box-blue"
    ];
    while (true) {
      yield map[counter++];
      if (counter > 7) {
        counter = 0;
      }
    }
  }
  const gen = getColorBox();
  return (
    <>
      <SiteAuthProvider>
        <HeaderLogin />
      </SiteAuthProvider>
      <div className="top-rated pb-4" style={{ backgroundColor: "#14123c" }}>
        <Container>
          <Row className="mr-1 ml-1">
            {news.map((it, index) => {
              const lg =
                index % 2 === 0
                  ? { span: 5, offset: 1 }
                  : { span: 5, offset: 1 };
              return (
                <Col
                  lg={lg}
                  className="mt-4 mr-0"
                  style={{
                    minHeight: "22vh",
                    backgroundColor: "#f0f8ff2e"
                  }}
                  key={it.id}
                >
                  <Row>
                    <Col lg="4" className="pl-1 pr-1">
                      {/* <img
                        src={`${urlContents}${it.image}`}
                        style={{ width: "100%" }}
                        className="mt-1 news-img-jesse"
                        alt="noticia"
                      /> */}
                      <div style={{width: "100%", backgroundImage: `url("${urlContents}${it.image}")`, backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }} className="mt-1 news-img-jesse"/>
                    </Col>
                    <Col lg="8" className="pl-2">
                      <p
                        style={{
                          fontFamily: "Monserrat",
                          fontSize: "16pt",
                          fontWeight: "bold",
                          color: "white"
                        }}
                        className="p-0 m-0"
                      >
                        {it.title}
                      </p>
                      <p
                        style={{
                          fontFamily: "Monserrat",
                          fontSize: "10pt",
                          textAlign: "justify",
                          color: "white",
                          minHeight: "11.4vh",
                          maxHeight: "11.4vh",
                          overflow: "hidden"
                        }}
                        className="p-0 m-0 mb-1"
                      >
                        {it.summary}
                      </p>
                      <a
                        size="sm"
                        className={`font-weight-bold btn btn-sm ${
                          gen.next().value
                        }`}
                        href={`#/site-news/${it.id}`}
                        style={{
                          color: "white",
                          fontSize: "11pt",
                          marginBottom: "1rem",
                          marginTop: ".7rem"
                        }}
                      >
                        Leer más
                      </a>
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};
