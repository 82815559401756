import * as Yup from "yup";

const FILE_SIZE = 2097152;
const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const commonSchema = {
  title: Yup.string()
    .min(5, "Debe introducir un título de al menos 5 caracteres")
    .max(128, "El título no debe superar los 128 caracteres")
    .required("Este campo es requerido."),
  summary: Yup.string()
    .min(10, "Debe introducir un resumen de al menos 10 caracteres")
    .max(500, "El resumen no debe superar los 500 caracteres")
    .required("Este campo es requerido."),
  body: Yup.string()
    .min(10, "Debe introducir un cuerpo de noticia de al menos 10 caracteres")
    .required("Este campo es requerido."),
  status_id: Yup.string().required("Este campo es requerido."),
  date: Yup.date().required("La fecha es requerida")
};

const addSchema = {
  image: Yup.mixed()
    .required("La foto de la noticia es requerida.")
    .test(
      "picture-format",
      "Solo se aceptan los siguientes formatos .jpg, .jpeg, .png",
      value => !(value && !SUPPORTED_FORMATS.includes(value.type))
    )
    .test(
      "picture-size",
      "La imagen no debe superar los 2MB",
      value => !(value && value.size > FILE_SIZE)
    )
};

const editSchema = {
  image: Yup.mixed()
    .test(
      "picture-format",
      "Solo se aceptan los siguientes formatos .jpg, .jpeg, .png",
      value => !(value && !SUPPORTED_FORMATS.includes(value.type))
    )
    .test(
      "picture-size",
      "La imagen no debe superar los 2MB",
      value => !(value && value.size > FILE_SIZE)
    )
};

const getSchema = type => {
  const toAssign = type === "add" ? addSchema : editSchema;
  return Yup.object(Object.assign(commonSchema, toAssign));
};

let initialValues = {
  title: "",
  summary: "",
  body: "",
  status_id: "",
  image: "",
  date: ""
};

export { getSchema, initialValues, FILE_SIZE, SUPPORTED_FORMATS };
