import React, { useMemo, useState, useContext, useEffect } from "react";
import { PageTitle } from "../commons/page.title";
import { AddElement } from "../commons/add.element";
import LoadingPage from "../commons/loading.page";
import { FilterForm } from "../commons/filter.form";
import { CustomModal } from "../commons/modal";
import { Notification } from "../commons/notification";
import { ArtistList } from "./artist.list";
import { ArtistContext } from "../../context/artist/artist.state";

const artistCardModalConfig = {
  activate: {
    type: "confirmation",
    mainQuestion: "Está seguro?",
    description: "Está seguro que desea activar el artista?"
  },
  delete: {
    type: "confirmation",
    mainQuestion: "Está seguro?",
    description: "Está seguro que desea eliminar el artista?"
  },
  default: {
    type: "confirmation",
    mainQuestion: "",
    description: ""
  }
};

const ResultArtistList = LoadingPage(ArtistList);

export const Artist = props => {
  let {
    isLoading,
    getArtists,
    artists,
    notify,
    showNotification,
    notificationConfig,
    show,
    showModal,
    doActions
  } = useContext(ArtistContext);
  const [filterText, setFilterText] = useState("");
  const [modalConfig, setModalConfig] = useState({
    ...artistCardModalConfig.default
  });

  useEffect(() => {
    let msg = null;
    if (
      props.location.state &&
      props.location.state.show === true &&
      props.history.action === "REPLACE"
    ) {
      msg = props.location.state.message;
    }
    getArtists(msg);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredItems = artists.filter(
    item =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleShowModal = (action, eid) => {
    showModal({ eid, action });
    const actionConfig = artistCardModalConfig[action];
    setModalConfig(actionConfig);
  };

  const subHeaderFilterForm = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText("");
      }
    };
    return (
      <FilterForm
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        label="Filtrar Artista"
        placeholder="Filtre por el nombre del artista."
      />
    );
  }, [filterText]);
  return (
    <>
      <Notification
        notify={notify}
        notificationConfig={notificationConfig}
        showNotification={showNotification}
      />
      <AddElement txt="ADICIONAR ARTISTA" action="add-artist" />
      <div style={{ clear: "both" }}></div>
      <PageTitle title="Administrar Artistas" />
      <ResultArtistList
        artists={filteredItems}
        data={isLoading}
        subHeaderFilterForm={subHeaderFilterForm}
        handleShowModal={handleShowModal}
      />
      <CustomModal
        show={show}
        onClose={showModal}
        onAccept={doActions}
        {...modalConfig}
      />
    </>
  );
};
