import axios from "axios";
import { serverPath } from "./file.path";

export const httpRequest = service => {
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded"
  };
  //   const server = "https://cors-anywhere.herokuapp.com/https://jobs.github.com/";
  // const server = "http://167.86.115.152/beatec/api/v1/";
  const server = `${serverPath}v1/`;
  if (service instanceof Array) {
    let promiseAll = service.map(_ => {
      const data = openTokenData(_.data, _.url);
      return {
        method: "post",
        url: `${server}${_.url}`,
        data: data || {},
        headers
      };
    });
    return axios.all(promiseAll.map(_ => axios(_)));
  }
  const data = openTokenData(service.data, service.url);
  return axios({
    method: "post",
    url: `${server}${service.url}`,
    data: data || {},
    headers
  });
};

const openTokenData = (data, endpoint) => {
  const excludesTokenRoutes = [
    "security/do_register",
    "security/do_login",
    "security/request_password_recovery",
    "security/password_recovery"
  ];
  const siteStorageMap = {
    "artists/get": "main-site-inbeat",
    "artists/get_by_id": "main-site-inbeat",
    "playlist/add_favorite": "main-site-inbeat",
    "playlist/remove_favorite": "main-site-inbeat",
    "contents/get_to_show": "main-site-inbeat",
    "interactions/count_reproduction": "main-site-inbeat",
    "interactions/vote_content": "main-site-inbeat",
    "playlist/get": "main-site-inbeat",
    "playlist/add_content": "main-site-inbeat",
    "news/get": "main-site-inbeat",
    "news/get_by_id": "main-site-inbeat",
    "playlist/create_playlist": "main-site-inbeat",
    "playlist/modify_playlist": "main-site-inbeat",
    "playlist/remove_content": "main-site-inbeat",
    "playlist/delete_playlist": "main-site-inbeat",
    "contents/get_by_id": "main-site-inbeat",
    "promotions/get": "main-site-inbeat",
    "contents/most_voted": "main-site-inbeat",
    "contents/most_listened": "main-site-inbeat",
    "promotions/news": "main-site-inbeat"
  };
  if (!data) {
    data = new FormData();
  }
  if (!excludesTokenRoutes.includes(endpoint)) {
    const localStorageName = siteStorageMap[endpoint] || "inbeat";
    const token = JSON.parse(localStorage.getItem(localStorageName))
      ? JSON.parse(localStorage.getItem(localStorageName)).token
      : null;
    data.append("token", token);
  }
  return data;
};
