import React from "react";

export const EmptyTable = () => (
  <div
    style={{
      position: "fixed",
      top: "55%",
      left: "45%",
      transform: "translate(-50%, -50%)"
    }}
  >
    <h5 style={{ fontWeight: "bold", textTransform: "uppercase" }}>
      no existen resultados
    </h5>
  </div>
);
