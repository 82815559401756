import React from "react";
import { Row, Col } from "react-bootstrap";
import musicIcon from "../../../images/icono_nota_musical.png";
export const PlayListTitle = () => {
  return (
    <Row className="mr-1 mb-4 pt-4">
      <Col lg={{ span: 2, offset: 9 }} xs={{ span: 5, offset: 7 }}>
        <img
          src={musicIcon}
          width="25"
          height="20"
          alt="Play List"
          className="mr-1"
        />
        <span
          style={{
            fontFamily: '"Montserrat", sans-serif',
            color: "black",
            fontWeight: "bold",
            fontSize: "10pt"
          }}
        >
          {" "}
          Mi playlist{" "}
        </span>
      </Col>
    </Row>
  );
};
