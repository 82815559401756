import React from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImage } from "@fortawesome/free-solid-svg-icons";
import { UploadFile } from "../commons/upload.file";
import {CKEditor} from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

export const NewsForm = props => {
  let {
    handleSubmit,
    values,
    touched,
    handleChange,
    handleBlur,
    errors,
    setFieldValue,
    setFieldTouched,
    btnTxt,
    btnIcon,
    cathalogs
  } = props;
  const dateValue = values.date ? new Date(values.date).toJSON().slice(0,10) : ""

  return (
    <Form className="mb-4 ml-4 mr-2" onSubmit={handleSubmit}>
      <Form.Row as={Row}>
        <Form.Group as={Col} xs={6}>
          <Form.Label>Título de la noticia</Form.Label>
          <Form.Control
            name="title"
            type="text"
            placeholder="Ingrese el título de la noticia."
            value={values.title}
            onChange={handleChange}
            onBlur={handleBlur}
            isValid={touched.title && !errors.title}
            isInvalid={touched.title && !!errors.title}
          />
          <Form.Control.Feedback>Entrada correcta!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {errors.title}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} xs={6}>
          <Form.Label>Fecha de la noticia</Form.Label>
          <Form.Control
            name="date"
            type="date"
            value={dateValue}
            onChange={handleChange}
            onBlur={handleBlur}
            isValid={touched.date && !errors.date}
            isInvalid={touched.date && !!errors.date}
          />
          <Form.Control.Feedback>Entrada correcta!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {errors.date}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} xs={6}>
          <Form.Label className="my-1 mr-2">Estado de la noticia</Form.Label>
          <Form.Control
            as="select"
            name="status_id"
            value={values.status_id}
            onChange={handleChange}
            onBlur={handleBlur}
            isValid={touched.status_id && !errors.status_id}
            isInvalid={touched.status_id && !!errors.status_id}
          >
            <option value="-1">Seleccione el estado</option>
            {cathalogs.status.map(_ => (
              <option value={_.id} key={_.id}>
                {_.name}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback>Entrada correcta!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {errors.status_id}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} xs={6}>
          <Form.Label>Resumen</Form.Label>
          <Form.Control
            as="textarea"
            rows="3"
            name="summary"
            value={values.summary}
            onChange={handleChange}
            onBlur={handleBlur}
            isValid={touched.summary && !errors.summary}
            isInvalid={touched.summary && !!errors.summary}
          />
          <Form.Control.Feedback>Entrada correcta!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {errors.summary}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} xs={12}>
          <Form.Label>Cuerpo de la noticia</Form.Label>
          <CKEditor
            editor={ ClassicEditor }
            data={values.body}
            name="body"
            config={ {
              removePlugins: [ 'MediaEmbed', 'EasyImage', 'ImageUpload', 'Table' ]
          } }
            onChange={ ( event, editor ) => {
                const data = editor.getData();
                setFieldTouched("body")
                setFieldValue("body", data)
            } }
        />
          {/* <Form.Control
            as="textarea"
            rows="3"
            name="body"
            value={values.body}
            onChange={handleChange}
            onBlur={handleBlur}
            isValid={touched.body && !errors.body}
            isInvalid={touched.body && !!errors.body}
          /> */}
          <Form.Control.Feedback>Entrada correcta!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {errors.body}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} xs={6}>
          <UploadFile
            icon={faFileImage}
            placeholder="Foto de la noticia"
            handleChange={setFieldValue}
            value={values.image}
            name="image"
            isValid={touched.image && !errors.image}
            isInvalid={touched.image && !!errors.image}
            errors={errors.image}
            label="Fotografía"
            key="1"
            handleTouched={setFieldTouched}
          />
        </Form.Group>
        <Form.Group as={Col} xs={6} className="mr-4">
          <Button variant="info" type="submit">
            <FontAwesomeIcon icon={btnIcon} color="white" className="mr-2" />
            {btnTxt}
          </Button>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};
