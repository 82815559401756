import React, { useContext } from "react";
import { PageTitle } from "../commons/page.title";
import { Row } from "react-bootstrap";
import { Notification } from "../commons/notification";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { ArtistContext } from "../../context/artist/artist.state";
import { Formik } from "formik";
import { Redirect } from "react-router-dom";
import LoadingPage from "../commons/loading.page";
import * as AddSchema from "./artist.schema";
import { ArtistForm } from "./artist.form";

const LoadingEmptyComponent = LoadingPage(<></>);
export const ArtistAdd = () => {
  const {
    isLoading,
    addArtists,
    notify,
    showNotification,
    notificationConfig,
    redirect
  } = useContext(ArtistContext);

  let redirectComponent = (
    <Redirect
      to={{
        pathname: "/artist",
        state: {
          show: true,
          message: "Exito! El artista se ha adicionao",
          status: "success"
        }
      }}
    ></Redirect>
  );

  if (redirect) {
    return redirectComponent;
  }

  if (isLoading === true) {
    return <LoadingEmptyComponent data={isLoading} />;
  }

  return (
    <>
      <Row>
        <Notification
          notify={notify}
          notificationConfig={notificationConfig}
          showNotification={showNotification}
        />
      </Row>
      <PageTitle title="+ Adicionar Artista" />

      <Formik
        validationSchema={AddSchema.getSchema("add")}
        onSubmit={values => {
          addArtists({
            name: values.name,
            facebook: values.facebook,
            instagram: values.instagram,
            twitter: values.twitter,
            biography: values.biography,
            picture: values.picture,
            interview: values.interview
          });
        }}
        initialValues={AddSchema.initialValues}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
          setFieldValue,
          setFieldTouched
        }) => (
          <ArtistForm
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            handleBlur={handleBlur}
            values={values}
            touched={touched}
            errors={errors}
            setFieldTouched={setFieldTouched}
            setFieldValue={setFieldValue}
            btnTxt="ADICIONAR ARTISTA"
            btnIcon={faPlus}
          />
        )}
      </Formik>
      
    </>
  );
};
