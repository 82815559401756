import { useReducer, useCallback, useEffect } from "react";

const ACTIONS = {
  MAKE_REQUEST: "make-request",
  GET_DATA: "get-data",
  ERROR: "error"
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.MAKE_REQUEST:
      return { loading: true, response: [], error: false };
    case ACTIONS.GET_DATA:
      return { ...state, loading: false, response: action.payload.response };
    case ACTIONS.ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        response: []
      };
    default:
      return state;
  }
};

export const useHttpRequest = (asyncFunction, immediate = true) => {
  const [state, dispatch] = useReducer(reducer, {
    response: [],
    loading: false,
    error: false
  });

  const execute = useCallback(
    async param => {
      dispatch({ type: ACTIONS.MAKE_REQUEST });
      try {
        // setTimeout(async () => {
        const response = await asyncFunction(param);
        dispatch({
          type: ACTIONS.GET_DATA,
          payload: { response: response.data }
        });
        if (response instanceof Array) return response;
        return response.data;
        // }, 2000);
      } catch (error) {
        dispatch({ type: ACTIONS.ERROR, payload: { error } });
        throw new Error();
      }
    },
    [asyncFunction]
  );

  useEffect(() => {
    const makeRequest = async () => {
      await execute();
    };
    if (immediate) {
      makeRequest();
    }
  }, [execute, immediate]);

  if (state.response && state.response.cod === 121) {
    localStorage.removeItem("main-site-inbeat");
    localStorage.removeItem("inbeat");
  }

  return { execute, ...state };
};
