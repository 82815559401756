import React, { useEffect, useContext, useState, useMemo } from "react";
import { PageTitle } from "../commons/page.title";
import { FilterForm } from "../commons/filter.form";
import { AddElement } from "../commons/add.element";
import { CustomModal } from "../commons/modal";
import { Notification } from "../commons/notification";
import LoadingPage from "../commons/loading.page";
import { AddGenreModalForm } from "./add.modal.form";
import { CardList } from "./card.list";
import { GenresContext } from "../../context/genres/genres.state";

const categoryCardModalConfig = {
  activate: {
    type: "confirmation",
    mainQuestion: "Está seguro?",
    description: "Está seguro que desea activar la categoría?"
  },
  delete: {
    type: "confirmation",
    mainQuestion: "Está seguro?",
    description: "Está seguro que desea eliminar la categoría?"
  },
  add: {
    type: "component"
  },
  default: {
    type: "confirmation",
    mainQuestion: "",
    description: ""
  }
};

const ResultCardList = LoadingPage(CardList);

export const Genres = () => {
  const {
    isLoading,
    getCategories,
    categories,
    notify,
    showNotification,
    notificationConfig,
    show,
    showModal,
    addGenre,
    doActions
  } = useContext(GenresContext);

  const [filterText, setFilterText] = useState("");

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [genreName, setGenreName] = useState("");

  const [modalConfig, setModalConfig] = useState({
    ...categoryCardModalConfig.default
  });

  const onChangeGenreName = e => setGenreName(e.target.value);

  const handleAddGenre = () => {
    addGenre(genreName);
    setGenreName("");
  };

  const handleShowModal = (action, eid) => {
    showModal({ eid, action });
    const actionConfig = categoryCardModalConfig[action];
    setModalConfig(actionConfig);
  };

  const handleAddModal = () => {
    showModal({ eid: -1, action: "add" });
    const addConfig = categoryCardModalConfig.add;
    addConfig.component = (
      <AddGenreModalForm handleGenreName={onChangeGenreName} />
    );
    setModalConfig(addConfig);
  };

  const filteredItems = categories.filter(
    item =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderFilterForm = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText("");
      }
    };
    return (
      <FilterForm
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        label="Filtrar Géneros Músicales"
        placeholder="Ej. Pop"
      />
    );
  }, [filterText]);

  const actionModalAccept =
    modalConfig.type === "confirmation" ? doActions : handleAddGenre;

  return (
    <>
      <Notification
        notify={notify}
        notificationConfig={notificationConfig}
        showNotification={showNotification}
      />
      <AddElement txt="ADICIONAR GÉNERO" action={handleAddModal} />
      <div style={{ clear: "both" }}></div>
      <PageTitle title="Administrar Géneros" />
      <ResultCardList
        categories={filteredItems}
        data={isLoading}
        subHeaderFilterForm={subHeaderFilterForm}
        handleShowModal={handleShowModal}
      />
      <CustomModal
        show={show}
        onClose={showModal}
        onAccept={actionModalAccept}
        {...modalConfig}
      />
    </>
  );
};
