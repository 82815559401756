import React from "react";
import "./no.auth.css";
import { Container } from "react-bootstrap";
import { Header } from "./components/header";
import whiteLogo from "../../../images/logo_INbeat_White.png";
import DataTable, { createTheme } from "react-data-table-component";

export const TermsConditions = () => {
  const Columns = [
    {
      name: "Cantidad de Ganadores",
      selector: "cant"
      
    },
    {
      name: "Descripcion",
      selector: "desc"
    }
  ];

  const data = [{cant:4,desc: "Camisetas oficiales, del uniforme principal, de la selección ecuatoriana de Fútbol"}]

  return (
    <Container fluid className="p-0">
      <Header />
      <Container>
        <h3>Condiciones de uso y venta del Servicio InBeat Web</h3>
        <b>Artículo 1 – Generalidades</b>
        <p>
          Estas son las Condiciones de uso y venta del Servicio InBeat Web, que
          incluye el sitio web accesible en la dirección http://www.inbeat.live
          (en lo sucesivo, el &quot;Sitio&quot;).
        </p>
        <p>
          El Sitio ha sido creado por ECUSOLID S.A. (en lo sucesivo,
          &quot;ECUSOLID&quot;), empresa ecuatoriana.
        </p>
        <p>
          Estas condiciones de uso y venta (en adelante, las
          &quot;Condiciones&quot;) rigen exclusivamente el Servicio InBeat Web
          ofrecido por ECUSOLID. Se aplicarán a exclusión de cualesquiera otras
          condiciones, particularmente aquellas que se aplican a otros Servicios
          ofrecidos en el Sitio.
        </p>
        <p>
          El propósito de estas Condiciones es definir la relación contractual y
          comercial entre INBEAT, por un lado, y cualquier suscriptor del
          Servicio InBeat Web (en adelante, el &quot;Suscriptor&quot;), por otro
          lado.
        </p>
        <p>
          El uso del Sitio y del Servicio InBeat Web está autorizado a título
          personal y privado únicamente, por lo que se prohíbe cualquier otro
          uso, particularmente en lugares públicos y empresas.
        </p>
        <p>
          El uso del Sitio y del Servicio InBeat Web está autorizado a título
          personal y privado únicamente, por lo que se prohíbe cualquier otro
          uso, particularmente en lugares públicos y empresas.
        </p>
        <p>
          La suscripción y el acceso al Servicio InBeat Web están estrictamente
          sujetos a la aceptación previa y sin restricciones ni reservas de
          todas estas Condiciones (incluida la Política de privacidad).
        </p>
        <b>Artículo 2 – Presentación del Servicio InBeat Web</b>
        <p>
          El Servicio InBeat Web es un servicio ofrecido y operado por INBEAT
          que, previa aceptación de estas Condiciones, permite escuchar de forma
          ilimitada y exclusivamente desde un ordenador personal, teléfono móvil
          y/o Tablet, registros sonoros y otros contenidos musicales y
          culturales (en lo sucesivo, los &quot;Registros&quot;).
        </p>
        <p>
          Desde el Sitio, y una vez que se registre, el Suscriptor tendrá acceso
          a todas las funciones del Servicio InBeat Web.
        </p>
        <p>
          Las principales características del Servicio InBeat Web son las
          siguientes: <br /> - acceso ilimitado para escuchar los Registros en
          el Servicio InBeat Web; <br /> - sin publicidad; <br /> - sonido de
          alta calidad.
        </p>
        <p>
          Como Suscriptor, tendrá acceso al Servicio InBeat Web hasta que
          finalice su suscripción o al cancelarla bajo las Condiciones abajo
          estipuladas.
        </p>
        <p>
          Una vez que la suscripción haya vencido o haya sido cancelada, el
          acceso ilimitado y la escucha dejarán de estar autorizados.
        </p>
        <p>
          Los Registros se podrán escuchar en streaming durante todo el periodo
          de suscripción, si bien no podrán descargarse, transferirse o grabarse
          en cualquier medio.
        </p>
        <b>Artículo 3 – Uso del Servicio InBeat Web</b>
        <p>
          El uso del Servicio InBeat Web requiere de una conexión a Internet de
          alta velocidad. Dado que INBEAT no ofrece estas conexiones, el
          Suscriptor deberá contratar primero una conexión a Internet de alta
          velocidad para poder utilizar el Servicio InBeat Web.
        </p>
        <p>
          El catálogo musical que se ofrece con el Servicio InBeat Web está
          asociado a los contratos establecidos con los titulares de los
          derechos, por lo que está sujeto a modificaciones. ECUSOLID no puede
          garantizar la disponibilidad de ninguna pista o álbum determinado, o
          ningún artista o grupo en el catálogo del Servicio InBeat Web. Tampoco
          puede garantizar que las pistas, los álbumes, los artistas ni los
          grupos del catálogo del Servicio InBeat Web estén disponibles de forma
          indefinida. ECUSOLID no incurrirá responsabilidad alguna por la
          retirada de ninguna parte del contenido del catálogo ofrecido.
        </p>
        <p>
          El Suscriptor puede activar el Servicio InBeat Web desde su teléfono
          celular enviando un SMS al número corto 5511 con la palabra “SI”, “OK”
          o “MUSICA”, con las operadoras móviles con las cuales ECUSOLID
          mantenga el acuerdo comercial correspondiente. El suscriptor puede
          acceder a los Registros del Sitio a través un ordenador personal (PC o
          MAC), un teléfono celular con capacidad de navegación Web y/o Tablet.
          No se puede acceder al Servicio InBeat Web desde más de una conexión a
          la vez. INBEAT posee medios técnicos para verificar si existen varias
          conexiones simultáneas para la misma cuenta, por lo que puede detectar
          cualquier intento de efectuar varias conexiones.
        </p>
        <b>
          Artículo 4 – Disponibilidad y modificación del Servicio InBeat Web
        </b>
        <p>
          El Servicio InBeat Web está disponible las 24 horas del día y los 7
          días de la semana, dentro del límite de las Condiciones y, en
          concreto, para los territorios donde ECUSOLID mantenga un acuerdo
          comercial con las operadoras de de telecomunicaciones con el Servicio
          InBeat Web.
        </p>
        <p>
          ECUSOLID se reserva el derecho a modificar o mejorar el Servicio
          InBeat Web según estime oportuno. No obstante, INBEAT garantiza que
          esto no afectará a la calidad ni alterará sustancialmente las
          funciones del Servicio InBeat Web.
        </p>
        <p>
          Además, ECUSOLID tendrá derecho a retirar temporalmente el acceso al
          Servicio InBeat Web, sin previo aviso ni compensación, cuando resulte
          necesario para realizar tareas de mantenimiento o para garantizar su
          continuidad. El Suscriptor reconoce que ECUSOLID no puede ser hecha
          responsable y renuncia por tanto a cualquier derecho de compensación
          y/o acción contra ECUSOLID a este respecto. Las interrupciones
          temporales del Servicio InBeat Web serán notificadas en el Sitio con
          una antelación de al menos 24 (veinticuatro) horas, a no ser que sean
          urgentes. ECUSOLID también tiene derecho a cerrar el Servicio InBeat
          Web permanentemente sin deber ninguna indemnización. De producirse el
          cierre definitivo del Servicio InBeat Web, este se notificará a través
          del Sitio con al menos 1 (un) mes de antelación. A partir de la fecha
          del cierre del Servicio InBeat Web, el Suscriptor dejará de estar
          sujeto a la obligación de pago.
        </p>
        <b>
          Artículo 5 – Ausencia de derecho de cancelación – periodo de vigencia
          – renovación – cancelación – mejora al Servicio InBeat Premium
        </b>
        <p>5.1 Ausencia del derecho de cancelación (período de anulación)</p>
        <p>
          El Suscriptor reconoce y acepta expresamente que la prestación del
          Servicio InBeat Web comenzará en el momento en el que se confirme la
          suscripción y que tras esto perderá su derecho a cancelarla para el
          período en que se ha suscrito. En consecuencia, no se aceptarán
          solicitudes de anulación, cancelación o reembolso tras dicha
          confirmación de suscripción para dicho período.
        </p>
        <p>5.2 Periodo</p>
        <p>
          INBEAT ofrece una suscripción diaria (o por cualquier otro período
          dependiendo de las ofertas realizadas en el Sitio). En el Sitio se
          podrán ofrecer períodos de prueba del Servicio InBeat Web de diferente
          duración, de forma temporal o no. Salvo que se indique lo contrario,
          estos períodos de prueba estarán sujetos a las presentes Condiciones y
          se limitarán a una suscripción (misma dirección IP [Protocolo de
          Internet] y/o misma dirección de correo electrónico),
          independientemente de la oferta de prueba de que se trate.
        </p>
        <p>5.3 Renovación</p>
        <p>
          A menos que el Suscriptor cancele su suscripción, la suscripción al
          Servicio se renovará automáticamente durante un período equivalente al
          inicial. Por tanto, si el Suscriptor elige una suscripción diaria,
          esta se renovará automáticamente cada día.
        </p>
        <p>
          Excepto que se indique algo distinto en el Sitio o en las Condiciones,
          y salvo cancelación por parte del Suscriptor con arreglo a lo
          dispuesto en el Artículo 5.4, cualquier oferta de prueba pasará a ser
          automáticamente una suscripción diaria al precio indicado en la oferta
          o, en su defecto, al aplicable a la suscripción diaria del Servicio
          InBeat Web.
        </p>
        <p>5.4 Cancelación</p>
        <p>
          Si el Suscriptor desea cancelar la suscripción, deberá enviar un SMS
          al número corto 5512 con la palabra “SALIR”. La cancelación se hará
          efectiva al final del periodo de suscripción correspondiente.
        </p>
        <b>Artículo 6 – Condiciones de acceso al Servicio InBeat Web</b>
        <p>
          El Suscriptor declara estar en facultades de aceptar estas
          Condiciones, lo que significa que es mayor de la edad legal requerida
          y que no se encuentra sujeto a ninguna medida de protección legal
          (como una tutela legal).
        </p>
        <p>
          El Suscriptor declara residir en un país donde se ofrece el Servicio
          InBeat Web y ser titular de la línea telefónica con la cual ha
          realizado la suscripción o en su defecto cuenta con la autorización
          del titular de la línea para hacerlo.
        </p>
        <b>Artículo 7 – Creación de una cuenta</b>
        <p>Para suscribirse al Servicio InBeat Web, todo Suscriptor deberá:</p>
        <ul>
          <li>enviar un SMS al 5511 con la palabra “SI”, “OK” o “MUSICA”</li>
          <li>
            pagar el precio de su suscripción mediante los métodos de pago
            disponibles
          </li>
          <li>
            crearse una cuenta en el Sitio o, si el Suscriptor ya está
            registrado, iniciar sesión en él;
          </li>
          <li>
            proporcionar la información requerida en el formulario de registro;
          </li>
          <li>dar su consentimiento a las Condiciones;</li>
          <li>confirmar su registro</li>
        </ul>
        <p>
          El Suscriptor acuerda facilitar información veraz, exacta y auténtica
          sobre su persona en relación con su registro en el Sitio y el Servicio
          InBeat Web.
        </p>
        <p>
          Una vez aprobada la suscripción, INBEAT enviará al Suscriptor un SMS
          (mensaje de texto) de confirmación al número telefónico con el que se
          haya registrado la suscripción.
        </p>
        <p>
          Con arreglo a las demás cláusulas de las Condiciones, la suscripción
          al Servicio InBeat Web entrará en vigor únicamente una vez que INBEAT
          envíe el mensaje de confirmación anteriormente indicado. INBEAT
          recomienda al Suscriptor guardar este mensaje de texto.
        </p>
        <p>
          El Suscriptor acuerda informar a INBEAT sin demora sobre cualquier
          cambio o actualización en la información proporcionada en el momento
          de suscribirse al Servicio InBeat Web.
        </p>
        <p>
          El Suscriptor deberá informar inmediatamente a INBEAT de cualquier
          pérdida o uso no autorizado de su cuenta, sus datos de conexión o su
          contraseña. Las contraseñas y los datos de conexión son personales y
          el Suscriptor acuerda no divulgarlos. Por consiguiente, el Suscriptor
          es el único responsable de su uso.
        </p>
        <p>
          El Suscriptor será el único responsable de las consecuencias del uso
          indebido de su cuenta, tanto por su parte como por parte de terceros
          que hayan accedido a ella fruto de su propio error o negligencia. Por
          tanto, el Suscriptor reconoce que no podrá considerarse responsables a
          tal efecto a ECUSOLID ni a ninguno de sus socios, parte(s)
          contratante(s) o titulares de derechos.
        </p>
        <b>Artículo 8 – Precio</b>
        <p>El precio de suscripción está indicado en el Sitio.</p>
        <p>
          ECUSOLID se reserva el derecho a modificar el precio de todas las
          ofertas de suscripción de manera ocasional. ECUSOLID informará a cada
          Suscriptor enviando un SMS o correo electrónico a la dirección que
          éste haya facilitado al suscribirse al Servicio InBeat Web (o más
          tarde al actualizar su cuenta) sobre cualquier aumento de precio al
          menos 15 (quince) días antes de que se haga efectivo. En caso de que
          el Suscriptor no acepte el incremento, podrá cancelar su suscripción
          con arreglo al Artículo 5.4 anterior: dicha cancelación se hará
          efectiva en la siguiente fecha de vencimiento de la suscripción
          diaria. Si no cancela la suscripción, se le aplicará el nuevo precio a
          partir de la siguiente fecha de vencimiento de la suscripción diaria.
        </p>
        <p>
          De producirse algún incremento del tipo de IVA (impuesto sobre el
          valor añadido), este se aplicará automática e inmediatamente al precio
          del Servicio InBeat Web. Lo mismo se aplicará en caso de que se
          establezca un nuevo impuesto sobre el precio del Servicio InBeat Web y
          que se puedan aplicar a ECUSOLID. Las condiciones de cancelación
          aplicables en caso de que se realicen cambios en el precio del
          Servicio InBeat Web mencionadas más arriba serán aplicables de la
          misma manera en caso de que se introduzca un cambio de precio que
          resulte del incremento o la creación de nuevos impuestos.
        </p>
        <p>
          Se recuerda a los Suscriptores que los costes de conexión y
          comunicación (Internet) relacionados con el uso del Servicio InBeat
          Web no están a cargo de ECUSOLID y correrán únicamente por cuenta del
          Suscriptor.
        </p>
        <b>Artículo 9 – Pago de la tarifa</b>
        <p>
          En función del periodo de suscripción elegido por el Suscriptor, el
          importe de la suscripción deberá abonarse diaria, semanal o
          mensualmente. El primer pago se efectuará al realizar la suscripción
          y, posteriormente, al principio de cada día o semana o mes, mediante
          el saldo disponible en su línea telefónica hasta la cancelación de la
          suscripción, tanto si ésta se inicia a instancias del Suscriptor como
          de INBEAT.
        </p>
        <p>
          A menos que el Suscriptor pueda demostrar lo contrario, los registros
          digitalizados custodiados en los sistemas informáticos de ECUSOLID o
          de sus socios protegidos con medidas de seguridad razonables serán
          considerados como prueba de las comunicaciones, los pedidos, las
          validaciones y los pagos realizados entre el Suscriptor e ECUSOLID.
          Esta información deberá considerarse una prueba válida entre el
          Suscriptor y ECUSOLID, a menos que el primero pueda aportar pruebas
          por escrito de lo contrario.
        </p>
        <b>Artículo 10 – Responsabilidad del Suscriptor</b>
        <p>
          Para evitar ser declarado responsable, el Suscriptor acepta
          expresamente asegurarse de que todos los mensajes que publique en el
          Sitio o en la Aplicación de escritorio cumplan con los siguientes
          requisitos (la lista no es exhaustiva):
        </p>
        <p>
          - no infringir los derechos de propiedad intelectual de terceros; por
          consiguiente, el Suscriptor se compromete a no publicar en el Sitio
          contenidos protegidos por leyes de derechos de autor, marcas
          registradas o, más generalmente, cualquier contenido protegido por
          cualquier otro derecho de propiedad intelectual de terceros sin el
          consentimiento previo del titular o titulares de dichos derechos;
        </p>
        <p>
          - no contener ningún virus informático que pueda interrumpir, destruir
          o afectar a las funcionalidades del Sitio;
        </p>
        <p>
          - no hacer apología del delito ni de conductas delictivas, ni contener
          mensajes que constituyan ilegalidad o amenaza ni contenidos de
          naturaleza pedófila, pornográfica, difamatoria, obscena, que haga
          apología del odio, racista, antisemita, xenófoba o revisionista, o que
          sean contrarios al orden público y a las buenas costumbres;
        </p>
        <p>
          - no infringir el derecho a la intimidad ni atentar contra la dignidad
          humana;
        </p>
        <p>
          - no fomentar la violencia, el fanatismo, el delito, el suicidio o el
          odio por motivos de credo religioso, raza, sexo, orientación sexual u
          origen étnico;
        </p>
        <p>- no suponer acoso hacia otros Usuarios;</p>
        <p>
          - no promover ni fomentar ningún tipo de actividad u organización
          delictiva;
        </p>
        <p>
          - no solicitar ni revelar contraseñas y/o información personal con
          fines ilegales o comerciales;
        </p>
        <p>
          - no transmitir cadenas de correo electrónico, correos electrónicos
          masivos no solicitados ni mensajes instantáneos, publicitarios ni de
          correo basura;
        </p>
        <p>
          - no contener anuncios ni actos de captación directa con el objetivo
          de vender productos y/o servicios a través del Sitio;
        </p>
        <p>
          - no contener direcciones ni enlaces de Internet a sitios web externos
          cuyo contenido infrinja cualquier ley o normativa aplicable, los
          derechos de terceros o las presentes Condiciones.
        </p>
        <p>
          ii) El Suscriptor se compromete a no utilizar ningún sistema
          automatizado, como scripts, con el propósito de añadir a otros
          Usuarios a su cuenta de Usuario y/o enviar comentarios o mensajes.
        </p>
        <p>
          iii) Si un Suscriptor vulnera alguna ley o los derechos de terceros,
          ECUSOLID tendrá derecho a ofrecer cualquier información que permita o
          facilite la identificación del infractor a petición de cualquier
          autoridad legal (tribunales, autoridades administrativas y fuerzas
          policiales).
        </p>
        <b>Artículo 11 – Descargo de la responsabilidad de garantías</b>
        <p>
          El Suscriptor declara estar informado del alcance y las limitaciones
          de la red Internet.
        </p>
        <p>
          Por tanto, ECUSOLID declina toda responsabilidad por posibles errores
          en el acceso al Servicio InBeat Web, la velocidad de descarga,
          apertura y lectura de las páginas del Sitio, la velocidad de escucha
          de las pistas, la inaccesibilidad temporal o permanente del Servicio
          InBeat Web y el uso fraudulento por parte de terceros de la
          información ofrecida en el Sitio.
        </p>
        <p>
          En consecuencia, corresponderá al Suscriptor proteger sus equipos ante
          cualquier forma de intrusión y/o contaminación por virus, de los
          cuales ECUSOLID nunca podrá ser considerada responsable. ECUSOLID
          tampoco se hará responsable de las disfunciones o daños causados a los
          equipos del Suscriptor.
        </p>
        <p>
          Más generalmente, ECUSOLID rechaza cualquier responsabilidad por el
          incumplimiento de cualquier de obligación por causas naturales,
          fuerzas o causas que escapen a su control dentro de lo razonable, lo
          que incluye, sin carácter exhaustivo, errores de Internet, de equipos
          informáticos o de telecomunicaciones, de otros equipos o del
          suministro eléctrico, huelgas, conflictos laborales, revueltas,
          insurrecciones, disturbios civiles, escasez de mano de obra o de
          materiales, incendios, inundaciones, tormentas, explosiones, causas de
          fuerza mayor, guerras, medidas gubernamentales, órdenes de tribunales
          nacionales o extranjeros, inacción de terceros o fluctuaciones en la
          calefacción, el alumbrado o el aire acondicionado, y más generalmente
          cualquier suceso inevitable e impredecible que pueda impedir su buen
          desempeño.
        </p>
        <p>
          ECUSOLID rechaza toda responsabilidad en caso de que el Servicio
          InBeat Web resulte ser incompatible con ciertos equipos y/o funciones
          del equipo del Suscriptor.
        </p>
        <p>
          Por último, el Suscriptor será el único responsable del uso que
          realice del Servicio InBeat Web, y no podrá hacer responsable a
          ECUSOLID de ninguna reclamación y/o procedimiento en su contra a este
          respecto. El Suscriptor se hará cargo de cualquier reclamación, queja
          u objeción, y más generalmente de cualquier procedimiento iniciado
          contra ECUSOLID por terceros en relación con el uso que realiza el
          Suscriptor del Servicio InBeat Web.
        </p>
        <b>Artículo 12 – Propiedad intelectual</b>
        <p>
          La estructura general del Sitio, el Servicio InBeat Web y todos los
          elementos que lo componen (lo que incluye, sin carácter exhaustivo,
          los logotipos, nombres de dominio, pistas o vídeos y sus elementos
          asociados, como fotografías, imágenes, textos y biografías de autores,
          artistas y/o cualquier beneficiario legal de las pistas o los vídeos,
          además de los elementos visuales de los embalajes) son propiedad
          exclusiva de INBEAT y/o sus licenciantes (en especial los productores
          de discos y vídeos, las compañías discográficas, las sociedades de
          autores, etc.).
        </p>
        <p>
          Estos elementos están protegidos por las leyes de propiedad
          intelectual y otras leyes, particularmente las relativas a los
          derechos de autor. El Suscriptor solo podrá utilizar estos elementos
          con el fin de utilizar el Servicio InBeat Web, de acuerdo con las
          presentes Condiciones.
        </p>
        <p>
          Queda prohibida cualquier representación total o parcial del Sitio,
          del Servicio InBeat Web y/o de los elementos que los componen (tal
          como se ha descrito anteriormente) por cualquier medio sin el
          consentimiento expreso de ECUSOLID, y se considerará una infracción
          legalmente punible.
        </p>
        <p>
          Queda terminantemente prohibido el uso de cualquier hipervínculo que
          enlace al Sitio y el uso de &quot;framing&quot; (técnica de
          programación que permite dividir las ventanas del navegador en varios
          marcos para mostrar el contenido de un sitio externo) o &quot;in-line
          linking&quot; (técnica de programación que hace desaparecer en una
          página un elemento extraído de otro sitio web, lo que ahorra espacio
          de almacenamiento en el disco duro donde se encuentra alojado el Sitio
          y permite ocultar al usuario poco experimentado el contenido inicial
          del que forma parte el elemento). Queda terminantemente prohibido
          cualquier uso indebido de una cuenta de INBEAT (como intentar aumentar
          de forma artificial o anormal el número de reproducciones de canciones
          concretas) mediante cualquier tipo de proceso automatizado, como los
          bots y scripts, o a través de otros medios.
        </p>
        <p>
          Los Registros ofrecidos a través del Servicio InBeat Web son archivos
          digitales protegidos por la legislación nacional e internacional en
          materia de derechos de autor y afines. Por tanto, solo podrán
          escucharse en un contexto privado o familiar. Cualquier uso no privado
          hará que el Suscriptor esté sujeto a acciones civiles y/o penales.
          Queda terminantemente prohibido cualquier otro uso de los Registros, y
          en particular la descarga o el intento de descarga, su transferencia o
          intento de transferencia permanente o temporal al disco duro de un
          ordenador o cualquier otro dispositivo (en especial reproductores de
          música), cualquier copia o grabación, o intento de copia o grabación,
          de cualquier archivo o archivos en un CD o cualquier otro soporte. La
          reventa, el intercambio o el alquiler de dichos archivos quedan
          terminantemente prohibidos.
        </p>
        <p>
          El Suscriptor reconoce que los Registros ofrecidos en el Sitio están
          protegidos por medidas técnicas de protección adoptadas por INBEAT con
          el fin de impedir o limitar, dependiendo de la tecnología ofrecida, el
          uso no autorizado de los mismos.
        </p>
        <p>
          El Suscriptor acepta no eludir estas medidas técnicas de protección
          por ningún medio con el propósito de descargar estos archivos y de
          permitir su conservación en su terminal de recepción, sea cual sea
          este (ordenador, teléfono móvil, reproductor de música u otro
          reproductor de discos de música portátil, etc.).
        </p>
        <b>
          Artículo 13 – Suspensión y/o cancelación iniciada por INBEAT o por el
          Suscriptor
        </b>
        <p>
          Sin perjuicio de cualesquiera daños que ECUSOLID pueda reclamar,
          ECUSOLID tendrá derecho a suspender el acceso al Servicio InBeat Web a
          un Suscriptor y/o cancelar su suscripción al Servicio InBeat Web sin
          previo aviso ni compensación, en caso de que este:
        </p>
        <p>- No cumpla con las Condiciones y en especial:</p>
        <ul>
          <li>
            incumpla los derechos de propiedad intelectual de INBEAT o de sus
            licenciantes;
          </li>
          <li>
            eluda o intente eludir las medidas técnicas de protección adoptadas
            por INBEAT;
          </li>
          <li>
            utilice o intente utilizar conexiones simultáneas a una misma
            cuenta;
          </li>
          <li>
            proporcione información falsa al suscribirse al Servicio InBeat Web
            o con posterioridad.
          </li>
        </ul>
        <p>
          - No pague el precio de la suscripción en su totalidad o en parte.
        </p>
        <p>
          - Lleve a cabo actividades perjudiciales para los intereses
          comerciales de INBEAT.
        </p>
        <p>
          El Suscriptor podrá cancelar sin notificación o compensación a INBEAT
          su contrato o suscripción si INBEAT incumpliera sus obligaciones
          sustanciales tal como se definen en estas Condiciones o por una causa
          legítima como, en particular, el robo de su ordenador o una
          incompatibilidad técnica del Servicio InBeat Web con el equipo del
          Suscriptor.
        </p>
        <b>Artículo 14 – Modificación de las Condiciones</b>
        <p>
          INBEAT tendrá derecho a modificar las Condiciones a su sola
          discreción. INBEAT informará al Suscriptor por correo electrónico,
          escribiendo a la dirección de correo electrónico que haya indicado en
          su cuenta de Suscriptor, sobre cualquier modificación de las
          Condiciones con al menos 1 (un) mes de antelación a su entrada en
          vigor. Si el Suscriptor no aceptase dichas modificaciones, podrá
          cancelar la suscripción de su cuenta en el Sitio, según establece el
          Artículo 5.4 más arriba. La cancelación se hará efectiva en la
          siguiente fecha de vencimiento de la suscripción. A menos que el
          Suscriptor elimine su suscripción, las Condiciones modificadas
          entrarán en vigor un 1 (un) mes después de que se reciba la
          notificación de dicha modificación de las Condiciones.
        </p>
        <b>Artículo 15 – Atención al cliente</b>
        <p>
          Como parte de su acceso y uso del Servicio InBeat Web, INBEAT ofrece
          también al Suscriptor un servicio de atención al cliente que está a su
          disposición para responder a las dudas que pueda tener sobre su cuenta
          y sobre los Servicios que ofrece INBEAT (&quot;Atención al
          cliente&quot;).
        </p>
        <p>
          El Suscriptor podrá solicitar información sobre (la lista no es
          exhaustiva):
        </p>
        <p>- su acceso y uso de los Servicios ofrecidos por INBEAT;</p>
        <p>- su cuenta y la modificación de los datos de su cuenta;</p>
        <p>- sus suscripciones y pagos.</p>
        <p>
          El Suscriptor puede ponerse en contacto con INBEAT a través del Sitio
          haciendo clic en la sección &quot;Contáctanos&quot;.
        </p>
        <p>
          Atención al cliente hará todo lo posible por responder a dichas
          solicitudes dentro de un plazo razonable, si bien no puede ofrecer
          garantías de ningún tipo de que estas sean atendidas en un plazo que
          se ajuste a las expectativas del Suscriptor o de que este quede
          totalmente satisfecho con la respuesta.
        </p>
        <b>Artículo 16 – Invalidez de una cláusula</b>
        <p>
          En caso de que alguna de las cláusulas de las presentes Condiciones
          fuera declarada nula o anulable por cualquier razón, el resto seguirán
          siendo de aplicación sin modificación alguna.
        </p>
        <b>Artículo 17 – Jurisdicción – Reclamaciones y disputas</b>
        <p>
          Estas Condiciones se rigen por la legislación del país en el que el
          Suscriptor tiene su residencia habitual.
        </p>
        <p>
          Cualquier reclamación relativa a la aplicación de las presentes
          Condiciones de uso deberá presentarse enviando un correo electrónico a
          Atención al cliente a comercial@ecusolid.com para que podamos
          tramitarla lo antes posible.
        </p>
        <p>
          En caso de litigio, las partes tratarán de llegar a una solución
          amistosa antes de iniciar un procedimiento judicial. Si no se llega a
          ninguna solución amistosa, cualquier reclamación relativa a la
          validez, interpretación y/o ejecución de las Condiciones deberá
          llevarse a los tribunales del país de residencia habitual del
          Suscriptor.
        </p>
        <h3>Promoción mi música con la selección</h3>
        <h4><b><u>BASES, CONDICIONES Y RESTRICCIONES</u></b></h4>
        <b>1.            COMPARECIENTES, OBJETO, TERRITORIO Y VIGENCIA</b>
        <p>El presente instrumento establece las disposiciones que regularán las actividades de 
          ECUSOLID S.A. (en adelante <b>ORGANIZADOR</b> ) con relación al lanzamiento del ENTRETENIMIENTO 
          “MI MÚSICA CON LA SELECCIÓN” (en adelante el <b>ENTRETENIMIENTO</b>) dirigido a los usuarios de la
           CORPORACIÓN NACIONAL DE TELECOMUNICACIONES, CNT EP. (en adelante <b>“CNT”</b>) exclusivamente.
        </p>
        <p>
        El ORGANIZADOR en función de la legalidad y transparencia del ENTRETENIMIENTO informa lo siguiente:
        </p>
        <p>
        El ENTRETENIMIENTO, tendrá vigencia en todo el territorio de la República del Ecuador, desde 
        el 4 de noviembre 2020 hasta el 20 de noviembre 2020, entre las 00:01 horas del día en que inicia 
        el ENTRETENIMIENTO, hasta las 23:59 horas, del día en que termina, sujeto al cumplimiento de 
        las presentes Bases y Condiciones (en adelante las BASES). 
        </p>
        <b>2.            CONDICIONES</b>
        <p>
        <b>2.1)</b> La participación en el ENTRETENIMIENTO implica el íntegro conocimiento y aceptación de las 
        presentes BASES, así como de las decisiones que, conforme a derecho, adopte el ORGANIZADOR sobre 
        cualquier cuestión no prevista en las mismas. El ORGANIZADOR tendrá el derecho a definir, establecer
         e interpretar aquellas situaciones o circunstancias que no estén expresamente previstas en las presentes 
         BASES, sin afectar la esencia del presente evento. Las antedichas decisiones serán inapelables y respecto 
         de las cuales los USUARIOS no tendrán derecho a reclamo alguno, aclarándose que cualquier modificación será 
         ajustada a la legislación vigente.
        </p>
        <p>
        <b>2.2)</b> Podrá participar del ENTRETENIMIENTO cualquier persona residente en todo el territorio nacional de la 
        República del Ecuador, que sea cliente móvil de la CNT, siempre que sean líneas activas prepago o pospago de 
        cuentas individuales, tengan activo el componente de SMS Premium, que no registren ninguna eventualidad a efectos 
        de robo, fraude o suspensión de ninguna índole y que cumpla con las condiciones especificadas en las presentes BASES.
        </p>
        <p>
        <b>2.3)</b> Serán los GANADORES, los USUARIOS que cumplan con TODOS los requisitos establecidos para acreditarse con este 
        título de acuerdo a lo especificado en las presentes BASES.
        </p>
        <p>
        <b>2.4)</b> Las presentes BASES podrán ser modificadas sin previo aviso a criterio conforme a derecho por el ORGANIZADOR y 
        publicadas a través de los medios previstos y aquí establecidos para las BASES.
        </p>
        <p>
        <b>2.5)</b> El ORGANIZADOR se reserva el derecho exclusivo de suspender definitiva o transitoriamente el ENTRETENIMIENTO, 
        como así también introducir las modificaciones que juzgue convenientes en cuanto a las condiciones y características 
        del mismo, en caso de configurarse el supuesto de caso fortuito o de fuerza mayor, sin que ello genere derecho alguno 
        o reclamo por parte de los USUARIOS.
        </p>
        <p>
        <b>2.6)</b> El servicio de suscripción premium asociado al ENTRETENIMIENTO a través del cual podrán participar los usuarios es 
        Club InBeat, cuya tarifa de suscripción es de TREINTA CENTAVOS DE DOLAR DE LOS ESTADOS UNIDOS DE AMERICA (USD$ 0.30) más 
        impuestos con cobro diario y automático.
        </p>
        <p>
        <b>2.7)</b> El ORGANIZADOR no será responsable por daños o perjuicios que pudiere sufrir el (los) GANADOR (ES) o los terceros, 
        en sus personas o bienes, con motivo y/u ocasión de la participación del ENTRETENIMIENTO y/o del uso de los premios, 
        declinando todo tipo de responsabilidad contractual y/o extracontractual frente a los USUARIOS, GANADORES y/o acompañantes 
        y/o sucesores, lo que es aceptado incondicionalmente por los USUARIOS. De igual manera, el ORGANIZADOR no asume responsabilidad 
        alguna por fallas técnicas y/o humanas que por cualquier motivo imposibiliten el desarrollo del ENTRETENIMIENTO.
        </p>
        <p>
        <b>2.8)</b> El USUARIO renuncia expresamente a reclamar al ORGANIZADOR por cualquier efecto o consecuencia que su participación 
        en el ENTRETENIMIENTO pueda acarrear, como beneficiario o no del premio y libera de toda responsabilidad ante terceros por 
        el uso o destino que dé al premio. Además, no exigirá garantías o presentará reclamo o acción alguna en contra del ORGANIZADOR 
        relacionada con la calidad del premio recibido, ya que el ORGANIZADOR no es el fabricante, proveedor o distribuidor del premio 
        que se ofrece.
        </p>
        <b>3. RESTRICCIONES</b>
        <p><b>3.1)</b> Se exceptúan de la participación aquellas líneas cuya facturación esté a nombre de la CNT, o de los empleados directos de 
          dicha empresa, así como también los planes no comerciales o de Telefonía Pública (Locutorios).
          </p>
        <p><b>3.2)</b> De la misma manera, no podrán participar planes familiares de la CNT, ni aquellas personas que son familiares hasta el
           segundo grado de consanguinidad o segundo grado de afinidad de empleados de la CNT, es decir, Padres, Hijos, Cónyuge, Suegros,
            Yernos, Nueras, Cuñados; menos aún familiares en los antedichos grados de las personas que se encuentran directa o 
            indirectamente involucrados con el ENTRETENIMIENTO.
            </p>
        <p><b>3.3)</b> Adicionalmente se excluye de la posibilidad de participación a los empleados directos del ORGANIZADOR en las mismas 
          condiciones que para los empleados directos de la CNT, es decir, parientes hasta el segundo grado de consanguinidad y
           segundo grado de afinidad.
           </p>
        <p><b>3.4)</b> Es responsabilidad del portador de estas líneas, el participar o no en el ENTRETENIMIENTO. En caso de que la persona 
          usuaria de dicha línea decida participar en el ENTRETENIMIENTO a pesar de estar excluida, será de su entera responsabilidad 
          y el ORGANIZADOR no realizará ajustes ni devoluciones por los montos gastados por dicha participación. Así mismo, no será 
          considerada como participante para efectos de hacerse acreedora a cualquiera de los premios en cualquier modalidad.</p>
        <p><b>3.5)</b> No podrán participar aquellas personas que no residan en el país y aquellas personas que no gocen de los derechos 
          de ciudadanía, temporal o definitivamente. Tampoco podrán participar las personas que registren datos falsos en cualquiera 
          de las plataformas del ENTRETENIMIENTO.
          </p>
        <b>4.            PREMIO</b>
        <p>
        <b>4.1)</b> Los USUARIOS que sean acreditados como GANADORES del ENTRETENIMIENTO de acuerdo a todo lo estipulado en las presentes BASES
         obtendrán como premio:  
        </p>
        <DataTable
                  columns={Columns}
                  data={data}
                  noHeader={true}
                  theme="solarized"
                  noDataComponent={<div></div>}
                />
        <p>
        En total serán 4 GANADORES beneficiarios del premio del ENTRETENIMIENTO.
        </p>
        <p>
        El premio será entregado directamente por el ORGANIZADOR y de ninguna manera se reembolsarán gastos adicionales hechos por el
         USUARIO ganador.
        </p>
        <p>
        El(los) GANADOR(ES) del premio aceptan expresamente que la responsabilidad del ORGANIZADOR se limita únicamente a la entrega 
        del premio, quedando exonerados de toda responsabilidad, culpa, reclamo, demanda y acción ante cualquier instancia administrativa
         y/o judicial por los efectos que directa o indirectamente puedan derivarse del ENTRETENIMIENTO, ante el uso, aceptación y disfrute
          del mismo.  
        </p>
        <p>
        EL ORGANIZADOR no se hará responsable de gastos adicionales en que incurra el ganador para el disfrute del premio en condiciones
         diferentes a las estipuladas en las BASES del ENTRETENIMIENTO.  
        </p>
        <p>
        El USUARIO ganador no podrá acumular, negociar, intercambiar ni canjear el premio. Tampoco podrán exigir la devolución, mejora
         y/o reemplazo del premio, u otro beneficio que así pondere el ganador ante el ORGANIZADOR.  
        </p>
        <p>
        <b>4.2)</b> En caso de Fuerza Mayor o Caso Fortuito el premio podrá ser reemplazado por el ORGANIZADOR por otro de similares 
        características, lo cual será informado oportunamente al GANADOR, quien al participar en el ENTRETENIMIENTO lo acepta 
        expresamente. El ORGANIZADOR se reserva el derecho a cambiar las fechas establecidas para los sorteos en caso de fuerza
         mayor o caso fortuito u otras razones que le impidan hacer efectivos los premios, en las condiciones iniciales indicadas.
        </p>
        <p>
        <b>4.3)</b> La adquisición y la entrega del premio es responsabilidad del ORGANIZADOR.  
        </p>
        <b>5.            LA MECANICA</b>
        <p>
        <b>5.1)</b> La contratación del SERVICIO podrá realizarse a través de Broadcast u otros medios publicitarios en donde se invite a los 
        usuarios a participar de la promoción enviando un SMS a la marcación corta 5511 (Club InBeat) con la palabra clave correspondiente 
        que podrán ser de manera enunciativa, más no limitativa SI, OK, PROMO y sus alias o cualquier otra palabra similar indicada en el 
        mensaje que contenga el ENTRETENIMIENTO y que invite a suscribirse al SERVICIO. 
        </p>
        <p>
        <b>5.2)</b> Una vez que el usuario acepta la suscripción al SERVICIO de suscripción participante, éstos generarán automáticamente al
         USUARIO un cargo de TREINTA CENTAVOS DE DOLAR DE LOS ESTADOS UNIDOS DE AMERICA (USD$ 0.30) más impuestos desde el momento de 
         su suscripción hasta el último día en que se encuentre suscrito. Los usuarios suscritos en el servicio de contenidos para 
         celulares denominado InBeat, podrán acceder diariamente a Contenidos Multimedia “PREMIUM” para sus terminales móviles, tales 
         como: Música, Noticias y Vídeos de cantantes emergentes (nuevos) de Ecuador.
        </p>
        <p>
        <b>5.3)</b> El usuario suscrito al SERVICIO participante, con cada cobro efectivo recibirá 1 día de acceso con lo cual podrá ingresar 
        al portal del club para hacer uso del contenido que desee.
        </p>
        <p>
        <b>5.4)</b> El SERVICIO es prestado mediante la modalidad de suscripción, es decir en forma continua e indefinida desde la activación 
        del SERVICIO por parte del Usuario y hasta el momento en que este último solicite la desactivación o cancelación o se ponga fin a
         la provisión del Servicio por otras circunstancias.
        </p>
        <p>
        <b>5.5)</b> Es requisito indispensable para la utilización de los servicios ofertados por el SERVICIO participante, que el USUARIO 
        posea los servicios SMS e Internet Móvil activados, un teléfono móvil compatible y correctamente configurado. Los USUARIOS 
        deberán comprobar la disponibilidad de este servicio en forma previa a la solicitud de activación.
        </p>
        <p>
        <b>5.6)</b> Toda desactivación o cancelación a la suscripción del SERVICIO participante implicará la pérdida del acceso al Club InBeat, 
        sin derecho a compensación alguna ni reembolso total o parcial de lo abonado.
        </p>
        <p>
        <b>5.7)</b> El USUARIO podrá solicitar la cancelación de la suscripción en cualquier momento, mediante el envío de un SMS con la 
        palabra “SALIR” al código corto 5512. En caso de que el USUARIO cancele el SERVICIO antes de finalizar el ENTRETENIMIENTO, 
        dicho USUARIO no participará para obtener alguno de los premios descritos en las presentes BASES. 
        </p>
        <p>
        <b>5.8)</b> Tras la recepción de la solicitud de cancelación y de conformidad con la legislación aplicable, el USUARIO recibirá un
         SMS gratuito confirmando la baja solicitada. Desde el momento de la cancelación, el Usuario no tendrá derecho a continuar 
         utilizando el SERVICIO Club InBeat.  
        </p>
        <p>
        <b>5.9)</b> El ORGANIZADOR se reserva el derecho de auditar las transacciones generadas por el USUARIO con el fin de verificar que 
        las mismas fueron originadas desde un teléfono celular de forma manual y no a través de aplicativos especiales, máquinas, software 
        u otros el caso de los mensajes enviados. En este caso, de comprobarse que se han utilizado estos mecanismos, el ORGANIZADOR se
         reserva el derecho de descartar a este USUARIO y no adjudicarle el premio que correspondiera ni hacer devolución alguna por los
          valores gastados con ese número celular en el ENTRETENIMIENTO. 
        </p>
        <b>6.            DEFINICIÓN DEL GANADOR	</b>
        <p>
        <b>6.1)</b> Los ganadores serán seleccionados en base a un reporte de Altas en el cual contiene el número de cobros positivos y la fecha
         de alta/baja del USUARIO. Los ganadores serán los USUARIOS con mayor cantidad de cobros positivos, en caso de haber empate 
         considerando estos criterios, se utilizará la hora exacta de alta para determinar a los ganadores.
        </p>
        <p>
        Por cada premio se seleccionará UN ganador y DIEZ (10) GANADORES potenciales suplentes.
        </p>
        <p>
        La verificación de los potenciales ganadores del ENTRETENIMIENTO se efectuará en las oficinas del ORGANIZADOR, ubicadas en 
        la Av. República del Salvador N36-140 y Naciones Unidas o donde éste designe, de acuerdo con las circunstancias del caso,
         dentro de los siguientes 7 días hábiles después de las fechas de verificación de altas del ENTRETENIMIENTO.	
        </p>
        <p>
        Dentro de los siguientes 7 días hábiles, después de haberse definido quienes son los posibles ganadores, EL ORGANIZADOR o 
        quien éste designe para el efecto, procederá a intentar establecer una comunicación telefónica con el (los) potencial (es) 
        ganador (es) principal (es), llamando al número telefónico con el cual participó en el ENTRETENIMIENTO, comunicando el 
        resultado de su participación en el ENTRETENIMIENTO, indicando el día, hora y lugar en que deberá concurrir a recibir el 
        (los) premio (s), así como todos los requisitos que deberá cumplir para ser el (los) GANADOR (ES) oficial (es).
        </p>
        <p>
        En caso que la comunicación no pueda ser establecida, por cualquier razón que ello fuera, incluyendo, sin limitación, 
        que la línea se encuentre ocupada o que no se responda al llamado, habiendo intentado por un mínimo de tres (3) llamadas, 
        hasta un máximo de cinco (5) llamadas diarias, en un período de dos (2) días laborables consecutivos, el ORGANIZADOR 
        procederá a eliminar al titular y realizará la misma gestión con el primer ganador suplente y así sucesivamente hasta lograr
         establecer contacto y verificación con alguno de los designados como potenciales ganadores.
        </p>
        <p>
        En caso de que el (los) GANADOR(ES) caiga en mora o su terminal sea suspendido por la falta de pago de su línea, pese a 
        haber sido notificado como un (los) GANADOR (ES), inmediatamente el premio será entregado al suplente correspondiente de 
        acuerdo con las presentes BASES.
        </p>
        <p>
        Si al llegar a los 10 ganadores suplentes y ninguno cumple con los requisitos del ENTRETENIMIENTO y/o no contestan a la
         llamada telefónica del ORGANIZADOR según lo arriba indicado, se procederá a declarar desierto el ENTRETENIMIENTO y el 
         ORGANIZADOR podrá disponer del premio.
        </p>
        <p>
        Una vez seleccionado el ganador, el ganador tendrá 7 días hábiles para recoger el premio, en el lugar y hora establecidos 
        por el ORGANIZADOR, después de que este le haya notificado al (os) potencial (es) GANADOR (ES) y a su vez que este cumpla 
        con todos los requisitos establecidos en las presentes BASES para ser el (os) GANADOR (ES). La entrega será ante notario 
        público autorizado.
        </p>
        <p>
        En caso que el ganador no vaya a recoger el premio en la fecha establecida, el ORGANIZADOR podrá seleccionar a otro 
        ganador suplente.
        </p>
        <p>
        En caso que el premio no sea reclamado por el primer lugar o suplente, ECUSOLID se reserva el derecho de disponer 
        del premio a su conveniencia, sin responsabilidad alguna.
        </p>
        <p>
        El ganador será responsable de trasladarse (por sus propios medios) a los puntos de entrega seleccionados por el ORGANIZADOR. 
        Todo gasto, impuesto, tasa o contribución que deba pagarse por la entrega del premio, así como los gastos de traslado, 
        alimentación, hospedaje, etc. en que incurran para poder reclamar los premios, correrán por cuenta propia del GANADOR (ES).
        </p>
        <p>
        Una vez entregado el premio, el ORGANIZADOR quedará liberada de toda responsabilidad por el mismo.
        </p>
        <b>7.            REQUISITOS A CUMPLIR POR PARTE DEL GANADOR:</b>
        <p>
        <b>7.1)</b> Que de acuerdo con lo estipulado en las presentes BASES no tenga ningún impedimento para participar en el ENTRETENIMIENTO o 
        ser ganador del mismo.
        </p>
        <p>
        <b>7.2)</b> Que durante la vigencia del ENTRETENIMIENTO haya logrado suscribirse al SERVICIO y se mantenga activo hasta el último día 
        de vigencia de la promoción.  
        </p>
        <p>
        <b>7.3)</b> Un USUARIO no podrá ganar más de una vez en el ENTRETENIMIENTO. El USUARIO solo podrá participar para ganar uno de los 
        premios señalados en las presentes bases. Si el USUARIO resultó ganador en un premio, ya no podrá participar para ganar otro 
        premio. 
        </p>
        <p>
        <b>7.4)</b> Que el ORGANIZADOR haya podido notificar al USUARIO, su calidad de ganador dentro de los plazos establecidos en las 
        presentes bases.  
        </p>
        <p>
        <b>7.5)</b> Que se presente a recibir su premio en el lugar, día y hora especificadas por el ORGANIZADOR. 
        </p>
        <p>
        <b>7.6)</b> Que firme un acta que certifique la entrega del premio y su recibo a entera satisfacción. Sin el cumplimiento de lo anterior, 
        no se hará entrega del respectivo premio.  
        </p>
        <p>
        <b>7.7)</b> En el caso de que el usuario de la línea sea un menor de edad, el premio será entregado a su o sus padres, representante o 
        tutor legal. El menor deberá presentarse a reclamar el premio obligatoriamente con uno de sus padres, representante legal o tutor 
        legal. El o los padres, representante legal o tutor legal deberán presentar los documentos requeridos en la cláusula ocho del 
        presente instrumento, para poder reclamar el premio, así como los que sean necesarios para comprobar la representación de la 
        persona que comparece en nombre del menor de edad. Los padres, representantes o tutores legales son los responsables de la 
        participación del menor en el ENTRETENIMIENTO. En caso de no presentarse los documentos requeridos, el ganador no podrá reclamar 
        el premio.
        </p>
        <b>8.	DOCUMENTOS QUE DEBE ENTREGAR EL GANADOR AL ORGANIZADOR:</b>
        <p>
        <b>8.1)</b> Exhibir el original de su documento de identificación válido (Cédula de Identidad, Cédula de Ciudadanía o Pasaporte) y entregar 
        tres fotocopias a color de las mismas.
        </p>
        <p>
        <b>8.2)</b> Para menores de edad, se deberá presentar el documento de identificación (Cédula de Identidad, Cédula de Ciudadanía o 
        Pasaporte) del menor, así como del o de los padres, representante legal, o tutor legal y entregar tres fotocopias a color de las 
        mismas. Adicionalmente, deberán presentar la autorización habilitante al menor para su participación en el ENTRETENIMIENTO.
        </p>
        <b>9.          DERECHOS DE PROPIEDAD INTELECTUAL</b>
        <p>
        <b>9.1)</b> Todo derecho, título e interés en y hacia cualquier propiedad intelectual, Derechos de Propiedad u otros derechos relacionados 
        con la propiedad intangible que se usa (en lo sucesivo derechos de la Propiedad Intelectual) o práctica en conexión con cualquiera 
        de los Servicios son propiedad del ORGANIZADOR o de sus concedentes de licencia y el USUARIO acepta no realizar ninguna reclamación
         de interés o propiedad de éstos.
        </p>
        <p>
        <b>9.2)</b> El USUARIO reconoce que las marcas exhibidas son registradas, mismas que el ORGANIZADOR ha obtenido de sus titulares las 
        autorizaciones y contratos necesarios para el ENTRETENIMIENTO, explotación, licencia y transmisión de los derechos que le permiten 
        comercializar todos los Contenidos, por lo que el USUARIO acepta estas condiciones como una licencia personal, limitada, no
         transferible, no exclusiva y no asignable, el derecho para descargar y utilizar los Servicios en un dispositivo móvil compatible 
         designado exclusivamente para su uso personal y no comercial. El USUARIO consiente y acuerda no alquilar, arrendar, vender, 
         distribuir, crear ni generar los Servicios y/o los derechos de Propiedad Intelectual del ORGANIZADOR
        </p>
        <p>
        <b>9.3)</b> El contenido es propiedad del ORGANIZADOR y de sus proveedores certificados. El USUARIO podrá hacer uso del material y 
        contenido, única y exclusivamente bajo las condiciones expresamente autorizadas del SERVICIO y así mismo el USUARIO se compromete 
        a no copiar, reproducir, transmitir, distribuir los Contenidos ni a crear Servicios derivados de tales contenidos o información, 
        ni violentar las Leyes de Derechos de autor, Propiedad Intelectual e Industrial. El USUARIO se compromete a utilizar el SERVICIO 
        de conformidad con las Leyes y conforme a estos Términos y Condiciones, así como de forma correcta y diligente.
        </p>
        <b>10.          DIFUSION Y PROTECCIÓN DE DATOS</b>
        <p>
        <b>10.1)</b> El ENTRETENIMIENTO podrá ser difundido a través de la totalidad de medios que EL ORGANIZADOR estime conveniente como, por 
        ejemplo: Radio, Prensa, Revistas, Medios Gráficos, Internet, entre otros. De igual manera, el USUARIO autoriza al ORGANIZADOR a
         enviarle mensajes de texto del ENTRETENIMIENTO, así como también acepta recibir mensajes entrantes propios de la interacción del 
         ENTRETENIMIENTO en cualquier día y a cualquier hora durante la vigencia del mismo.
        </p>
        <p>
        <b>10.2)</b> Los datos e información personal recabada por el ORGANIZADOR con motivo del ENTRETENIMIENTO, únicamente serán utilizados
         para dar cumplimiento al ENTRETENIMIENTO y en su caso, entregar el premio al ganador, que cumpla con los requisitos aquí 
         establecidos, así como para publicitar al ganador a través de los medios de comunicación que el ORGANIZADOR decida.
        </p>
        <p>
        <b>10.3)</b> El USUARIO del ENTRETENIMIENTO acepta y autoriza al ORGANIZADOR a utilizar los datos obtenidos a través del desarrollo de 
        la suscripción, para la creación de una base de datos con el objetivo de enviar mensajes de texto a los propios usuarios 
        relacionados con el ENTRETENIMIENTO y únicamente durante la vigencia del ENTRETENIMIENTO, y hasta 1 año después o a fin de 
        informar posteriormente sobre un nuevo entretenimiento.
        </p>
        <p>
        <b>10.4)</b> El USUARIO garantiza que no existen terceros que tengan derechos de exclusividad sobre la exhibición, publicación, difusión, 
        reproducción y/o la puesta en el comercio de su imagen, voz y/o datos personales. En caso contrario, se comprometen a mantener 
        indemne al ORGANIZADOR y/o a la CNT EP respecto de cualquier reclamo que por este motivo pudieran formular terceras personas. 
        El USUARIO reconoce que la participación en el ENTRETENIMIENTO no les causa ningún perjuicio patrimonial involuntario, por tanto, 
        renuncian a cualquier reclamo que puedan efectuar por la disminución de sus ingresos. 
        </p>
        <p>
        <b>10.5)</b> Las presentes BASES, serán publicadas en la sección destinada a la misma en la página WEB &nbsp;  
        <a href={"https://inbeat.live/#/terms-conditions"}>https://inbeat.live/#/terms-conditions</a> 
        </p>
        <p>
        <b>10.6)</b> Las imágenes del premio en caso de corresponder a algún objeto lo son a modo ilustrativo y el premio a entregarse es el 
        precedentemente descrito. Las imágenes, marcas y logos exhibidos son propiedad de sus titulares. 
        </p>
        <p>
        <b>10.7)</b> Al utilizar el SERVICIO, el USUARIO acepta y autoriza que sus datos personales sean tratados, utilizados y comunicados 
        por el ORGANIZADOR, filiales, asociadas, afiliadas, agentes y subsidiarias para la elaboración de una base de datos, la cual 
        será siempre propiedad del ORGANIZADOR, así como para las demás finalidades y bajo las condiciones definidas por éstas. 
        </p>
        <p>
        <b>10.8)</b> El GANADOR autoriza al ORGANIZADOR a difundir sus datos personales e imágenes en caso de ser requeridos, únicamente para 
        fines publicitarios, en los medios y formas que dichas empresas dispongan, sin derecho a compensación alguna, durante la 
        vigencia del ENTRETENIMIENTO y hasta los tres (3) años de su finalización.
        </p>
        <b>11. 	INTERPRETACIÓN</b>
        <p>
        En caso de duda sobre los alcances o aplicación de estas BASES, corresponderá exclusivamente al ORGANIZADOR determinar 
        su interpretación.  
        </p>
        <b>12. 	CONTROVERSIAS </b>
        <p>
        <b>12.1)</b> Toda relación que en virtud de éste ENTRETENIMIENTO que se genere entre los USUARIOS y el ORGANIZADOR, será 
        regida y concertada en total sujeción a las leyes del Ecuador.
        </p>
        <p>
        <b>12.2)</b> Cualquier controversia que pudiera presentarse en relación del ENTRENIMIENTO y que no pueda ser resuelta de mutuo 
        acuerdo, los USUARIOS y el ORGANIZADOR, de conformidad con la Ley de Arbitraje y Mediación, someten la resolución de dichas
         controversias al arbitraje administrado por un Centro de Arbitraje del domicilio de el ORGANIZADOR. De no haber un centro
          de arbitraje, se presentará la demanda ante un Centro de Arbitraje de Cuenca, Guayaquil o Quito a elección del demandante,
           de acuerdo con la Ley de Arbitraje y Mediación y el Reglamento del Centro de Arbitraje, y las siguientes normas:
        </p>
        <ul>
          <li>
          <b>a)</b> El Tribunal Arbitral estará conformado por un árbitro, designado a través del sistema de 
          selección previsto en el Reglamento del Centro de Arbitraje y Mediación. 
          </li>
          <li>
          <b>b)</b> El procedimiento será confidencial.
          </li>
          <li>
          <b>c)</b> El tribunal fallará en derecho.
          </li>
          <li>
            <p>
            <b>d)</b> Para la ejecución de medidas cautelares, el tribunal arbitral está facultado a solicitar de 
            los funcionarios públicos, judiciales, policiales y administrativos su cumplimiento, sin que sea
            necesario recurrir a juez ordinario alguno. 
           </p>
           <p>
            Sin perjuicio de lo anterior, las partes podrán acudir a la justicia ordinaria para solicitar medidas 
            cautelares o medidas similares, sin que aquello implique renuncia al convenio arbitral.
           </p>
          </li>
          <li>
          <b>e)</b> Las partes se obligan a acatar el laudo que expida el tribunal arbitral y se comprometen a no 
          interponer ningún tipo de acción o recurso en contra del laudo arbitral, con excepción de lo previsto en la ley.
          </li>
        </ul>
      </Container>

      <div
        className="footer"
        // style={{ height: "fit-content", minHeight: "auto" }}
      >
        <div style={{ height: "fit-content", paddingTop: "8vh" }}>
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ paddingTop: "5%", paddingBottom: "5%" }}
          >
            <p>
              <img src={whiteLogo} height="65" width="150" alt="INBEAT" />
            </p>
          </div>
        </div>
        <Container></Container>
      </div>
    </Container>
  );
};
