import React, { useContext, useEffect } from "react";
import { PageTitle } from "../commons/page.title";
import { Notification } from "../commons/notification";
import LoadingPage from "../commons/loading.page";
import { Row } from "react-bootstrap";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { NewsContext } from "../../context/news/news.state";
import { Formik } from "formik";
import { Redirect } from "react-router-dom";
import * as Schema from "./news.schema";
import { NewsForm } from "./news.form";

const LoadingEmptyComponent = LoadingPage(<></>);

export const NewsEdit = props => {
  const {
    isLoading,
    editNews,
    notify,
    showNotification,
    notificationConfig,
    redirect,
    getNewsById,
    toEdit,
    cathalogs
  } = useContext(NewsContext);

  const { nid } = props.match.params;

  useEffect(() => {
    getNewsById(nid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let redirectComponent = (
    <Redirect
      to={{
        pathname: "/news",
        state: {
          show: true,
          message: "Exito! La noticia se ha editado",
          status: "success"
        }
      }}
    ></Redirect>
  );

  if (redirect) {
    return redirectComponent;
  }

  if (isLoading === true) {
    return <LoadingEmptyComponent data={isLoading} />;
  }
  if (Object.keys(toEdit).length > 0 && isLoading === false) {
    let initialValues = Schema.initialValues;
    initialValues = toEdit;
    initialValues.image = "";
    initialValues.date = toEdit.news_date
    return (
      <>
        <Row>
          <Notification
            notify={notify}
            notificationConfig={notificationConfig}
            showNotification={showNotification}
          />
        </Row>
        <PageTitle title="+ Editar Noticia" />

        <Formik
          validationSchema={Schema.getSchema("edit")}
          onSubmit={values => {
            const editedNews = {
              id: values.id,
              title: values.title,
              summary: values.summary,
              body: values.body,
              status_id: values.status_id,
              date: values.date
            };
            if (values.image) {
              editedNews.image = values.image;
            }
            editNews(editedNews);
          }}
          initialValues={initialValues}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            setFieldValue,
            setFieldTouched,
            values,
            touched,
            isValid,
            errors
          }) => (
            <NewsForm
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              touched={touched}
              errors={errors}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              btnTxt="GUARDAR CAMBIOS"
              btnIcon={faSave}
              cathalogs={cathalogs}
            />
          )}
        </Formik>
      </>
    );
  }

  return (
    <Row>
      <Notification
        notify={notify}
        notificationConfig={notificationConfig}
        showNotification={showNotification}
      />
    </Row>
  );
};
