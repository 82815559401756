import React, { useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import addPlayList from "../../../images/iconocrear_playlist.png";
// import { PlayListContext } from "../../../context/site/playlist/playlist.state";
export const PlayListActions = ({
  handleAddPlayList,
  playListForm,
  handleHide,
  handleNewPlayList
}) => {
  let [playlistTxt, setPlayListTxt] = useState("");
  const disabled = playlistTxt ? false : true;

  const AddPlayList = () => {
    if (playListForm.show === true) {
      setPlayListTxt("");
    }
    handleAddPlayList();
  };
  const hidePlayList = () => {
    setPlayListTxt("");
    handleHide();
  };
  const newPlayList = () => {
    handleNewPlayList(playlistTxt);
    setPlayListTxt("");
  };
  return (
    <>
      <Row className="mr-1 ml-1">
        <Col
          lg={{ span: 1, offset: 2 }}
          style={{ backgroundColor: "#ee5954", maxHeight: "12vh" }}
        >
          <div
            className="d-flex justify-content-between align-items-center pt-1 pb-1"
            style={{ cursor: "pointer" }}
            onClick={AddPlayList}
          >
            <span
              style={{
                fontFamily: '"Montserrat", sans-serif',
                fontSize: "9pt",
                lineHeight: 1.3,
                fontWeight: 500,
                color: "#1c1126"
              }}
              className="mr-3"
            >
              Crear Playlist
            </span>
            <img
              src={addPlayList}
              width="22vw"
              height="24vh"
              alt="Play List"
              className="mr-1"
            />
          </div>
        </Col>
        {playListForm.show && (
          <Col
            lg={{ span: 6 }}
            style={{ backgroundColor: "#ee5954", maxHeight: "12vh" }}
            className="ml-1"
          >
            <div className="d-flex justify-content-between align-items-center pt-1 pb-1">
              <Form.Control
                size="sm"
                type="text"
                className="mr-4"
                value={playlistTxt || playListForm.txt}
                onChange={e => setPlayListTxt(e.target.value)}
              />
              <Button
                size="sm"
                className="mr-4 radius"
                style={{
                  backgroundColor: "white",
                  borderColor: "white",
                  fontFamily: '"Montserrat", sans-serif',
                  color: "black",
                  fontSize: "9pt",
                  fontWeight: "bold"
                }}
                disabled={disabled}
                onClick={newPlayList}
              >
                Guardar
              </Button>
              <Button
                variant="light"
                size="sm"
                style={{
                  backgroundColor: "white",
                  borderColor: "white",
                  fontFamily: '"Montserrat", sans-serif',
                  color: "black",
                  fontSize: "9pt",
                  fontWeight: "bold"
                }}
                className="radius"
                onClick={hidePlayList}
              >
                Cancelar
              </Button>
            </div>
          </Col>
        )}
      </Row>
    </>
  );
};
