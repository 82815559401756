const getCategories = (state, action) => {
  return {
    ...state,
    categories: action.payload
  };
};

const addGenres = (state, action) => {
  return {
    ...state,
    categories: [...state.categories, action.payload]
  };
};

const doActions = (state, action) => {
  let status = { delete: "Eliminado", activate: "Activo" };
  if (action.payload.action) {
    return {
      ...state,
      categories: state.categories.map(_ => {
        if (_.id === action.payload.eid) {
          let temp = { ..._ };
          temp.status = status[action.payload.action];
          return temp;
        }
        return _;
      })
    };
  }
  return { ...state };
};

export default (state, action) => {
  const actions = {
    GET_CATEGORIES: getCategories,
    ADD_GENRE: addGenres,
    DO_ACTIONS: doActions
  };
  return actions[action.type](state, action) || state;
};
