import * as Yup from "yup";

const schema = Yup.object({
  text: Yup.string().max(128, "El filtro no debe superar los 128 caracteres"),
  cathegory: Yup.string().required("Este campo es requerido.")
});

let initialValues = {
  text: "",
  cathegory: ""
};

export { schema, initialValues };
