import React, { useContext, useEffect } from "react";
import { PageTitle } from "../commons/page.title";
import LoadingPage from "../commons/loading.page";
import { Notification } from "../commons/notification";
import { Row } from "react-bootstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { NewsContext } from "../../context/news/news.state";
import { Formik } from "formik";
import { Redirect } from "react-router-dom";
import * as Schema from "./news.schema";
import { NewsForm } from "./news.form";

const LoadingEmptyComponent = LoadingPage(<></>);

export const NewsAdd = () => {
  const {
    isLoading,
    addNews,
    getCathalogs,
    cathalogs,
    notify,
    showNotification,
    notificationConfig,
    redirect
  } = useContext(NewsContext);
  useEffect(() => {
    getCathalogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let redirectComponent = (
    <Redirect
      to={{
        pathname: "/news",
        state: {
          show: true,
          message: "Exito! La noticia se ha adicionado",
          status: "success"
        }
      }}
    ></Redirect>
  );
  if (redirect) {
    return redirectComponent;
  }

  if (isLoading === true) {
    return <LoadingEmptyComponent data={isLoading} />;
  }
  if (Object.keys(cathalogs).length > 0) {
    return (
      <>
        <Row>
          <Notification
            notify={notify}
            notificationConfig={notificationConfig}
            showNotification={showNotification}
          />
        </Row>
        <PageTitle title="+ Adicionar Contenido" />
        <Formik
          validationSchema={Schema.getSchema("add")}
          onSubmit={values => {
            addNews(values);
          }}
          initialValues={Schema.initialValues}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setFieldValue,
            setFieldTouched
          }) => (
            <NewsForm
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              touched={touched}
              errors={errors}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              btnTxt="ADICIONAR NOTICIA"
              btnIcon={faPlus}
              cathalogs={cathalogs}
            />
          )}
        </Formik>
      </>
    );
  }
  return (
    <Row>
      <Notification
        notify={notify}
        notificationConfig={notificationConfig}
        showNotification={showNotification}
      />
    </Row>
  );
};
