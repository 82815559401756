import { Genres } from "../components/music-genres/genres";
import { Artist } from "../components/artist/artist";
import { ArtistAdd } from "../components/artist/artist.add";
import { ArtistEdit } from "../components/artist/artist.edit";
import { Content } from "../components/content/content";
import { ContentAdd } from "../components/content/content.add";
import { ContentEdit } from "../components/content/content.edit";
import { Login } from "../components/login/login";
import { News } from "../components/news/news";
import { NewsAdd } from "../components/news/news.add";
import { NewsEdit } from "../components/news/news.edit";
import { Promoted } from "../components/promoted/promoted";
export default {
  Genres,
  Artist,
  ArtistAdd,
  ArtistEdit,
  Content,
  ContentAdd,
  ContentEdit,
  Login,
  News,
  NewsAdd,
  NewsEdit,
  Promoted
};
