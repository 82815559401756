import React from "react";
import { Route, Redirect } from "react-router-dom";
import { NotFound } from "../page/404";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const storage = JSON.parse(localStorage.getItem("inbeat"));
  const isAuthenticated = storage && storage.login;
  return (
    <Route
      {...rest}
      component={props => {
        if (isAuthenticated) {
          return <Component {...props} />;
        }
        if (!isAuthenticated) {
          return <Redirect to="/admin-page" />;
        }
        return <NotFound />;
      }}
    />
  );
};
