import React from "react";
import { Col, Row } from "react-bootstrap";

export const PromotedTitle = ({ title }) => {
  return (
    <Row className="row">
      <Col lg={12} className="d-flex align-items-center justify-content-center">
        <h6 className="font-weight-bold text-muted mb-3">{title}</h6>
      </Col>
    </Row>
  );
};
