import * as Yup from "yup";

const FILE_SIZE = 536870912;
const FILE_IMAGE_SIZE = 2097152;
const SUPPORTED_FORMATS = [
  "audio/wav",
  "audio/mp3",
  "audio/ogg",
  "audio/mpeg",
  "video/webm",
  "video/mp4",
  "video/ogg"
];
const SUPPORTED_AUDIO_FORMATS = [
  "audio/wav",
  "audio/mp3",
  "audio/ogg",
  "audio/mpeg"
];
const SUPPORTED_VIDEO_FORMATS = ["video/webm", "video/mp4", "video/ogg"];
const SUPPORTED_IMAGE_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const addSchema = Yup.object({
  name: Yup.string()
    .min(3, "Debe introducir un nombre de al menos 3 caracteres")
    .max(64, "El nombre no debe superar los 64 caracteres")
    .required("Este campo es requerido."),
  artist: Yup.string().required("Este campo es requerido."),
  content_type: Yup.string()
    .required("Este campo es requerido.")
    .test(
      "content-type-video",
      "Seleccione un formato de audio permitido (mp3, wav, ogg) o cambie el tipo de contenido seleccionado.",
      function(item) {
        if (
          this.parent.file &&
          item === "1" &&
          !SUPPORTED_AUDIO_FORMATS.includes(this.parent.file.type)
        ) {
          return false;
        }
        return true;
      }
    )
    .test(
      "content-type-audio",
      "Seleccione un formato de video permitido (mp4, wemb, ogg) o cambie el tipo de contenido seleccionado.",
      function(item) {
        if (
          this.parent.file &&
          item === "2" &&
          !SUPPORTED_VIDEO_FORMATS.includes(this.parent.file.type)
        ) {
          return false;
        }
        return true;
      }
    ),
  genres: Yup.array()
    .required("Este campo es requerido.")
    .min(1),
  file: Yup.mixed()
    .required("El contenido es requerido.")
    .test(
      "content-format",
      "Solo se aceptan los siguientes formatos .wav .mp3 .webm .mp4 .ogg, .mpeg",
      value => !(value && !SUPPORTED_FORMATS.includes(value.type))
    )
    .test(
      "content-size",
      "El contenido no debe superar los 512MB",
      value => !(value && value.size > FILE_SIZE)
    ),
  preview: Yup.mixed()
    //.required("El preview es requerido.")
    .test(
      "same-file-type",
      "El formato debe coincidir con el/los formatos permitido para el tipo de contenido seleccionado. Si el contenido es audio, el preview debe ser (mp3, wav, ogg) si es video (mp4, wemb, ogg).",
      function(item) {
        const { file } = this.parent;
        const validOn =
          file && file.type && SUPPORTED_AUDIO_FORMATS.includes(file.type)
            ? SUPPORTED_AUDIO_FORMATS
            : SUPPORTED_VIDEO_FORMATS;
        if (
          item &&
          item.type &&
          file &&
          file.type &&
          !validOn.includes(item.type)
        ) {
          return false;
        }
        return true;
      }
    ),
  image: Yup.mixed()
    .required("La imagen del contenido es requerida.")
    .test(
      "picture-format",
      "Solo se aceptan los siguientes formatos .jpg, .jpeg, .png",
      value => !(value && !SUPPORTED_IMAGE_FORMATS.includes(value.type))
    )
    .test(
      "picture-size",
      "La imagen no debe superar los 2MB",
      value => !(value && value.size > FILE_IMAGE_SIZE)
    )
});

const editSchema = Yup.object({
  name: Yup.string()
    .min(3, "Debe introducir un nombre de al menos 3 caracteres")
    .max(64, "El nombre no debe superar los 64 caracteres")
    .required("Este campo es requerido."),
  artist: Yup.string().required("Este campo es requerido."),
  content_type: Yup.string()
    .test(
      "is-required",
      "Al cargar un nuevo contenido, este campo es obligatorio.",
      function(item) {
        if (this.parent.file && !item) {
          return false;
        }
        return true;
      }
    )
    .test(
      "content-type-video",
      "Seleccione un formato de audio permitido (mp3, wav, ogg) o cambie el tipo de contenido seleccionado.",
      function(item) {
        if (
          this.parent.file &&
          item === "1" &&
          !SUPPORTED_AUDIO_FORMATS.includes(this.parent.file.type)
        ) {
          return false;
        }
        return true;
      }
    )
    .test(
      "content-type-audio",
      "Seleccione un formato de video permitido (mp4, wemb, ogg) o cambie el tipo de contenido seleccionado.",
      function(item) {
        if (
          this.parent.file &&
          item === "2" &&
          !SUPPORTED_VIDEO_FORMATS.includes(this.parent.file.type)
        ) {
          return false;
        }
        return true;
      }
    ),
  genres: Yup.array()
    .required("Este campo es requerido.")
    .min(1),
  file: Yup.mixed()
    .test(
      "content-format",
      "Solo se aceptan los siguientes formatos .wav .mp3 .webm .mp4 .ogg, .mpeg",
      value => !(value && !SUPPORTED_FORMATS.includes(value.type))
    )
    .test(
      "content-size",
      "El contenido no debe superar los 512MB",
      value => !(value && value.size > FILE_SIZE)
    ),
  preview: Yup.mixed()
    .test(
      "isRequired",
      "Al cargar un nuevo contenido, este campo es obligatorio.",
      function(item) {
        if (this.parent.file && !item) {
          return false;
        }
        return true;
      }
    )
    .test(
      "same-file-type",
      "El formato debe coincidir con el/los formatos permitido para el tipo de contenido seleccionado. Si el contenido es audio, el preview debe ser (mp3, wav, ogg) si es video (mp4, wemb, ogg).",
      function(item) {
        const { file } = this.parent;
        const validOn =
          file && file.type && SUPPORTED_AUDIO_FORMATS.includes(file.type)
            ? SUPPORTED_AUDIO_FORMATS
            : SUPPORTED_VIDEO_FORMATS;
        if (
          item &&
          item.type &&
          file &&
          file.type &&
          !validOn.includes(item.type)
        ) {
          return false;
        }
        return true;
      }
    ),
  image: Yup.mixed()
    .test(
      "picture-format",
      "Solo se aceptan los siguientes formatos .jpg, .jpeg, .png",
      value => !(value && !SUPPORTED_IMAGE_FORMATS.includes(value.type))
    )
    .test(
      "picture-size",
      "La imagen no debe superar los 2MB",
      value => !(value && value.size > FILE_IMAGE_SIZE)
    )
});

let initialValues = {
  name: "",
  artist: "",
  content_type: "",
  genres: [],
  file: "",
  preview: "",
  image: ""
};

export {
  addSchema,
  editSchema,
  initialValues,
  FILE_SIZE,
  SUPPORTED_FORMATS,
  SUPPORTED_AUDIO_FORMATS,
  SUPPORTED_VIDEO_FORMATS
};
