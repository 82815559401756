import React, { createContext, useReducer } from "react";
import ArtistReducer from "./artist.reducer";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useModal } from "../../hooks/useModal";
import { useNotification } from "../../hooks/useNotification";
import { httpRequest } from "../utils/http";

const initialState = {
  artists: [],
  redirect: false,
  toEdit: {}
};

const notificationsMessages = {
  error: {
    msg: "Error. Oopps! Algo salió mal!",
    status: "dark"
  },
  delete: {
    msg: "Éxito. El artista se ha eliminado satisfactoriamente!",
    status: "success"
  },
  activate: {
    msg: "Éxito. El artista se ha activado satisfactoriamente!",
    status: "success"
  }
};

export const ArtistContext = createContext(initialState);

export const ArtistProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ArtistReducer, initialState);
  const { execute, loading, error } = useHttpRequest(httpRequest, false);
  const { showModal, eid, show, action } = useModal();
  const { showNotification, notify, notificationConfig } = useNotification();

  const getArtists = async msg => {
    try {
      let result = await execute({ url: "admin/get_artist" });
      if (msg) {
        showNotification({ msg, status: "success" });
      }
      dispatch({
        type: "GET_ARTISTS",
        payload: result.data
      });
    } catch (e) {
      showNotification(notificationsMessages.error);
    }
  };

  const doActions = async () => {
    try {
      showNotification();
      let formData = new FormData();
      formData.append("artist_id", eid);
      const endpoint = {
        delete: "artists/delete",
        activate: "artists/activate"
      };
      await execute({
        url: endpoint[action],
        data: formData
      });
      dispatch({
        type: "DO_ACTIONS",
        payload: { eid, action }
      });
      showModal();
      showNotification(notificationsMessages[action]);
    } catch (e) {
      showNotification(notificationsMessages.error);
      showModal();
    }
  };

  const addArtists = async artist => {
    try {
      showNotification();
      let formData = new FormData();
      formData.append("artist_name", artist.name);
      formData.append("artist_biography", artist.biography);
      formData.append("facebook", artist.facebook);
      formData.append("instagram", artist.instagram);
      formData.append("twitter", artist.twitter);
      formData.append("picture", artist.picture);
      formData.append("interview", artist.interview);
      await execute({
        url: "artists/create",
        data: formData
      });
      dispatch({
        type: "ADD_ARTIST"
      });
    } catch (error) {
      showNotification(notificationsMessages.error);
    }
  };
  const editArtist = async artist => {
    try {
      showNotification();
      let formData = new FormData();
      formData.append("artist_id", artist.id);
      formData.append("artist_name", artist.name);
      formData.append("artist_biography", artist.biography);
      formData.append("facebook", artist.facebook);
      formData.append("instagram", artist.instagram);
      formData.append("twitter", artist.twitter);
      if (Object.keys(artist).includes("picture")) {
        formData.append("picture", artist.picture);
      }
      if (Object.keys(artist).includes("interview")) {
        formData.append("interview", artist.interview);
      }
      await execute({
        url: "artists/modify",
        data: formData
      });
      dispatch({
        type: "EDIT_ARTIST"
      });
    } catch (error) {
      showNotification(notificationsMessages.error);
    }
  };

  const getArtistById = async aid => {
    try {
      let formData = new FormData();
      formData.append("artist_id", aid);
      const result = await execute({
        url: "admin/get_artist_by_id",
        data: formData
      });
      if (result.data === null) {
        throw new Error();
      }
      dispatch({
        type: "GET_ARTIST_TO_EDIT",
        payload: result.data
      });
    } catch (error) {
      showNotification(notificationsMessages.error);
    }
  };

  return (
    <ArtistContext.Provider
      value={{
        artists: state.artists,
        isLoading: loading,
        redirect: state.redirect,
        toEdit: state.toEdit,
        notify,
        notificationConfig,
        show,
        error,
        getArtists,
        showModal,
        showNotification,
        doActions,
        addArtists,
        getArtistById,
        editArtist
      }}
    >
      {children}
    </ArtistContext.Provider>
  );
};
