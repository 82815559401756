import React from "react";
import { Container } from "react-bootstrap";

export const MainContent = () => {
  return (
    <div className="main-content main-not-auth-img">
      <Container>
        {/* <Row className="mr-2">
          <Col
            md={{ span: 1, offset: 8 }}
            xs={3}
            className="d-flex align-items-center justify-content-center social-media"
          >
            <SocialIcon
                    icon={facebook}
                    alt="Facebook"
                    src="https://facebook.com"
                  />
          </Col>
          <Col
            md={1}
            xs={3}
            className="d-flex align-items-center justify-content-center social-media"
          >
            <SocialIcon
                    icon={twitter}
                    alt="Twitter"
                    src="https://twitter.com"
                  />
          </Col>
          <Col
            md={1}
            xs={3}
            className="d-flex align-items-center justify-content-center social-media"
          >
            <SocialIcon
                    icon={instagram}
                    alt="Instagram"
                    src="https://instagram.com"
                  />
          </Col>
          <Col
            md={1}
            xs={3}
            className="d-flex align-items-center justify-content-center social-media"
          >
            <SocialIcon
                    icon={youtube}
                    alt="Youtube"
                    src="https://youtube.com"
                  />
          </Col>
        </Row> */}
      </Container>
    </div>
  );
};
