import { useReducer, useCallback } from "react";

const ACTIONS = {
  TOGGLE_SHOW_MODAL: "toggle-show-modal"
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.TOGGLE_SHOW_MODAL:
      return {
        ...state,
        show: !state.show,
        eid: action.payload.eid,
        action: action.payload.action
      };
    default:
      return state;
  }
};

export const useModal = () => {
  const [state, dispatch] = useReducer(reducer, {
    show: false,
    eid: -1,
    action: "idle"
  });

  const showModal = useCallback(param => {
    if (!param) {
      param = { eid: -1, action: "idle" };
    }
    dispatch({
      type: ACTIONS.TOGGLE_SHOW_MODAL,
      payload: param
    });
  }, []);

  return { showModal, ...state };
};
