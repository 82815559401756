import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faTrashRestore,
  faEdit,
  faAd,
  faUnlink
} from "@fortawesome/free-solid-svg-icons";
import { NewsContext } from "../../context/news/news.state";
import { Link } from "react-router-dom";

const CustomActions = ({ id, status, isPromoted }) => {
  const { showModalWithConfig, countPromotes } = useContext(NewsContext);
  if (status === "Activo") {
    return (
      <>
        <Link to={`/edit-news/${id}`}>
          <FontAwesomeIcon
            className="text-primary mr-2"
            icon={faEdit}
            title="EDITAR"
            style={{ cursor: "pointer" }}
          />
        </Link>
        <FontAwesomeIcon
          className="text-dark mr-2"
          icon={faTrash}
          title="ELIMINAR"
          style={{ cursor: "pointer" }}
          onClick={() => showModalWithConfig("delete", id)}
        />
        {countPromotes < 4 && !isPromoted && (
          <FontAwesomeIcon
            className="text-info mr-2"
            icon={faAd}
            title="PROMOCIONAR"
            onClick={() => showModalWithConfig("promote", id)}
          />
        )}
        {isPromoted && (
          <FontAwesomeIcon
            className="text-danger mr-2"
            icon={faUnlink}
            title="NO PROMOCIONAR"
            onClick={() => showModalWithConfig("unlink", id)}
          />
        )}
      </>
    );
  }
  return (
    <>
      <Link to={`/edit-news/${id}`}>
        <FontAwesomeIcon
          className="text-primary mr-2"
          icon={faEdit}
          title="EDITAR"
          style={{ cursor: "pointer" }}
        />
      </Link>
      <FontAwesomeIcon
        className="text-info"
        icon={faTrashRestore}
        title="ACTIVAR"
        style={{ cursor: "pointer" }}
        onClick={() => showModalWithConfig("activate", id)}
      />
    </>
  );
};

export { CustomActions };
