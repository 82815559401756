import React from "react";
import { Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faTrashRestoreAlt,
  faEdit
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const styleElements = {
  container: { padding: ".3rem" },
  date: { fontWeight: 700, fontSize: "12px" },
  actions: { cursor: "pointer", fontSize: 14 }
};

export const ArtistCardHeader = ({ status, created_at, id, showModal }) => {
  const icon = status === "Activo" ? faTrash : faTrashRestoreAlt;
  const iconColor = status === "Activo" ? "secondary" : "info";
  const badgeColor = status === "Activo" ? "info" : "secondary";
  const title = status === "Activo" ? "Eliminar" : "Activar";
  const { container, date, actions } = styleElements;

  const handleClick = () => {
    const action = status === "Activo" ? "delete" : "activate";
    showModal(action, id);
  };

  return (
    <div className="d-flex justify-content-between mb-2" style={container}>
      <div>
        <Badge variant={badgeColor}>{status}</Badge>
        <small className="text-muted ml-2" style={date}>
          {new Date(created_at).toLocaleDateString()}
        </small>
      </div>
      <div>
        <FontAwesomeIcon
          className={`text-${iconColor} mr-2`}
          icon={icon}
          title={title}
          onClick={handleClick}
          style={actions}
        />
        <Link to={`/edit-artist/${id}`}>
          <FontAwesomeIcon
            icon={faEdit}
            className="text-secondary"
            title="Editar"
            style={actions}
          />
        </Link>
      </div>
    </div>
  );
};
