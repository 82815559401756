import React, { useContext } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff, faHeadphonesAlt } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../../context/auth/auth.state";
import { Redirect } from "react-router-dom";

export const Navigation = props => {
  const { isAuthenticated, doLogout } = useContext(AuthContext);
  const mapping = [
    { active: "artist", paths: ["artist", "addArtist", "editArtist"] },
    { active: "content", paths: ["content", "addContent", "editContent"] },
    { active: "news", paths: ["news", "addNews", "editNews"] },
    { active: "genres", paths: ["genres"] },
    { active: "promoted", paths: ["promoted"] }
  ];
  const resolveActive = path => {
    let menu = mapping.find(
      _ => _.paths.includes(path) || _.paths.some(_a => path.indexOf(_a) !== -1)
    );
    return menu.active;
  };
  const handleLogout = async () => {
    await doLogout();
  };
  let active = resolveActive(props.name);
  if (!isAuthenticated()) {
    return <Redirect to="/admin-page" />;
  }
  return (
    <Navbar bg="dark" variant="dark">
      <Navbar.Brand href="#home">
        <FontAwesomeIcon
          className="mr-3 mt-1"
          icon={faHeadphonesAlt}
          title="SALIR"
          color="white"
        />
        IN BEAT
      </Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        <Nav>
          <Nav.Link href="#/genres" active={active === "genres" ? true : false}>
            GÉNERO
          </Nav.Link>
          <Nav.Link href="#/artist" active={active === "artist" ? true : false}>
            ARTISTAS
          </Nav.Link>
          <Nav.Link
            href="#/content"
            active={active === "content" ? true : false}
          >
            CONTENIDO
          </Nav.Link>
          <Nav.Link href="#/news" active={active === "news" ? true : false}>
            NOTICIAS
          </Nav.Link>
          <Nav.Link
            href="#/promoted"
            active={active === "promoted" ? true : false}
          >
            PROMOCIONADOS
          </Nav.Link>

          <FontAwesomeIcon
            className="mr-1"
            icon={faPowerOff}
            title="SALIR"
            color="white"
            style={{ marginTop: "0.77rem", cursor: "pointer" }}
            onClick={handleLogout}
          />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};
