import React from "react";
import { Col, Image } from "react-bootstrap";
import { serverPath } from "../../context/utils/file.path";
const url = `${serverPath}promotions/`;

const componentStyle = {
  image: { minHeight: "13vh", maxHeight: "13vh" },
  smallTxt: { fontSize: "60%", fontWeight: "600", cursor: "pointer" },
  title: { textAlign: "justify" }
};

export const PromotedCard = props => {
  const { image, smallTxt, title } = componentStyle;
  const { promoted_at, name, picture, table, showModal, id } = props;
  const mapType = {
    artist: "ARTISTA",
    news: "NOTICIA",
    music: "MÚSICA",
    video: "VIDEO"
  };
  const handleClick = () => {
    showModal("unpromote", id, table);
  };
  return (
    <>
      <Col lg={4} className="p-0" style={image}>
        <Image src={`${url}${picture}`} height="100%" width="100%" alt="img" />
      </Col>
      <Col lg={8}>
        <p className="text-muted mb-0">
          <small
            className="font-weight-bold text-muted mt-1 mb-0"
            style={smallTxt}
          >
            {new Date(promoted_at).toLocaleDateString()}
          </small>
        </p>
        <p className="mb-0" style={title}>
          <small className="font-weight-bold text-info ">
            {mapType[table]}:
          </small>
          <small className="font-weight-bold text-muted ml-1">{name}</small>
        </p>
        <div className="d-flex align-items-end justify-content-end mt-1">
          <small className="text-danger" style={smallTxt} onClick={handleClick}>
            DEJAR DE PROMOCIONAR
          </small>
        </div>
      </Col>
    </>
  );
};
