import React, { useContext, useEffect, useState } from "react";
import { Row, Col, InputGroup, FormControl, Container } from "react-bootstrap";
import { HeaderLogin } from "../landing-page/components/header.login";
import { Footer } from "../landing-page/components/footer";
import playCircle from "../../../images/play_circulo.png";
import { VideoContext } from "../../../context/site/video/video.state";
import { serverPath } from "../../../context/utils/file.path";
import { SiteAuthProvider } from "../../../context/site/siteAuth/site.auth.state";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
const urlContents = `${serverPath}contents/`;

export const Video = () => {
  const { video, getVideo } = useContext(VideoContext);
  const [filterText, setFilterText] = useState("");
  useEffect(() => {
    getVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const filteredItems = video.filter(
    item =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  function* getColorBox() {
    let counter = 0;
    const map = ["box-yellow", "box-orange", "box-purple", "box-blue"];
    while (true) {
      yield map[counter++];
      if (counter > 3) {
        counter = 0;
      }
    }
  }
  const gen = getColorBox();
  return (
    <>
      <SiteAuthProvider>
        <HeaderLogin />
      </SiteAuthProvider>
      <div className="top-rated" style={{ backgroundColor: "#000000" }}>
        <Container>
          <Row className="ml-4">
            <Col
              lg={{ span: 3, offset: 8 }}
              xs={{ span: 8, offset: 3 }}
              style={{ paddingRight: "0" }}
            >
              <InputGroup className="mt-2 mb-1">
                <FormControl
                  id="inlineFormInputGroup"
                  size="sm"
                  type="text"
                  placeholder="Buscar video"
                  value={filterText}
                  onChange={e => setFilterText(e.target.value)}
                />
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FontAwesomeIcon
                      icon={faSearch}
                      color="13133c"
                      className="mr-1"
                    />
                  </InputGroup.Text>
                </InputGroup.Prepend>
              </InputGroup>
            </Col>
          </Row>
        </Container>
        <Row className="ml-1 mr-1 mt-2 pb-4">
          {filteredItems.map((song, index) => {
            const lg =
              index % 4 === 0 ? { span: 2, offset: 2 } : { span: 2, offset: 0 };
            return (
              <Col lg={lg} className="mt-4 video-img-size" key={song.id}>
                <div className="w-100 h-100 pos-relative">
                <div style={{width: "100%", height: "80%", backgroundImage: `url("${urlContents}${song.image}")`, backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}/>
                  {/* <img
                    src={`${urlContents}${song.image}`}
                    alt="corazon"
                    height="80%"
                    width="100%"
                  /> */}
                  <a href={`#/site-video/${song.id}`}>
                    <img
                      src={playCircle}
                      width="14%"
                      height="14%"
                      className="pointer"
                      title="Reproducir"
                      alt="Reproducir"
                      style={{ position: "absolute", top: "37%", left: "42%" }}
                    />
                  </a>
                  <div
                    className={`d-flex justify-content-between align-items-center mt-3 player-box ${
                      gen.next().value
                    }`}
                  >
                    <div></div>
                    <span
                      className="music-song-title"
                      style={{ fontSize: "9pt" }}
                    >
                    <a href={`#/site-video/${song.id}`} style={{textDecoration: 'none', color:'white'}}>
                      {song.name} - {song.artist}
                    </a>
                    </span>

                    <div></div>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </div>
      <Footer />
    </>
  );
};
