const getArtists = (state, action) => {
  return {
    ...state,
    artists: action.payload.filter(_ => _.status === "Activo")
  };
};
const getArtistById = (state, action) => {
  return {
    ...state,
    current: action.payload
  };
};

const addFavorite = (state, action) => {
  let contents = state.current.contents.map(_ => {
    if (_.id === action.payload) {
      return { ..._, is_favorite: true };
    }
    return _;
  });
  return {
    ...state,
    current: { ...state.current, contents }
  };
};

const removeFavorite = (state, action) => {
  let contents = state.current.contents.map(_ => {
    if (_.id === action.payload) {
      return { ..._, is_favorite: false };
    }
    return _;
  });
  return {
    ...state,
    current: { ...state.current, contents }
  };
};

export default (state, action) => {
  const actions = {
    GET_ARTISTS: getArtists,
    GET_ARTIST_BY_ID: getArtistById,
    ADD_FAVORITE: addFavorite,
    REMOVE_FAVORITE: removeFavorite
  };
  return actions[action.type](state, action) || state;
};
