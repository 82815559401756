import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const FormElement = props => {
  let { label, wrapperClass, helpText, error, success } = props.custom;
  let validFeedback = props.validFeedback;
  success = success || "Bien! Entrada correcta.";
  return (
    <div className={wrapperClass}>
      <label>{label}</label>
      {props.children}
      <small className="form-text text-muted">{helpText}</small>
      <div className="invalid-feedback">{error}</div>
      {validFeedback && <div className="valid-feedback">{success}</div>}
    </div>
  );
};

export const ButtonSubmit = ({ isSubmitting, icon, color, txt }) => {
  return (
    <div className="row justify-content-end mt-2">
      <div className="col-lg-2 col-sm-4 col-xs-6">
        <button
          type="submit"
          className={`btn btn-${color} btn-sm btn-block font-weight-bold`}
          disabled={isSubmitting}
        >
          <FontAwesomeIcon className="mr-1" icon={icon} />
          {txt}
        </button>
      </div>
    </div>
  );
};

export const HeaderTitle = ({ txt }) => (
  <h4 style={{ display: "inline", fontWeight: "400" }}>{txt} </h4>
);
