export default (state, action) => {
  switch (action.type) {
    case "DO_LOGIN":
      localStorage.setItem(
        "inbeat",
        JSON.stringify({ ...action.payload, login: true })
      );
      return {
        ...state,
        login: true,
        error: "",
        redirect: true
      };
    case "DO_LOGOUT":
      localStorage.removeItem("inbeat");
      return {
        ...state,
        login: false,
        error: "",
        redirect: false
      };
    case "AUTH_ERROR":
      return {
        ...state,
        error: action.payload.msg ? action.payload.msg : "Opps! Algo salió mal"
      };
    case "SET_ERROR":
      return {
        ...state,
        error: ""
      };
    default:
      return state;
  }
};
