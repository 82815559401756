import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrash,
  faTrashRestore,
  faEdit
} from "@fortawesome/free-solid-svg-icons";
import { ContentContext } from "../../context/content/content.state";
import { Link } from "react-router-dom";

const CustomActions = ({ id, status }) => {
  const { showModalWithConfig } = useContext(ContentContext);

  if (status === "Activo") {
    return (
      <>
        <Link to={`/edit-content/${id}`}>
          <FontAwesomeIcon
            className="text-primary mr-2"
            icon={faEdit}
            title="EDITAR"
            style={{ cursor: "pointer" }}
          />
        </Link>
        <FontAwesomeIcon
          className="text-dark mr-2"
          icon={faTrash}
          title="ELIMINAR"
          style={{ cursor: "pointer" }}
          onClick={() => showModalWithConfig("delete", id)}
        />
      </>
    );
  }
  return (
    <>
      <Link to={`/edit-content/${id}`}>
        <FontAwesomeIcon
          className="text-primary mr-2"
          icon={faEdit}
          title="EDITAR"
          style={{ cursor: "pointer" }}
        />
      </Link>
      <FontAwesomeIcon
        className="text-info"
        icon={faTrashRestore}
        title="ACTIVAR"
        style={{ cursor: "pointer" }}
        onClick={() => showModalWithConfig("activate", id)}
      />
    </>
  );
};

export { CustomActions };
