import React, { useContext } from "react";
import "../../landing-page/no.auth.css";

import { SiteAuthContext } from "../../../../context/site/siteAuth/site.auth.state";
import { Redirect } from "react-router-dom";
import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";
import logo from "../../../../images/logo_INBEAT_Color.png";

export const HeaderLogin = () => {
  const { isAuthenticated, doLogout } = useContext(SiteAuthContext);
  const handleLogout = async () => {
    await doLogout();
  };
  if (!isAuthenticated()) {
    return <Redirect to="/" />;
  }
  return (
    <div className="header">
      <Container style={{ maxHeight: "150px" }}>
        <Row style={{ maxHeight: "150px" }}>
          <Col
            md={{ span: 2, offset: 0 }}
            xs={6}
            style={{ marginTop: "6.5vh" }}
          >
            <a href="#/home">
              <img src={logo} height="55" width="140" alt="INBEAT" />
            </a>
          </Col>
          {/* <Col xs={6} className="min-menu">
            <Row
              style={{ maxHeight: "65px", minHeight: "65px", marginTop: "4vh" }}
            >
              <Col xs="3">
                <a href="#/site-music">
                  <FontAwesomeIcon
                    icon={faMusic}
                    color="#ee5652"
                    className="mr-1"
                  />
                </a>
              </Col>
              <Col xs="3">
                <a href="#/site-artist">
                  <FontAwesomeIcon
                    icon={faUser}
                    color="#7c2a7d"
                    className="mr-1"
                  />
                </a>
              </Col>
              <Col style={{ fontWeight: "bold" }}>
                <a
                  href="#/"
                  onClick={e => {
                    e.preventDefault();
                    handleLogout();
                  }}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Salir
                </a>
              </Col>
            </Row>
            <Row style={{ maxHeight: "50px", minHeight: "50px" }}>
              <Col xs="3">
                <a href="#/site-video">
                  <FontAwesomeIcon
                    icon={faVideo}
                    color="#7c2a7d"
                    className="mr-1"
                  />
                </a>
              </Col>
              <Col xs="3">
                <a href="#/site-news">
                  <FontAwesomeIcon
                    icon={faMicrophoneAlt}
                    color="#ee5652"
                    className="mr-1"
                  />
                </a>
              </Col>
              <Col style={{ fontWeight: "bold" }}>
                <a
                  href="#/myplaylist"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  Playlist
                </a>
              </Col>
            </Row>
          </Col> */}
          <Col
            xs={6}
            className="d-flex align-items-center justify-content-center min-menu"
            style={{
              fontWeight: "bold",
              paddingTop: "15%",
              fontSize: "8pt",
              textTransform: "uppercase"
            }}
          >
            <a
              href="#/"
              onClick={e => {
                e.preventDefault();
                handleLogout();
              }}
              style={{ textDecoration: "none", color: "black", marginLeft: "78%" }}
            >
              Salir
            </a>
          </Col>
          <Col xs={12} className="mt-2 min-menu">
            <Row style={{ fontSize: "7pt" }}>
              <Col>
                <a href="#/site-music" className="orange font-weight-bold">
                  MUSICA
                </a>
              </Col>
              <Col>
                <a href="#/site-video" className="purple font-weight-bold">
                  VIDEOS
                </a>
              </Col>
              <Col>
                <a className="purple font-weight-bold" href="#/site-news">
                  NOTICIAS
                </a>
              </Col>
              <Col>
                <a className="black font-weight-bold" href="#/site-artist">
                  ARTISTAS
                </a>
              </Col>
              <Col>
                <a className="black font-weight-bold" href="#/myplaylist">
                  PLAYLIST
                </a>
              </Col>
            </Row>
          </Col>
          <Navbar
            variant="light"
            collapseOnSelect
            expand="lg"
            as={Col}
            className="site"
          >
            <Navbar.Collapse
              className="justify-content-end"
              id="responsive-navbar-nav"
            >
              <Nav className="site">
                <Nav.Link
                  href="#/site-artist"
                  className="d-flex align-items-end justify-content-end nav-items orange site"
                >
                  Artista
                </Nav.Link>
                <Nav.Link
                  href="#/site-music"
                  className="d-flex align-items-end justify-content-end nav-items purple ml-3rem site"
                >
                  Musica
                </Nav.Link>
                <Nav.Link
                  href="#/site-video"
                  className="d-flex align-items-end justify-content-end nav-items ml-3rem site"
                  style={{ color: "#b43e62" }}
                >
                  Videos
                </Nav.Link>
                <Nav.Link
                  href="#/site-news"
                  className="d-flex align-items-end justify-content-end nav-items black ml-3rem site"
                >
                  Noticias
                </Nav.Link>
                <Nav.Link
                  href="#/site-news"
                  className="d-flex align-items-end justify-content-end nav-items black ml-3rem site"
                >
                  |
                </Nav.Link>
                <Nav.Link
                  href="#/myplaylist"
                  className="d-flex align-items-end justify-content-end nav-items black ml-3rem site"
                >
                  My playlist
                </Nav.Link>
                <Nav.Link
                  href="#/"
                  className="d-flex align-items-end justify-content-end nav-items black ml-3rem site"
                  onClick={e => {
                    e.preventDefault();
                    handleLogout();
                  }}
                >
                  Salir
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Row>
      </Container>
    </div>
  );
};
