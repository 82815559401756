import React from "react";
import { Row, Col } from "react-bootstrap";

export const PlayListFooterBox = ({
  name,
  color,
  handleRemovePlayList,
  handleShowPlayListEdit,
  id
}) => {
  function* getColorBox() {
    let counter = 0;
    const map = [
      "color-5ol",
      "color-4bb",
      "color-3pb",
      "color-2pl",
      "color-1o"
    ];
    while (true) {
      yield map[counter++];
      if (counter > 4) {
        counter = 0;
      }
    }
  }
  const gen = getColorBox();
  const handleDeletePList = () => {
    handleRemovePlayList(id);
  };
  const handleEditPList = () => {
    handleShowPlayListEdit(id, name);
  };
  if (name !== "Favoritos")
    return (
      <Row className="mr-1 ml-1 mt-1">
        <Col
          lg={{ span: 8, offset: 2 }}
          style={{ maxHeight: "12vh" }}
          className={`${gen.next().value}`}
        >
          <div className="d-flex justify-content-start align-items-center pt-1 pb-1">
            <span
              style={{
                fontFamily: '"Montserrat", sans-serif',
                fontSize: "11pt",
                fontWeight: "bold",
                color: "white",
                cursor: "pointer"
              }}
              className="mr-4"
              onClick={handleDeletePList}
            >
              Eliminar
            </span>
            <span
              style={{
                fontFamily: '"Montserrat", sans-serif',
                fontSize: "11pt",
                fontWeight: "bold",
                color: "white",
                cursor: "pointer"
              }}
              className="ml-2"
              onClick={handleEditPList}
            >
              Editar
            </span>
          </div>
        </Col>
      </Row>
    );
  return <></>;
};
