import React from "react";
import { SiteForgotPass } from "../components/public-site/login/site.forgot.pass";
import { SiteAuthProvider } from "../context/site/siteAuth/site.auth.state";
export const PageSiteForgotPass = () => {
  return (
    <SiteAuthProvider>
      <SiteForgotPass />
      </SiteAuthProvider>
  );
};
