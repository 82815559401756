import React from "react";
import { Artist } from "../components/public-site/artist/artist";
import { ArtistProvider } from "../context/site/artist/artist.state";

export const ArtistPage = () => {
  return (
    <ArtistProvider>
      <Artist />
    </ArtistProvider>
  );
};
