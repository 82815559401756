import React, { useState, useEffect, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import LoadingPage from "../commons/loading.page";
import { CustomModal } from "../commons/modal";
import { Notification } from "../commons/notification";
import { PromotedContext } from "../../context/promoted/promoted.state";
import { PromotedTitle } from "./promoted.title";
import { PromotedForm } from "./promoted.form";
import { Formik } from "formik";
import DataTable, { createTheme } from "react-data-table-component";
import { PromotedList } from "./promoted.list";
import { schema, initialValues } from "./promoted.schema";
import { Loading } from "../commons/loading";
import { Columns, paginationOptions } from "./table.config";

const PromotedListWithLoading = LoadingPage(PromotedList);
createTheme("solarized", {
  background: {
    default: "#f8f9fa"
  },
  context: {
    background: "#f8f9fa"
  }
});

export const Promoted = () => {
  let {
    isLoading,
    getPromotions,
    promotions,
    doActions,
    notify,
    showModal,
    showNotification,
    notificationConfig,
    show,
    showModalWithConfig,
    filterContent,
    contentFiltered,
    isFiltering,
    modalConfig
  } = useContext(PromotedContext);
  const [resetPaginationToggle] = useState(false);
  useEffect(() => {
    getPromotions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowModal = (action, eid, table) => {
    showModalWithConfig("unpromote", eid, null, table);
  };

  return (
    <>
      <Row>
        <Notification
          notify={notify}
          notificationConfig={notificationConfig}
          showNotification={showNotification}
        />
      </Row>
      <div className="ml-4 my-3 py-2 z-depth-1">
        <Row>
          <Col lg={5} className="mt-1">
            <PromotedTitle title="CONTENIDO PROMOCIONADO" />
            <PromotedListWithLoading
              promotions={promotions}
              data={isLoading && !isFiltering}
              handleShowModal={handleShowModal}
              dimensions="12"
              msg={true}
            />
          </Col>
          <Col lg={7} className="mt-1">
            <PromotedTitle title="FILTRO DE CONTENIDO" />
            <Formik
              validationSchema={schema}
              onSubmit={values => {
                filterContent(values.text, values.cathegory);
              }}
              initialValues={initialValues}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors
              }) => (
                <PromotedForm
                  handleSubmit={handleSubmit}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  values={values}
                  touched={touched}
                  errors={errors}
                />
              )}
            </Formik>

            <div className="ml-4 mr-4">
              {isFiltering && <Loading />}
              {!isFiltering && (
                <DataTable
                  columns={Columns}
                  data={contentFiltered}
                  noHeader={true}
                  pagination
                  paginationResetDefaultPage={resetPaginationToggle}
                  paginationComponentOptions={paginationOptions}
                  theme="solarized"
                  noDataComponent={<div></div>}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
      <CustomModal
        show={show}
        onClose={showModal}
        onAccept={doActions}
        {...modalConfig}
      />
    </>
  );
};
