const getMusic = (state, action) => {
  return {
    ...state,
    music: action.payload
  };
};

const addFavorite = (state, action) => {
  return {
    ...state,
    music: state.music.map(_ => {
      if (_.id === action.payload) {
        return { ..._, is_favorite: true };
      }
      return _;
    })
  };
};
const voted = (state, action) => {
  return {
    ...state,
    music: state.music.map(_ => {
      if (_.id === action.payload) {
        return { ..._, user_voted: true };
      }
      return _;
    })
  };
};

const removeFavorite = (state, action) => {
  return {
    ...state,
    music: state.music.map(_ => {
      if (_.id === action.payload) {
        return { ..._, is_favorite: false };
      }
      return _;
    })
  };
};

const getPlayLists = (state, action) => {
  console.log(action.payload);
  return {
    ...state,
    playlists: action.payload
  };
};

export default (state, action) => {
  const actions = {
    GET_MUSIC: getMusic,
    ADD_FAVORITE: addFavorite,
    REMOVE_FAVORITE: removeFavorite,
    GET_PLAYLISTS: getPlayLists,
    VOTED: voted
  };
  return actions[action.type](state, action) || state;
};
