import React from "react";
import { Container } from "react-bootstrap";
import whiteLogo from "../../../../images/logo_INbeat_White.png";

export const Footer = () => {
  return (
    <div
      className="footer"
      style={{ height: "fit-content", minHeight: "auto" }}
    >
      <div style={{ height: "fit-content" }}>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ paddingTop: "5%", paddingBottom: "5%" }}
        >
          <p>
            <img src={whiteLogo} height="65" width="150" alt="INBEAT" />
          </p>
          
        </div>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ paddingTop: "5%", paddingBottom: "5%" }}
        >
          <p>
            <a href="#/terms-conditions" style={{color:"white"}}> Términos y Condiciones</a>
          </p>
          
        </div>
        {/* <Container style={{ paddingTop: "5%", paddingBottom: "10%" }}>
          <div className="d-flex align-items-center justify-content-between ml-4 mr-4">
            <p>
              <img src={facebook} height="40" width="40" alt="facebook" />
            </p>
            <p>
              <img src={twitter} height="40" width="40" alt="facebook" />
            </p>
            <p>
              <img src={instagram} height="40" width="40" alt="facebook" />
            </p>
            <p>
              <img src={youtube} height="40" width="40" alt="facebook" />
            </p>
          </div>
        </Container> */}
      </div>
      <Container>
        {/* <Row style={{ paddingBottom: "80px" }}>
          <Col
            md={{ span: 2, offset: 1 }}
            xs={{ span: 8, offset: 1 }}
            style={{ paddingTop: "80px" }}
          >
            <img src={whiteLogo} height="65" width="150" alt="INBEAT" />
          </Col>
          <Col
            md={{ span: 2, offset: 1 }}
            xs={{ span: 4, offset: 0 }}
            style={{ paddingTop: "80px", marginRight: "25px" }}
          >
            <ul
              style={{
                fontFamily: '"Montserrat", sans-serif',
                color: "#ffffff",
                listStyle: "none",
                fontSize: "12pt"
              }}
            >
              <li className="font-weight-bold">Compañia</li>
              <li>Acerca de</li>
            </ul>
          </Col>
          <Col
            md={{ span: 2, offset: 0 }}
            xs={{ span: 3, offset: 0 }}
            style={{
              paddingTop: "80px",
              paddingLeft: "0",
              marginRight: "25px"
            }}
          >
            <ul
              style={{
                fontFamily: '"Montserrat", sans-serif',
                color: "#ffffff",
                listStyle: "none",
                fontSize: "12pt"
              }}
              // className="ml-2"
            >
              <li className="font-weight-bold">Comunidades</li>
              <li>Para artistas</li>
              <li>Desarrolladores</li>
              <li>Marcas</li>
              <li>Inversionistas</li>
              <li>Proveedores</li>
            </ul>
          </Col>
          <Col
            md={{ span: 3, offset: 0 }}
            xs={{ span: 8, offset: 0 }}
            style={{ paddingTop: "80px" }}
          >
            <ul
              style={{
                fontFamily: '"Montserrat", sans-serif',
                color: "#ffffff",
                listStyle: "none",
                fontSize: "12pt"
              }}
            >
              <li className="font-weight-bold">Enlaces útiles</li>
              <li>Ayuda</li>
            </ul>
          </Col>
        </Row>
        <Row
          style={{
            paddingBottom: "40px",
            paddingTop: "40px",
            paddingTop: "80px"
          }}
          className="ml-4"
        >
          <Col
            md={{ span: 1, offset: 4 }}
            xs={3}
            className="d-flex align-items-center justify-content-center social-media"
          >
            <img src={facebook} height="40" width="40" alt="facebook" />
          </Col>
          <Col
            md={1}
            xs={3}
            className="d-flex align-items-center justify-content-center social-media"
          >
            <img src={twitter} height="40" width="40" alt="facebook" />
          </Col>
          <Col
            md={1}
            xs={3}
            className="d-flex align-items-center justify-content-center social-media"
          >
            <img src={instagram} height="40" width="40" alt="facebook" />
          </Col>
          <Col
            md={1}
            xs={3}
            className="d-flex align-items-center justify-content-center social-media"
          >
            <img src={youtube} height="40" width="40" alt="facebook" />
          </Col>
        </Row>
        <Row
          style={{
            paddingBottom: "40px",
            marginLeft: "5rem"
          }}
        >
          <Col
            md={{ span: 1, offset: 2 }}
            xs={{ span: 5, offset: 1 }}
            style={{
              fontFamily: '"Montserrat", sans-serif',
              color: "#ffffff",
              fontSize: "10pt"
            }}
            className="d-flex align-items-start justify-content-start social-media"
          >
            Legal
          </Col>
          <Col
            md={{ span: 2, offset: 0 }}
            xs={{ span: 6, offset: 0 }}
            style={{
              fontFamily: '"Montserrat", sans-serif',
              color: "#ffffff",
              fontSize: "10pt"
            }}
            className="d-flex align-items-start justify-content-start social-media"
          >
            Centro de privacidad
          </Col>
          <Col
            md={{ span: 1, offset: 0 }}
            xs={{ span: 5, offset: 1 }}
            style={{
              fontFamily: '"Montserrat", sans-serif',
              color: "#ffffff",
              fontSize: "10pt"
            }}
            className="d-flex align-items-start justify-content-start social-media"
          >
            Cookies
          </Col>
          <Col
            md={{ span: 2, offset: 0 }}
            xs={{ span: 6, offset: 0 }}
            style={{
              fontFamily: '"Montserrat", sans-serif',
              color: "#ffffff",
              fontSize: "10pt"
            }}
            className="d-flex align-items-start justify-content-start social-media"
          >
            Política de privacidad
          </Col>
          <Col
            md={{ span: 2, offset: 0 }}
            xs={{ span: 6, offset: 6 }}
            style={{
              fontFamily: '"Montserrat", sans-serif',
              color: "#ffffff",
              fontSize: "10pt"
            }}
            className="d-flex align-items-start justify-content-start social-media"
          >
            Sobre los anuncios
          </Col>
        </Row> */}
      </Container>
    </div>
  );
};
