import React from "react";
import { Auth } from "../components/public-site/landing-page/auth";
import { LandingProvider } from "../context/site/landing.state";
export const Home = () => {
  return (
    <LandingProvider>
      <Auth />
    </LandingProvider>
  );
};
