import React, { createContext, useReducer } from "react";
import AuthReducer from "./auth.reducer";
import httpRequest from "./http";
//Initial state
const storage = JSON.parse(localStorage.getItem("inbeat"));
const initialState = {
  login: storage && storage.login ? storage.login : false,
  error: "",
  redirect: false
};

// Create context
export const AuthContext = createContext(initialState);

// Provaider component
export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, initialState);
  //Actions
  const doLogin = async user => {
    try {
      let formData = new FormData();
      formData.append("email", user.email);
      formData.append("password", user.password);
      const response = await httpRequest({
        url: "security/do_login",
        data: formData
      });
      if (response.data.cod !== 100) {
        let err = new Error(response.data.des);
        err.status = 400;
        err.msg = response.data.des;
        throw err;
      }
      dispatch({
        type: "DO_LOGIN",
        payload: response.data.data
      });
    } catch (error) {
      dispatch({
        type: "AUTH_ERROR",
        payload: error
      });
    }
  };

  const doLogout = async () => {
    try {
      let formData = new FormData();
      formData.append(
        "user_id",
        JSON.parse(localStorage.getItem("inbeat")).user_id
      );
      // formData.append("password", user.password);
      const response = await httpRequest({
        url: "security/do_logout",
        data: formData
      });
      if (response.data.cod !== 100) {
        let err = new Error(response.data.des);
        err.status = 400;
        err.msg = response.data.des;
        throw err;
      }
      dispatch({
        type: "DO_LOGOUT"
      });
    } catch (error) {
      dispatch({
        type: "AUTH_ERROR",
        payload: error
      });
    }
  };

  const isAuthenticated = () =>
    JSON.parse(localStorage.getItem("inbeat")) &&
    JSON.parse(localStorage.getItem("inbeat")).login;

  const setError = () => {
    dispatch({
      type: "SET_ERROR"
    });
  };

  return (
    <AuthContext.Provider
      value={{
        user: state,
        redirect: state.redirect,
        doLogin,
        doLogout,
        isAuthenticated,
        setError
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
