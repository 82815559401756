import React from "react";
import { Row } from "react-bootstrap";
import { ArtistCard } from "./artist.card";
import { EmptyTable } from "../commons/empty.result";

export const ArtistList = props => {
  const { artists, subHeaderFilterForm, handleShowModal } = props;
  if (!artists || artists.length === 0) {
    return (
      <>
        {subHeaderFilterForm}
        <EmptyTable />
      </>
    );
  }
  return (
    <>
      {subHeaderFilterForm}
      <Row>
        {artists.map((art, index) => {
          return (
            <ArtistCard key={art.id} {...art} showModal={handleShowModal} />
          );
        })}
      </Row>
    </>
  );
};
