import React, { useContext, useEffect, useState } from "react";
import { DataTableComponent } from "../commons/data.table";
import FilterComponent from "../commons/table.filter";
import LoadingPage from "../commons/loading.page";
import { CustomModal } from "../commons/modal";
import { AddElement } from "../commons/add.element";
import { PageTitle } from "../commons/page.title";
import { Notification } from "../commons/notification";
import { Columns, paginationOptions } from "./table.columns";
import { ContentContext } from "../../context/content/content.state";

const ResultDataTableWithLoading = LoadingPage(DataTableComponent);

export const Content = props => {
  let {
    isLoading,
    getContents,
    contents,
    notify,
    showNotification,
    notificationConfig,
    show,
    showModal,
    modalConfig,
    doActions
  } = useContext(ContentContext);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  useEffect(() => {
    let msg = null;
    if (
      props.location.state &&
      props.location.state.show === true &&
      props.history.action === "REPLACE"
    ) {
      msg = props.location.state.message;
    }
    getContents(msg);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredItems = contents.filter(
    item =>
      item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        helpTxt={"Filtra por nombre"}
      />
    );
  }, [filterText, resetPaginationToggle]);

  return (
    <>
      <Notification
        notify={notify}
        notificationConfig={notificationConfig}
        showNotification={showNotification}
      />
      <AddElement txt="ADICIONAR CONTENIDO" action="add-content" />
      <div style={{ clear: "both" }}></div>
      <ResultDataTableWithLoading
        title={<PageTitle title="Administrar Contenido" ml="ml-0" />}
        columns={Columns}
        items={filteredItems}
        pagination
        paginationResetDefaultPage={resetPaginationToggle}
        paginationComponentOptions={paginationOptions}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        data={isLoading}
      />
      <CustomModal
        show={show}
        onClose={showModal}
        onAccept={doActions}
        {...modalConfig}
      />
    </>
  );
};
