import React from "react";
import { Row, Col } from "react-bootstrap";

import pauseIcon from "../../../images/parlante.png";

export const MusicBox = ({
  item,
  gen,
  index,
  handleRemoveContent,
  plId,
  currentIndexSong
}) => {
  const handleRemoveItem = () => {
    handleRemoveContent(plId, item.id);
  };
  return (
    <>
      <Row className="mr-1 ml-1 mt-1" key={item}>
        <Col
          lg={{ span: 5, offset: 5 }}
          style={{
            maxHeight: "14vh"
          }}
          className={`${gen.next().value}`}
        >
          <span
            style={{
              color: "white",
              fontWeight: "bold",
              position: "absolute",
              top: -3,
              right: 2,
              cursor: "pointer",
              fontSize: "10pt"
            }}
            title="Eliminar"
            onClick={handleRemoveItem}
          >
            x
          </span>
          <div className="d-flex justify-content-between align-items-center pt-1 pb-1">
            <div>
              <span
                style={{
                  fontFamily: '"Montserrat", sans-serif',
                  fontSize: "11pt",
                  fontWeight: "bold",
                  color: "white",
                  paddingTop: ".4rem"
                }}
                className="mr-1"
              >
                {index}
              </span>
              {index - 1 === currentIndexSong && (
                <img
                  src={pauseIcon}
                  width="20vw"
                  height="14%"
                  className="pointer"
                  title="Detener"
                  alt="Detener"
                />
              )}
            </div>
            <span
              style={{
                fontFamily: '"Montserrat", sans-serif',
                fontSize: "11pt",
                color: "white"
              }}
              className="mr-3"
            >
              {item.name}
            </span>
            <span
              style={{
                fontFamily: '"Montserrat", sans-serif',
                fontSize: "11pt",
                fontWeight: "bold",
                color: "white"
              }}
              className="mr-3"
            >
              {item.artist_name}
            </span>
          </div>
        </Col>
      </Row>
    </>
  );
};
