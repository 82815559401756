import React, { useState } from "react";
import { Form, Col, Button, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFileImage } from "@fortawesome/free-solid-svg-icons";
import { UploadFile } from "../commons/upload.file";

export const ContentForm = props => {
  const [hasPreview, setHasPreview] = useState(true);
  let {
    handleSubmit,
    values,
    touched,
    handleChange,
    handleBlur,
    errors,
    setFieldValue,
    setFieldTouched,
    btnTxt,
    btnIcon,
    cathalogs,
    action
  } = props;
  let rule = touched.content_type && !!errors.content_type;
  let rulePreview = touched.preview && !!errors.preview;

  if (action) {
    rule = !!errors.content_type;
    rulePreview = !!errors.preview;
  }
  return (
    <Form className="mb-4 ml-4 mr-2" onSubmit={handleSubmit}>
      <Form.Row as={Row}>
        <Form.Group as={Col} xs={6}>
          <Form.Label>Nombre del Contenido</Form.Label>
          <Form.Control
            name="name"
            type="text"
            placeholder="Ingrese el nombre del contenido. Ej: Blanco y Negro"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            isValid={touched.name && !errors.name}
            isInvalid={touched.name && !!errors.name}
          />
          <Form.Control.Feedback>Entrada correcta!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {errors.name}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} xs={6}>
          <Form.Label className="my-1 mr-2">Artista</Form.Label>
          <Form.Control
            as="select"
            name="artist"
            value={values.artist}
            onChange={handleChange}
            onBlur={handleBlur}
            isValid={touched.artist && !errors.artist}
            isInvalid={touched.artist && !!errors.artist}
            custom
          >
            <option value="">Seleccione el artista</option>
            {cathalogs.artists.map(_ => (
              <option value={_.id} key={_.id}>
                {_.name}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback>Entrada correcta!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {errors.artist}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} xs={6}>
          <Form.Label className="my-1 mr-2">Tipo de Contenido</Form.Label>
          <Form.Control
            as="select"
            name="content_type"
            value={values.content_type}
            onChange={e => {
              if (e.target.value === "1") {
                setHasPreview(false);
              }
              if (e.target.value === "2") {
                setHasPreview(true);
                // handleChange();
              }
              setFieldValue("content_type", e.target.value);
            }}
            onBlur={handleBlur}
            isValid={touched.content_type && !errors.content_type}
            isInvalid={rule}
            custom
          >
            <option value="">Seleccione el tipo de contenido</option>
            {cathalogs.content_type.map(_ => (
              <option value={_.id} key={_.id}>
                {_.name}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback>Entrada correcta!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {errors.content_type}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} xs={6}>
          <Form.Label className="my-1 mr-2">
            Seleccione el/los Géneros
          </Form.Label>
          <Form.Control
            as="select"
            name="genres"
            value={values.genres}
            onChange={handleChange}
            onBlur={handleBlur}
            isValid={touched.genres && !errors.genres}
            isInvalid={touched.genres && !!errors.genres}
            multiple
          >
            {cathalogs.genres.map(_ => (
              <option value={_.id} key={_.id}>
                {_.name}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback>Entrada correcta!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {errors.genres}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} xs={6}>
          <UploadFile
            icon={faFile}
            placeholder="Cargue el contenido"
            handleChange={setFieldValue}
            value={values.file}
            name="file"
            isValid={touched.file && !errors.file}
            isInvalid={touched.file && !!errors.file}
            errors={errors.file}
            label="Contenido"
            handleTouched={setFieldTouched}
          />
        </Form.Group>
        {hasPreview && (
          <Form.Group as={Col} xs={6}>
            <UploadFile
              icon={faFile}
              placeholder="Preview del contenido"
              handleChange={setFieldValue}
              value={values.preview}
              name="preview"
              isValid={touched.preview && !errors.preview}
              isInvalid={rulePreview}
              errors={errors.preview}
              label="Preview"
              handleTouched={setFieldTouched}
            />
          </Form.Group>
        )}
        <Form.Group as={Col} xs={6}>
          <UploadFile
            icon={faFileImage}
            placeholder="Imagen"
            handleChange={setFieldValue}
            value={values.image}
            name="image"
            isValid={touched.image && !errors.image}
            isInvalid={touched.image && !!errors.image}
            errors={errors.image}
            label="Imagen"
            handleTouched={setFieldTouched}
          />
        </Form.Group>
        <Form.Group as={Col} xs={6} className="mr-4">
          <Button variant="info" type="submit">
            <FontAwesomeIcon icon={btnIcon} color="white" className="mr-2" />
            {btnTxt}
          </Button>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};
