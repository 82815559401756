const getArtist = (state, action) => {
  return {
    ...state,
    artists: action.payload
  };
};

const addOrEditArtist = (state, action) => {
  return {
    ...state,
    redirect: true
  };
};

const getArtistToEdit = (state, action) => {
  return {
    ...state,
    toEdit: action.payload,
    redirec: false
  };
};

const doActions = (state, action) => {
  let status = { delete: "Eliminado", activate: "Activo" };
  if (action.payload.action) {
    return {
      ...state,
      artists: state.artists.map(_ => {
        if (_.id === action.payload.eid) {
          let temp = { ..._ };
          temp.status = status[action.payload.action];
          return temp;
        }
        return _;
      })
    };
  }
  return { ...state };
};

export default (state, action) => {
  const actions = {
    GET_ARTISTS: getArtist,
    ADD_ARTIST: addOrEditArtist,
    EDIT_ARTIST: addOrEditArtist,
    DO_ACTIONS: doActions,
    GET_ARTIST_TO_EDIT: getArtistToEdit
  };
  return actions[action.type](state, action) || state;
};
