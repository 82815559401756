import React from "react";
import { SiteLogin } from "../components/public-site/login/site.login";
import { SiteAuthProvider } from "../context/site/siteAuth/site.auth.state";
export const PageSiteLogin = () => {
  return (
    <div>
      <SiteAuthProvider>
        <SiteLogin />
      </SiteAuthProvider>
    </div>
  );
};
