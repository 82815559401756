import React, { createContext, useReducer } from "react";
import NewsReducer from "./news.reducer";
import { useHttpRequest } from "../../../hooks/useHttpRequest";
import { httpRequest } from "../../utils/http";

const initialState = {
  news: [],
  page: 1,
  current: {}
};

export const NewsContext = createContext(initialState);

export const NewsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(NewsReducer, initialState);
  const { execute } = useHttpRequest(httpRequest, false);

  const getNews = async () => {
    try {
      let result = await execute({
        url: "news/get"
      });
      dispatch({
        type: "GET_NEWS",
        payload: result.data
      });
    } catch (e) {
      // showNotification(notificationsMessages.error);
    }
  };
  const getNewsById = async id => {
    try {
      let formData = new FormData();
      formData.append("news_id", id);
      let result = await execute({
        url: "news/get_by_id",
        data: formData
      });
      dispatch({
        type: "GET_NEWS_BY_ID",
        payload: result.data
      });
    } catch (e) {
      // showNotification(notificationsMessages.error);
    }
  };
  return (
    <NewsContext.Provider
      value={{
        news: state.news,
        page: state.page,
        current: state.current,
        getNews,
        getNewsById
      }}
    >
      {children}
    </NewsContext.Provider>
  );
};
