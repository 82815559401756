const config = {
  activate: {
    type: "confirmation",
    mainQuestion: "Está seguro?",
    description: "Está seguro que desea activar la noticia?",
    action: "delete"
  },
  delete: {
    type: "confirmation",
    mainQuestion: "Está seguro?",
    description: "Está seguro que desea eliminar la noticia?",
    action: "activate"
  },
  promote: {
    type: "confirmation",
    mainQuestion: "Está seguro?",
    description:
      "Está seguro que desea promocionar la noticia? Cuando una noticia es promocionada, esta se mostrará en la página principal del sistema.",
    action: "promote"
  },
  unlink: {
    type: "confirmation",
    mainQuestion: "Está seguro?",
    description:
      "Está seguro que desea dejar de promocionar la noticia? Cuando una noticia no está promocionada, esta dejará de ocurrir en la página principal del sistema.",
    action: "unlink"
  },
  default: {
    type: "confirmation",
    mainQuestion: "",
    description: ""
  }
};

const getNews = (state, action) => {
  const count = action.payload.filter(_ => _.promoted).length;
  return {
    ...state,
    news: action.payload,
    countPromotes: count
  };
};

const SetModalOption = (state, action) => {
  return {
    ...state,
    modalConfig: { ...config[action.payload.option], id: action.payload.id }
  };
};

const getNewsCathalogs = (state, action) => {
  return {
    ...state,
    cathalogs: { ...action.payload }
  };
};

const addOrEditNews = (state, action) => {
  return {
    ...state,
    redirect: true
  };
};

const getNewsToEdit = (state, action) => {
  return {
    ...state,
    toEdit: { ...action.payload.toEdit },
    cathalogs: { ...action.payload.cath },
    redirect: false
  };
};

const doActions = (state, action) => {
  let status = { activate: "Eliminado", delete: "Activo" };
  let promoteStatus = { promote: true, unlink: false };
  if (status[action.payload.action]) {
    return {
      ...state,
      news: state.news.map(_ => {
        if (_.id === action.payload.eid) {
          let temp = { ..._ };
          temp.status_name = status[action.payload.action];
          if (action.payload.action === "delete") {
            temp.promoted = false;
          }
          return temp;
        }
        return _;
      }),
      modalConfig: config.default
    };
  }
  if (Object.keys(promoteStatus).includes(action.payload.action)) {
    const newList = state.news.map(_ => {
      if (_.id === action.payload.eid) {
        let temp = { ..._ };
        temp.promoted = promoteStatus[action.payload.action];
        return temp;
      }
      return _;
    });
    const promoted = newList.filter(_ => _.promoted);
    const unpromoted = newList.filter(_ => !_.promoted);
    return {
      ...state,
      news: [...promoted, ...unpromoted],
      countPromotes: promoteStatus[action.payload.action]
        ? state.countPromotes + 1
        : state.countPromotes - 1,
      modalConfig: config.default
    };
  }
  return { ...state };
};

export default (state, action) => {
  const actions = {
    GET_NEWS: getNews,
    SET_MODAL_OPTION: SetModalOption,
    GET_NEWS_CATHALOGS: getNewsCathalogs,
    GET_NEWS_TO_EDIT: getNewsToEdit,
    ADD_NEWS: addOrEditNews,
    EDIT_NEWS: addOrEditNews,
    DO_ACTIONS: doActions
  };
  return actions[action.type](state, action) || state;
};
