import React from "react";
import Components from "./components";
import Providers from "../context/providers";
import { Navigation } from "../components/commons/navigation";

const settings = {
  genres: () => (
    <Providers.GenresProvider>
      <Components.Genres />
    </Providers.GenresProvider>
  ),
  content: props => (
    <Providers.ContentProvider>
      <Components.Content {...props} />
    </Providers.ContentProvider>
  ),
  news: props => (
    <Providers.NewsProvider>
      <Components.News {...props} />
    </Providers.NewsProvider>
  ),
  artist: props => (
    <Providers.ArtistProvider>
      <Components.Artist {...props} />
    </Providers.ArtistProvider>
  ),
  promoted: props => (
    <Providers.PromotedProvider>
      <Components.Promoted {...props} />
    </Providers.PromotedProvider>
  ),
  addArtist: () => (
    <Providers.ArtistProvider>
      <Components.ArtistAdd />
    </Providers.ArtistProvider>
  ),
  addNews: () => (
    <Providers.NewsProvider>
      <Components.NewsAdd />
    </Providers.NewsProvider>
  ),
  addContent: () => (
    <Providers.ContentProvider>
      <Components.ContentAdd />
    </Providers.ContentProvider>
  ),
  editArtist: props => (
    <Providers.ArtistProvider>
      <Components.ArtistEdit {...props} />
    </Providers.ArtistProvider>
  ),
  editNews: props => (
    <Providers.NewsProvider>
      <Components.NewsEdit {...props} />
    </Providers.NewsProvider>
  ),
  editContent: props => (
    <Providers.ContentProvider>
      <Components.ContentEdit {...props} />
    </Providers.ContentProvider>
  ),
  login: props => (
    <Providers.AuthProvider>
      <Components.Login {...props} />
    </Providers.AuthProvider>
  )
};

export const Index = props => {
  let { name } = props;
  return (
    <>
      {name !== "login" && (
        <Providers.AuthProvider>
          <Navigation {...props} />
        </Providers.AuthProvider>
      )}
      {settings[name](props)}
    </>
  );
};
