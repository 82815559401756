import React, { useContext, useEffect, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { HeaderLogin } from "../landing-page/components/header.login";
import { Footer } from "../landing-page/components/footer";
import { ArtistContext } from "../../../context/site/artist/artist.state";
import { serverPath } from "../../../context/utils/file.path";

import instagram from "../../../images/ig_icon.png";
import facebook from "../../../images/fb_icon.png";
import twitter from "../../../images/tw_icon.png";

import "./artist.css";
import { AudioPlayer } from "./audio.player";
import { SiteAuthProvider } from "../../../context/site/siteAuth/site.auth.state";
import { LandingMusicBox } from "../landing-page/components/landing.music.box";
import ReactHtmlParser from 'react-html-parser'
const urlContents = `${serverPath}artists/`;
const url = `${serverPath}contents/`;

export const ViewArtist = props => {
  const { current, getArtistById, addFavorite, removeFavorite } = useContext(
    ArtistContext
  );
  const [showMusic, setshowMusic] = useState(false);
  const [src, setSrc] = useState("");
  const [currentSong, setCurrentSong] = useState(-1);
  const [currentInfo, setCurrentInfo] = useState(null);

  useEffect(() => {
    getArtistById(props.match.params.aid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  function* getColorBox() {
    let counter = 0;
    const map = ["color-1o", "color-2pl", "color-3pb", "color-4bb"];
    while (true) {
      yield map[counter++];
      if (counter > 3) {
        counter = 0;
      }
    }
  }
  const gen = getColorBox();
  const handleToogleClick = (show, content) => {
    setshowMusic(show);
    setSrc(content.content);
    setCurrentInfo(content)
  };
  const handleClose = () => {
    setCurrentSong(-1);
    setCurrentInfo(null)
  };
  if (Object.keys(current).length > 0) {
    return (
      <>
        {showMusic && (
          <LandingMusicBox
            showMusic={setshowMusic}
            src={`${url}${src}`}
            onClose={handleClose}
            info={currentInfo}
          />
        )}
        <SiteAuthProvider>
          <HeaderLogin />
        </SiteAuthProvider>
        <div className="single-news">
          <Container style={{paddingTop: "20px" }}>
            <Row className="ml-1 mr-1">
              <Col
                lg={{ span: 6, offset: 1 }}
                className="mt-4 mb-4"
                // style={{ minHeight: "50vh", maxHeight: "50vh" }}
              >
                <div>
                  {/* <img
                    src={`${urlContents}${current.picture}`}
                    // src={imageTest1}
                    className="w-100 h-100"
                    style={{ minHeight: "40vh", maxHeight: "40vh" }}
                    alt="artista"
                  /> */}
                  <div style={{width: "100%", backgroundImage: `url("${urlContents}${current.picture}")`, backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }} className="art-picture"/>
                  <span
                    style={{
                      fontFamily: '"Montserrat", sans-serif',
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "18pt"
                    }}
                  >
                    {current.name}
                  </span>
                  <p
                    style={{
                      fontFamily: '"Montserrat", sans-serif',
                      color: "black",
                      textAlign: "justify",
                      fontSize: "8pt",
                      lineHeight: "1.4"
                    }}
                  >
                    {ReactHtmlParser(current.biography)}
                  </p>
                </div>
              </Col>
              <Col lg="4" className="mt-4 mb-4" style={{ minHeight: "35vh" }}>
                {current.interview && (
                  <>
                    <Row className="mr-2 mt-0 pt-0">
                      <Col
                        lg={{ span: 4, offset: 8 }}
                        className="mb-3 mt-0 pt-0"
                      >
                        <span
                          style={{
                            fontFamily: '"Montserrat", sans-serif',
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "14pt"
                          }}
                        >
                          Entrevista
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12" className="mt-2">
                        {/* <img
                          src={`${urlContents}${current.picture}`}
                          //   src={imageTest1}
                          className="w-100 h-100"
                          style={{ minHeight: "30vh", maxHeight: "30vh" }}
                        /> */}
                        <video
                          controls
                          name="media"
                          style={{ height: "auto", width: "100%" }}
                          controlsList="nodownload"
                        >
                          <source src={`${urlContents}${current.interview}`} />
                        </video>
                      </Col>
                    </Row>
                  </>
                )}
                <Row>
                  <Col lg="12" className="mt-4 mb-4">
                    <div className="d-flex justify-content-between align-items-center w-100 h-100 pl-2 pr-2">
                      {current.facebook && (
                        <a href={`${current.facebook}`}>
                          <img
                            src={facebook}
                            width="34"
                            height="34"
                            alt="facebook"
                          />
                        </a>
                      )}
                      {current.twitter && (
                        <a href={`${current.twitter}`}>
                          <img
                            src={twitter}
                            width="34"
                            height="34"
                            alt="instagram"
                          />
                        </a>
                      )}
                      {current.instagram && (
                        <a href={`${current.instagram}`}>
                          <img
                            src={instagram}
                            width="34"
                            height="34"
                            alt="twitter"
                          />
                        </a>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row className="mr-2 mt-0 pt-0">
                  <Col lg={{ span: 4, offset: 8 }} className="mb-3 mt-0 pt-0">
                    <span
                      style={{
                        fontFamily: '"Montserrat", sans-serif',
                        color: "black",
                        fontWeight: "bold",
                        fontSize: "14pt"
                      }}
                    >
                      Canciones
                    </span>
                  </Col>
                </Row>
                {current.contents.map((it, index) => {
                  return (
                    <Row className="mt-0 pt-0" key={index}>
                      <Col lg="12" className="mb-1 mt-0 pt-0">
                        <AudioPlayer
                          content={it}
                          handleClick={handleToogleClick}
                          name={it.name}
                          color={gen.next().value}
                          current={currentSong}
                          id={it.id}
                          setSong={setCurrentSong}
                          addFavorite={addFavorite}
                          removeFavorite={removeFavorite}
                          isFavorite={it.is_favorite}
                        />
                      </Col>
                    </Row>
                  );
                })}
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </>
    );
  }
  return <></>;
};
