// import React from 'react'
import axios from "axios";
import { serverPath } from "../utils/file.path";
// import history from '../routes/history';
// history.push('/login');

const httpRequest = async service => {
  const headers = { "Content-Type": "application/x-www-form-urlencoded" };
  const server = `${serverPath}v1/`;
  if (service instanceof Array) {
    let promiseAll = service.map(_ => {
      const data = openTokenData(_.data, _.url);
      return {
        method: "post",
        url: `${server}${_.url}`,
        data: data || {},
        headers
      };
    });
    const response = await axios.all(promiseAll.map(_ => axios(_)));
    const tkExp = response.some(_ => _.data.cod === 121);
    if (tkExp) {
      localStorage.removeItem("inbeat");
      // history.push('/');
    }
    return response;
  }
  const data = openTokenData(service.data, service.url);
  const response = await axios({
    method: "post",
    url: `${server}${service.url}`,
    data: data || {},
    headers
  });
  if (response.data.cod === 121) {
    localStorage.removeItem("inbeat");
    // history.push('/');
  }
  return response;
};

const openTokenData = (data, endpoint) => {
  const excludesTokenRoutes = [
    "security/do_login",
    "security/request_user_password_recovery",
    "security/password_recovery"
  ];
  if (!data) {
    data = new FormData();
  }
  if (!excludesTokenRoutes.includes(endpoint)) {
    const token = JSON.parse(localStorage.getItem("inbeat"))
      ? JSON.parse(localStorage.getItem("inbeat")).token
      : null;
    data.append("token", token);
  }
  return data;
};

export default httpRequest;
