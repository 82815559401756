import React from "react";
import { PromotedCard } from "./promoted.card";
import { Row } from "react-bootstrap";

const componentStyle = {
  box: { minHeight: "10vh", border: "1px solid #d2cdcd", marginBottom: ".5rem" }
};

export const PromotedList = props => {
  const { promotions, handleShowModal } = props;
  const { box } = componentStyle;
  if (!promotions.length) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Row>
          <h6 style={{ textAlign: "justify" }} className="p-4 text-muted">
            No existen contenidos promocionados. Recuerde que esto afecta la
            visualización de la página principal del sitio.
          </h6>
        </Row>
      </div>
    );
  }
  return (
    <>
      {promotions.map((content, index) => {
        return (
          <Row style={box} key={content.id}>
            <PromotedCard {...content} showModal={handleShowModal} />
          </Row>
        );
      })}
    </>
  );
};
