import React from "react";
import ReactLoading from "react-loading";

export const Loading = () => {
  return (
    <div
      className="p-3 d-flex align-items-center justify-content-center mb-5"
      style={{ height: "50vh" }}
    >
      <ReactLoading
        type="spin"
        color="#9e9e9e"
        height={`${9}%`}
        width={`${9}%`}
        className="mr-1 mb-5"
      />
    </div>
  );
};
