import React, { createContext, useReducer } from "react";
import NewsReducer from "./news.reducer";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useModal } from "../../hooks/useModal";
import { useNotification } from "../../hooks/useNotification";
import { httpRequest } from "../utils/http";

const initialState = {
  news: [],
  redirect: false,
  toEdit: {},
  modalConfig: {
    type: "confirmation",
    mainQuestion: "",
    description: "",
    action: "idle"
  },
  cathalogs: {},
  countPromotes: 0
};

const notificationsMessages = {
  error: {
    msg: "Error. Oopps! Algo salió mal!",
    status: "dark"
  },
  activate: {
    msg: "Éxito. La noticia se ha eliminado satisfactoriamente!",
    status: "success"
  },
  delete: {
    msg: "Éxito. La noticia se ha activado satisfactoriamente!",
    status: "success"
  },
  unlink: {
    msg: "Éxito. La noticia ha dejado de promocionarse satisfactoriamente!",
    status: "success"
  },
  promote: {
    msg: "Éxito. La noticia ha sido promocionada satisfactoriamente!",
    status: "success"
  }
};

export const NewsContext = createContext(initialState);

export const NewsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(NewsReducer, initialState);
  const { execute, loading, error } = useHttpRequest(httpRequest, false);
  const { showModal, show } = useModal();
  const { showNotification, notify, notificationConfig } = useNotification();

  const getNews = async msg => {
    try {
      let result = await execute({ url: "admin/get_news" });
      if (msg) {
        showNotification({ msg, status: "success" });
      }
      dispatch({
        type: "GET_NEWS",
        payload: result.data
      });
    } catch (e) {
      showNotification(notificationsMessages.error);
    }
  };

  const showModalWithConfig = (option, id) => {
    dispatch({
      type: "SET_MODAL_OPTION",
      payload: { option, id }
    });
    showModal(option, id);
  };

  const getCathalogs = async () => {
    try {
      const result = await execute({ url: "cathalogs/get" });
      let cath = {
        status: result.data.news_status
      };
      dispatch({
        type: "GET_NEWS_CATHALOGS",
        payload: cath
      });
    } catch (error) {
      showNotification(notificationsMessages.error);
    }
  };

  const editNews = async news => {
    try {
      showNotification();
      let formData = new FormData();
      formData.append("news_id", news.id);
      formData.append("title", news.title);
      formData.append("summary", news.summary);
      formData.append("body", news.body);
      formData.append("status_id", news.status_id);
      formData.append("news_date", news.date)
      if (Object.keys(news).includes("image")) {
        formData.append("file", news.image);
      }
      await execute({
        url: "news/modify",
        data: formData
      });
      dispatch({
        type: "EDIT_NEWS"
      });
    } catch (error) {
      showNotification(notificationsMessages.error);
    }
  };

  const addNews = async news => {
    try {
      showNotification();
      let formData = new FormData();
      formData.append("title", news.title);
      formData.append("summary", news.summary);
      formData.append("body", news.body);
      formData.append("status_id", news.status_id);
      formData.append("file", news.image);
      formData.append("news_date", news.date)
      await execute({
        url: "news/create",
        data: formData
      });
      dispatch({
        type: "ADD_NEWS"
      });
    } catch (error) {
      showNotification(notificationsMessages.error);
    }
  };
  const getNewsById = async nid => {
    try {
      let formData = new FormData();
      formData.append("news_id", nid);
      const allData = await execute([
        { url: "cathalogs/get" },
        {
          url: "admin/get_news_by_id",
          data: formData
        }
      ]);

      let [cathalog, newsToEdit] = allData;
      let cath = {
        status: cathalog.data.data.news_status
      };
      dispatch({
        type: "GET_NEWS_TO_EDIT",
        payload: {
          cath,
          toEdit: { ...newsToEdit.data.data }
        }
      });
    } catch (error) {
      showNotification(notificationsMessages.error);
    }
  };
  const doActions = async () => {
    try {
      showNotification();
      let formData = new FormData();
      formData.append("news_id", state.modalConfig.id);
      const setPromoted = state.modalConfig.action === "promote" ? true : false;
      formData.append("promoted", setPromoted);
      const endpoint = {
        delete: "news/activate",
        activate: "news/delete",
        promote: "news/set_promotion",
        unlink: "news/set_promotion"
      };
      showModal();
      await execute({
        url: endpoint[state.modalConfig.action],
        data: formData
      });
      dispatch({
        type: "DO_ACTIONS",
        payload: { eid: state.modalConfig.id, action: state.modalConfig.action }
      });
      showNotification(notificationsMessages[state.modalConfig.action]);
    } catch (e) {
      showModal();
      showNotification(notificationsMessages.error);
    }
  };
  return (
    <NewsContext.Provider
      value={{
        news: state.news,
        isLoading: loading,
        redirect: state.redirect,
        toEdit: state.toEdit,
        modalConfig: state.modalConfig,
        cathalogs: state.cathalogs,
        countPromotes: state.countPromotes,
        notify,
        notificationConfig,
        show,
        error,
        getNews,
        showModal,
        showNotification,
        showModalWithConfig,
        doActions,
        getCathalogs,
        addNews,
        getNewsById,
        editNews
      }}
    >
      {children}
    </NewsContext.Provider>
  );
};
