import React from "react";
import { Card, Badge, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faTrashRestoreAlt } from "@fortawesome/free-solid-svg-icons";

export const GenresCard = ({ name, date, status, showModal, id }) => {
  const icon = status === "Activo" ? faTrash : faTrashRestoreAlt;
  const iconColor = status === "Activo" ? "dark" : "info";
  const badgeColor = status === "Activo" ? "info" : "dark";
  return (
    <Col lg={3} className="mb-1">
      <Card className="ml-4 mr-4">
        <Card.Body>
          <div className="d-flex justify-content-between">
            <div>
              <Card.Subtitle className="mb-2">
                {name}
                <small className="text-muted">
                  - {date.toLocaleDateString()}
                </small>
              </Card.Subtitle>
              <Badge variant={badgeColor}>{status}</Badge>
            </div>
            <div>
              <FontAwesomeIcon
                className={`text-${iconColor}`}
                icon={icon}
                title={status === "Activo" ? "Eliminar" : "Activar"}
                onClick={() => {
                  let action = status === "Activo" ? "delete" : "activate";
                  showModal(action, id);
                }}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};
