import React, { useContext } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import logo from "../../../images/logo_INBEAT_Color.png";
import iconGmial from "../../../images/GMAIL.png";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import "./site.login.css";
import { Formik } from "formik";
import * as Yup from "yup";
import { SiteAuthContext } from "../../../context/site/siteAuth/site.auth.state";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("El nombre es requerido"),
  email: Yup.string()
    .email("Debe introducir un correo electrónico válido")
    .required("El correo es un campo requerido"),
  password: Yup.string().required("Este campo es obligatorio"),
  rpassword: Yup.string()
    .when("password", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Las contraseñas deben coincidir"
      )
    })
    .required("Este campo es obligatorio")
});

export const SiteRegister = props => {
  const {
    registered,
    siteRegister,
    registerError,
    registerErrorMsg,
    googleRegister,
    facebookRegister
  } = useContext(SiteAuthContext);

  const handleSiteRegister = async (values, setSubmitting, resetForm) => {
    const { token } = props.match.params;
    const { email, name, password } = values;
    await siteRegister({ email, name, password, token });
    setSubmitting(false);
    resetForm({
      name: "",
      email: "",
      password: "",
      rpassword: ""
    });
  };

  const responseGoogle = response => {
    const { token } = props.match.params;
    const { email, googleId, givenName } = response.profileObj;
    googleRegister({ email, name: givenName, token, third_party_id: googleId });
  };
  const responseFacebook = response => {
    const { token } = props.match.params;
    const { name, userID } = response;
    facebookRegister({ name, token, third_party_id: userID });
  };
  return (
    <>
      <Row>
        <Col
          lg="6"
          style={{
            minHeight: "100vh",
            maxHeight: "100vh",
            backgroundColor: "#7b2a7c"
          }}
          className="m-0 p-0"
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              paddingLeft: "5vw",
              paddingRight: "5vw"
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <Formik
              initialValues={{
                name: "",
                email: "",
                password: "",
                rpassword: ""
              }}
              validationSchema={validationSchema}
              onSubmit={
                (values, { setSubmitting, resetForm }) => {
                  //   login(values, setSubmitting, resetForm)
                  setSubmitting(true);

                  handleSiteRegister(values, setSubmitting, resetForm);
                }
                // console.log(values)
              }
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                resetForm
              }) => (
                <Form onSubmit={handleSubmit} method="post">
                  {registered && !registerError && (
                    <Form.Text
                      className="mt-4 pt-1 mb-4 pb-1"
                      style={{ color: "white", fontSize: "12pt" }}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <span>
                          REGISTRO EXITOSO. Ahora puedes ingresar al sitio.
                          <a
                            href="#/site-login"
                            style={{ color: "#df544f" }}
                            className="ml-2"
                          >
                            INICIAR SESIÓN
                          </a>
                        </span>
                      </div>
                    </Form.Text>
                  )}
                  {registerError && (
                    <Form.Text
                      className="mt-4 pt-1 mb-4 pb-1"
                      style={{ color: "white", fontSize: "8pt" }}
                    >
                      <div className="d-flex justify-content-center align-items-center">
                        <span>{registerErrorMsg}</span>
                      </div>
                    </Form.Text>
                  )}
                  <Form.Group
                    controlId="formBasicEmail"
                    style={{ minWidth: "25vw" }}
                  >
                    <Form.Label style={{ color: "#C3C0BF", fontSize: "14pt" }}>
                      NOMBRE
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Nombre completo"
                      style={{
                        borderColor: "#606263",
                        backgroundColor: "transparent",
                        color: "white"
                      }}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.name && !errors.name}
                      isInvalid={touched.name && !!errors.name}
                      autoComplete="off"
                    />
                    <Form.Control.Feedback>
                      Entrada correcta!
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    controlId="formBasicEmail"
                    style={{ minWidth: "25vw" }}
                  >
                    <Form.Label style={{ color: "#C3C0BF", fontSize: "14pt" }}>
                      CORREO ELECTRÓNICO
                    </Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Correo electrónico"
                      style={{
                        borderColor: "#606263",
                        backgroundColor: "transparent",
                        color: "white"
                      }}
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.email && !errors.email}
                      isInvalid={touched.email && !!errors.email}
                      autoComplete="off"
                    />
                    <Form.Control.Feedback>
                      Entrada correcta!
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword" className="">
                    <Form.Label style={{ color: "#C3C0BF", fontSize: "14pt" }}>
                      PASSWORD
                    </Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder="Password"
                      style={{
                        borderColor: "#606263",
                        backgroundColor: "transparent",
                        color: "white"
                      }}
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.password && !errors.password}
                      isInvalid={touched.password && !!errors.password}
                      autoComplete="off"
                    />
                    <Form.Control.Feedback>
                      Entrada correcta!
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword" className="">
                    <Form.Label style={{ color: "#C3C0BF", fontSize: "14pt" }}>
                      CONFIRMAR PASSWORD
                    </Form.Label>
                    <Form.Control
                      type="password"
                      name="rpassword"
                      placeholder="Repita el password"
                      style={{
                        borderColor: "#606263",
                        backgroundColor: "transparent",
                        color: "white"
                      }}
                      value={values.rpassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.rpassword && !errors.rpassword}
                      isInvalid={touched.rpassword && !!errors.rpassword}
                      autoComplete="off"
                    />
                    <Form.Control.Feedback>
                      Entrada correcta!
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.rpassword}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Button
                    type="submit"
                    block
                    className="mt-4 box-orange"
                    disabled={isSubmitting}
                  >
                    CREAR CUENTA
                  </Button>
                  <Form.Text
                    className="mt-4 pt-1 mb-1 pb-1"
                    style={{ color: "#C3C0BF", fontSize: "14pt" }}
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <span> O regístrate con</span>
                    </div>
                  </Form.Text>
                  <Form.Text
                    className="mt-0 pt-0 mb-2 pb-0"
                    style={{ color: "#C3C0BF", fontSize: "14pt" }}
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <GoogleLogin
                        clientId="536600311982-21j7nlfal3671m21io0956mckmdb5d97.apps.googleusercontent.com"
                        render={renderProps => (
                          <img
                            src={iconGmial}
                            width="40vw"
                            height="40vh"
                            className="mr-4"
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            style={{ cursor: "pointer" }}
                            title="Login con Google"
                            alt="google"
                          />
                        )}
                        buttonText="Login"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={"single_host_origin"}
                      />
                      <FacebookLogin
                        appId="680389002606632"
                        autoLoad={false}
                        callback={responseFacebook}
                        cssClass="my-facebook-button-class"
                        textButton=""
                        icon="fa-facebook"
                        disableMobileRedirect={true}
                      />
                    </div>
                  </Form.Text>
                  <Form.Text
                    className=""
                    style={{ color: "white", fontSize: "10pt" }}
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <a
                        href="#/site-login"
                        style={{ color: "white" }}
                        className=""
                      >
                        INICIAR SESIÓN
                      </a>
                    </div>
                  </Form.Text>
                  <Form.Text
                    className="mb-1 d-flex justify-content-center align-items-center"
                    style={{ color: "#C3C0BF" }}
                  >
                    <span style={{ color: "#C3C0BF", fontSize: "6pt" }}>
                      Si presenta problemas con su registro o inicio de sesión.
                    </span>
                  </Form.Text>
                  <Form.Text
                    className="d-flex justify-content-center align-items-center"
                    style={{ color: "#C3C0BF" }}
                  >
                    <span style={{ color: "#C3C0BF", fontSize: "6pt" }}>
                      Contáctenos a soporte@inbeat.live
                    </span>
                  </Form.Text>
                  <Form.Text
                  className="d-flex justify-content-center align-items-center"
                  style={{ color: "#C3C0BF" }}
                >
                <p style={{ color: "#C3C0BF", fontSize: "6pt" }}> 
                  <a href="#/terms-conditions" style={{color:"white"}}> Términos y Condiciones</a>
                </p>   
                </Form.Text>
                </Form>
              )}
            </Formik>
          </div>
        </Col>
        <Col
          lg="6"
          style={{
            minHeight: "100vh",
            maxHeight: "100vh",
            backgroundColor: "#e5e5e5"
          }}
          className="m-0 p-0"
        >
          <div
            style={{ height: "100%" }}
            className="d-flex justify-content-center align-items-center"
          >
            <img src={logo} height="100vh" width="40%" alt="INBEAT" />
          </div>
        </Col>
      </Row>
    </>
  );
};
