import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouseDamage } from "@fortawesome/free-solid-svg-icons";

export const NotFound = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center mb-5 mt-0"
      style={{ height: "80vh" }}
    >
      <FontAwesomeIcon
        className="login-icon login-items mr-4 text-secondary"
        icon={faHouseDamage}
      />
      <h1 className="login-items text-secondary">Opps! Página no encontrada</h1>
    </div>
  );
};
