import React, { useEffect, useContext, useState } from "react";
import { HeaderLogin } from "../landing-page/components/header.login";
import { Footer } from "../landing-page/components/footer";
import { PlayListTitle } from "./play.list.title";

import { PlayListBox } from "./play.list.box";

import { PlayListActions } from "./play.list.actions";
import { SiteAuthProvider } from "../../../context/site/siteAuth/site.auth.state";
import { PlayListMusicBox } from "./play.list.music.box";

import "../artist/artist.css";
import { PlayListContext } from "../../../context/site/playlist/playlist.state";

export const PlayList = () => {
  const {
    playlists,
    playListForm,
    getPlayLists,
    hidePlayListAction,
    showPlayListAction,
    addPlayList,
    removeContentFromPlayList,
    removePlayList,
    showPlayListEdit
  } = useContext(PlayListContext);

  useEffect(() => {
    getPlayLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [currentPlayList, setCurrentPlayList] = useState(null);
  const [showMusic, setshowMusic] = useState(false);
  const [currentIndexSong, setCurrentIndexSong] = useState(-1);
  const [globalPlay, setGlobalPlay] = useState(false);
  const handleAddPlayList = () => {
    if (playListForm.show === true) {
      hidePlayListAction();
    }
    if (playListForm.show === false) {
      showPlayListAction("add");
    }
  };
  const handleHide = () => {
    hidePlayListAction();
  };
  const handleNewPlayList = name => {
    addPlayList(name);
    hidePlayListAction();
  };
  const handleRemoveContent = (pid, cid) => {
    removeContentFromPlayList(pid, cid);
  };
  const handleRemovePlayList = plid => {
    removePlayList(plid);
  };
  const handleShowPlayListEdit = (plid, name) => {
    showPlayListEdit(plid, name);
  };
  const handlePlayList = content => {
    setCurrentPlayList({ ...content });
  };
  function* getColorBox() {
    let counter = 0;
    const map = [
      "color-5ol",
      "color-4bb",
      "color-3pb",
      "color-2pl",
      "color-1o"
    ];
    while (true) {
      yield map[counter++];
      if (counter > 4) {
        counter = 0;
      }
    }
  }
  const gen = getColorBox();
  const handleClose = () => {
    setGlobalPlay(false);
    handlePlayList({});
    setshowMusic(false);
    setCurrentIndexSong(-1);
  };
  return (
    <>
      <SiteAuthProvider>
        <HeaderLogin />
      </SiteAuthProvider>
      <div className="single-news">
        <PlayListTitle />
        {showMusic && (
          <PlayListMusicBox
            list={currentPlayList}
            showMusic={setshowMusic}
            onClose={handleClose}
            setCurrentIndexSong={setCurrentIndexSong}
            currentIndexSong={currentIndexSong}
          />
        )}
        <PlayListActions
          handleAddPlayList={handleAddPlayList}
          handleHide={handleHide}
          handleNewPlayList={handleNewPlayList}
          playListForm={playListForm}
        />
        {playlists.map(pl => {
          return (
            <PlayListBox
              key={pl.id}
              name={pl.name}
              content={pl.content}
              id={pl.id}
              color={gen.next().value}
              handleRemoveContent={handleRemoveContent}
              handleRemovePlayList={handleRemovePlayList}
              handleShowPlayListEdit={handleShowPlayListEdit}
              playPlayList={handlePlayList}
              setshowMusic={setshowMusic}
              currentIndexSong={currentIndexSong}
              setCurrentIndexSong={setCurrentIndexSong}
              globalPlay={globalPlay}
              setGlobalPlay={setGlobalPlay}
            />
          );
        })}
      </div>
      <Footer />
    </>
  );
};
