import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export const PromotedForm = props => {
  let {
    handleSubmit,
    values,
    touched,
    handleChange,
    handleBlur,
    errors
  } = props;
  return (
    <Form className="mb-4 ml-4" onSubmit={handleSubmit}>
      <Form.Row as={Row} className="">
        <Form.Group as={Col} xs={7}>
          <Form.Control
            name="text"
            type="text"
            placeholder="Filtre por título de noticia, nombre de artista o contenido"
            value={values.text}
            onChange={handleChange}
            onBlur={handleBlur}
            isValid={touched.text && !errors.text}
            isInvalid={touched.text && !!errors.text}
          />
          <Form.Control.Feedback>Entrada correcta!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {errors.text}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} xs={3}>
          <Form.Control
            as="select"
            name="cathegory"
            value={values.cathegory}
            onChange={handleChange}
            onBlur={handleBlur}
            isValid={touched.cathegory && !errors.cathegory}
            isInvalid={touched.cathegory && !!errors.cathegory}
          >
            <option value="">Seleccione Categoría</option>
            <option value="news">Noticias</option>
            <option value="artist">Artistas</option>
            <option value="music">Música</option>
            <option value="video">Video</option>
          </Form.Control>
          <Form.Control.Feedback>Entrada correcta!</Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {errors.cathegory}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} xs={2} className="">
          <Button variant="secondary" type="submit">
            <FontAwesomeIcon icon={faSearch} title="Limpiar" color="white" />
          </Button>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};
