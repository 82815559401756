import React from "react";
import { PlayList } from "../components/public-site/playlist/play.list";
import { PlayListProvider } from "../context/site/playlist/playlist.state";

export const PlayListPage = () => {
  return (
    <PlayListProvider>
      <PlayList />
    </PlayListProvider>
  );
};
