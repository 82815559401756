import React, { useContext } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import logo from "../../../images/logo_INBEAT_Color.png";
import whiteLogo from "../../../images/logo_INbeat_White.png";
import "./site.login.css";
import { Formik } from "formik";
import * as Yup from "yup";
import { SiteAuthContext } from "../../../context/site/siteAuth/site.auth.state";

const validationSchema = Yup.object().shape({
  password: Yup.string().required("Este campo es obligatorio"),
  rpassword: Yup.string()
    .when("password", {
      is: val => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Las contraseñas deben coincidir"
      )
    })
    .required("Este campo es obligatorio")
});

export const RecoveryPass = props => {
  const { change, changeError, changePass } = useContext(SiteAuthContext);

  const handleChangePass = async (values, setSubmitting, resetForm) => {
    const { token } = props.match.params;
    const { password } = values;
    await changePass(password, token);
    setSubmitting(false);
    resetForm({
      password: "",
      rpassword: ""
    });
  };

  return (
    <Row>
      <Col
        lg="6"
        style={{
          minHeight: "100vh",
          maxHeight: "100vh",
          backgroundColor: "#7b2a7c"
        }}
        // className="m-0 p-0"
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            paddingLeft: "5vw",
            paddingRight: "5vw"
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <Formik
            initialValues={{
              password: "",
              rpassword: ""
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setSubmitting(true);

              handleChangePass(values, setSubmitting, resetForm);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <Form onSubmit={handleSubmit} method="post">
                {change && !changeError && (
                  <Form.Text
                    className="mt-4 pt-1 mb-4 pb-1"
                    style={{ color: "white", fontSize: "12pt" }}
                  >
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ maxWidth: "400px" }}
                    >
                      <span>CAMBIO EXITOSO. Ahora puede iniciar sesión.</span>
                      <a
                        href="#/site-login"
                        style={{ color: "#df544f" }}
                        className="ml-2"
                      >
                        INICIAR SESIÓN
                      </a>
                    </div>
                  </Form.Text>
                )}
                {changeError && (
                  <Form.Text
                    className="mt-4 pt-1 mb-4 pb-1"
                    style={{ color: "white", fontSize: "12pt" }}
                  >
                    <div className="d-flex justify-content-center align-items-center">
                      <span>
                        ACTUALIZACIÓN FALLIDA. Por favor inténtelo nuevamente.
                      </span>
                    </div>
                  </Form.Text>
                )}
                <Form.Group controlId="formBasicPassword" className="">
                  <Form.Label style={{ color: "#C3C0BF", fontSize: "14pt" }}>
                    PASSWORD
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Password"
                    style={{
                      borderColor: "#606263",
                      backgroundColor: "transparent",
                      color: "white"
                    }}
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.password && !errors.password}
                    isInvalid={touched.password && !!errors.password}
                    autoComplete="off"
                  />
                  <Form.Control.Feedback>
                    Entrada correcta!
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formBasicPassword" className="">
                  <Form.Label style={{ color: "#C3C0BF", fontSize: "14pt" }}>
                    CONFIRMAR PASSWORD
                  </Form.Label>
                  <Form.Control
                    type="password"
                    name="rpassword"
                    placeholder="Repita el password"
                    style={{
                      borderColor: "#606263",
                      backgroundColor: "transparent",
                      color: "white"
                    }}
                    value={values.rpassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rpassword && !errors.rpassword}
                    isInvalid={touched.rpassword && !!errors.rpassword}
                    autoComplete="off"
                  />
                  <Form.Control.Feedback>
                    Entrada correcta!
                  </Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                    {errors.rpassword}
                  </Form.Control.Feedback>
                </Form.Group>
                <Button
                  type="submit"
                  block
                  className="mt-4 box-orange"
                  disabled={isSubmitting}
                >
                  CAMBIAR CONTRASEÑA
                </Button>
                <Form.Text className="mt-4 pt-4">
                  <div className="d-flex justify-content-center align-items-center">
                    <img src={whiteLogo} height="45" width="105" alt="INBEAT" />
                  </div>
                </Form.Text>
              </Form>
            )}
          </Formik>
        </div>
      </Col>
      <Col
        lg="6"
        style={{
          minHeight: "100vh",
          maxHeight: "100vh",
          backgroundColor: "#e5e5e5"
        }}
        className="m-0 p-0"
      >
        <div
          style={{ height: "100%" }}
          className="d-flex justify-content-center align-items-center"
        >
          <img src={logo} height="100vh" width="40%" alt="INBEAT" />
        </div>
      </Col>
    </Row>
  );
};
