import React, { useContext, useEffect } from "react";
import { PageTitle } from "../commons/page.title";
import { Notification } from "../commons/notification";
import LoadingPage from "../commons/loading.page";
import { Row } from "react-bootstrap";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { ContentContext } from "../../context/content/content.state";
import { Formik } from "formik";
import { Redirect } from "react-router-dom";
import * as EditSchema from "./content.schema";
import { ContentForm } from "./content.form";

const LoadingEmptyComponent = LoadingPage(<></>);
export const ContentEdit = props => {
  const {
    isLoading,
    editContent,
    notify,
    showNotification,
    notificationConfig,
    redirect,
    getContentById,
    toEdit,
    cathalogs
  } = useContext(ContentContext);
  const { cid } = props.match.params;
  useEffect(() => {
    getContentById(cid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let redirectComponent = (
    <Redirect
      to={{
        pathname: "/content",
        state: {
          show: true,
          message: "Exito! El contenido se ha editado",
          status: "success"
        }
      }}
    ></Redirect>
  );

  if (redirect) {
    return redirectComponent;
  }

  if (isLoading === true) {
    return <LoadingEmptyComponent data={isLoading} />;
  }
  if (Object.keys(toEdit).length > 0 && isLoading === false) {
    let initialValues = EditSchema.initialValues;
    initialValues = {
      ...toEdit,
      artist: toEdit.artist_id
    };
    initialValues.file = "";
    initialValues.image = "";
    initialValues.preview = "";
    return (
      <>
        <Row>
          <Notification
            notify={notify}
            notificationConfig={notificationConfig}
            showNotification={showNotification}
          />
        </Row>
        <PageTitle title="Editar Contenido" />
        <Formik
          validationSchema={EditSchema.editSchema}
          onSubmit={values => {
            if (!values.file && values.content_type) {
              values.content_type = "";
            }
            const editedContent = {
              id: values.id,
              name: values.name,
              artist: values.artist,
              genres: values.genres
            };
            if (values.content_type) {
              editedContent.content_type = values.content_type;
            }
            if (values.file) {
              editedContent.file = values.file;
            }
            if (values.image) {
              editedContent.image = values.image;
            }
            if (values.preview) {
              editedContent.preview = values.preview;
            }
            editContent(editedContent);
          }}
          initialValues={initialValues}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            setFieldValue,
            setFieldTouched
          }) => (
            <ContentForm
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              handleBlur={handleBlur}
              values={values}
              touched={touched}
              errors={errors}
              setFieldTouched={setFieldTouched}
              setFieldValue={setFieldValue}
              btnTxt="GUARDAR CAMBIOS"
              btnIcon={faSave}
              cathalogs={cathalogs}
              action
            />
          )}
        </Formik>
      </>
    );
  }
  return (
    <Row>
      <Notification
        notify={notify}
        notificationConfig={notificationConfig}
        showNotification={showNotification}
      />
    </Row>
  );
};
