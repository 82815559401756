import React, { useContext, useEffect } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { HeaderLogin } from "../landing-page/components/header.login";
import { Footer } from "../landing-page/components/footer";

import instagram from "../../../images/ig_icon.png";
import facebook from "../../../images/fb_icon.png";
import twitter from "../../../images/tw_icon.png";
import { SiteAuthProvider } from "../../../context/site/siteAuth/site.auth.state";

import { VideoContext } from "../../../context/site/video/video.state";
import { serverPath } from "../../../context/utils/file.path";
const urlContents = `${serverPath}contents/`;
const urlArtists = `${serverPath}artists/`;

export const PlayVideo = props => {
  const { current, getVideoById } = useContext(VideoContext);

  useEffect(() => {
    getVideoById(props.match.params.vid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (Object.keys(current).length > 0) {
    return (
      <>
        <SiteAuthProvider>
          <HeaderLogin />
        </SiteAuthProvider>
        <div className="top-rated" style={{ backgroundColor: "#14123c" }}>
          <Row className="ml-1 mr-1">
            <Col
              lg={{ span: 9, offset: 2 }}
              xs={{ span: 12, offset: 0 }}
              className="mt-0 video-play-size pt-4"
            >
              <div className="w-100 pos-relative">
                <video
                  controls
                  controlsList="nodownload"
                  name="media"
                  width="100%"
                  height="auto"
                  // style={{ paddingRight: "30pt" }}
                >
                  <source src={`${urlContents}${current.content}`} />
                </video>
              </div>
            </Col>
          </Row>
          <Row className="ml-1 mr-1 video-footer">
            <Col
              lg={{ span: 9, offset: 2 }}
              xs={{ span: 10, offset: 0 }}
              className="mt-4 mb-2"
            >
              <span
                style={{
                  fontFamily: "Monserrat",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "20pt"
                }}
              >
                {current.artist_name} - {current.name}
              </span>
            </Col>
          </Row>
          <Row className="ml-4 mr-1">
            <Col
              lg={{ span: 6, offset: 2 }}
              className="mt-2 mb-4"
              style={{
                borderTop: "1.5pt white solid",
                borderBottom: "1.5pt white solid",
                minHeight: "20vh",
                maxHeight: "20vh"
              }}
            >
              <div className="d-flex justify-content-between align-items-center w-100 h-100">
                <Image
                  src={`${urlArtists}${current.artist_image}`}
                  width="80"
                  height="80"
                  className="ml-1"
                  roundedCircle
                />
                <div className="pt-4 mr-4">
                  <p
                    style={{
                      fontFamily: "Monserrat",
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "16pt"
                    }}
                    className="p-0 m-0 ml-1"
                  >
                    {current.artist_name}
                  </p>
                  <p
                    style={{
                      fontFamily: "Monserrat",
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "12pt"
                    }}
                    className="ml-1"
                  >
                    {current.name}
                  </p>
                </div>
                <a href={`https://facebook.com/${current.facebook}`}>
                  <img
                    src={facebook}
                    width="30"
                    height="30"
                    alt="facebook"
                    className="mr-1"
                  />
                </a>
                <a href={`https://twitter.com/${current.instagram}`}>
                  <img
                    src={twitter}
                    width="30"
                    height="30"
                    alt="instagram"
                    className="mr-1"
                  />
                </a>
                <a href={`https://www.instagram.com/${current.twitter}`}>
                  <img
                    src={instagram}
                    width="30"
                    height="30"
                    alt="twitter"
                    className="mr-1"
                  />
                </a>
              </div>
            </Col>
          </Row>
        </div>
        <Footer />
      </>
    );
  }
  return <></>;
};
