import React from "react";
import { Route, Redirect } from "react-router-dom";

export const PublicRoutes = ({ component: Component, ...rest }) => {
  const storage = JSON.parse(localStorage.getItem("inbeat"));
  const isAuthenticated = storage && storage.login;
  return (
    <Route
      {...rest}
      component={props => {
        if (!isAuthenticated) {
          return <Component {...props} />;
        }
        if (isAuthenticated) {
          return <Redirect to="/genres" />;
        }
      }}
    />
  );
};
