import React from "react";
import { Col, Card } from "react-bootstrap";
import { ArtistCardHeader } from "./artist.card.header";
import { ArtistCardContent } from "./artist.card.content";
import { ArtistCardFooter } from "./artist.card.footer";

export const ArtistCard = props => {
  return (
    <Col lg={6} className="mb-1">
      <Card className="ml-4 mr-4">
        <Card.Body style={{ padding: ".3rem" }}>
          <ArtistCardHeader
            status={props.status}
            created_at={props.created_at}
            id={props.id}
            showModal={props.showModal}
          />
          <ArtistCardContent
            picture={props.picture}
            facebook={props.facebook}
            twitter={props.twitter}
            instagram={props.instagram}
            name={props.name}
          />
          <hr className="m-0 mt-1 mb-2" />
          <ArtistCardFooter
            contents={props.contents}
            biography={props.biography}
            interview={props.interview}
          />
        </Card.Body>
      </Card>
    </Col>
  );
};
