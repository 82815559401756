import React from "react";
import "./no.auth.css";
import { Container } from "react-bootstrap";
import { Header } from "./components/header";
import { MainContent } from "./components/main.content";
import whiteLogo from "../../../images/logo_INbeat_White.png";

export const NoAuth = () => {
  return (
    <Container fluid className="p-0">
      <Header />
      <MainContent />
      {/* <Footer /> */}
      <div
        className="footer"
        // style={{ height: "fit-content", minHeight: "auto" }}
      >
        <div style={{ height: "fit-content" }} className="footer-icon-pd">
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ paddingTop: "5%", paddingBottom: "5%" }}
          >
            <p>
              <img src={whiteLogo} height="65" width="150" alt="INBEAT" />
            </p>
          </div>
        </div>
        <Container></Container>
      </div>
    </Container>
  );
};
