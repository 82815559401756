import React from "react";
import { TermsConditions } from "../components/public-site/landing-page/terms.conditions";

export const PageTermsConditions = () => {
  return (
    <div>
      <TermsConditions />
    </div>
  );
};
