import React from "react";
import { PlayVideo } from "../components/public-site/playVideo/play.video";
import { VideoProvider } from "../context/site/video/video.state";

export const SingleVideo = props => {
  return (
    <VideoProvider>
      <PlayVideo {...props} />
    </VideoProvider>
  );
};
