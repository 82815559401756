import React, { useContext, useState } from "react";
import { AuthTitle, AuthDescription } from "./auth.components";
import { AuthForm } from "./auth.form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeadphonesAlt } from "@fortawesome/free-solid-svg-icons";
import ReactLoading from "react-loading";
import { AuthContext } from "../../context/auth/auth.state";
import { Redirect } from "react-router-dom";
import "../../styles/login.css";

export const Login = () => {
  let { user, doLogin, setError, redirect } = useContext(AuthContext);
  let [loading, setLoading] = useState(false);
  const handleDoLogin = async values => {
    setLoading(true);
    setError();
    await doLogin(values);
    // setLoading(false);
  };
  if (redirect) {
    return <Redirect to="/genres" />;
  }
  return (
    <div className="d-flex align-items-center justify-content-center mb-5 login-container mt-0">
      <div className="toast show login-toast">
        <div className="toast-body login-toast">
          <div className="mb-1 login-items">
            {loading && (
              <ReactLoading
                type="cubes"
                color="#768fad"
                height={"20%"}
                width={"10%"}
                className="mr-3 mt-3"
              />
            )}
            {!loading && (
              <FontAwesomeIcon
                className="login-icon mr-3 mt-1"
                icon={faHeadphonesAlt}
              />
            )}
            <AuthTitle title="IN BEAT" />
          </div>
          <AuthDescription
            txt="Entre su correo electrónico y su contraseña para acceder al sistema."
            hasError={user.error}
          />
          <AuthForm handleDoLogin={handleDoLogin} />
        </div>
      </div>
    </div>
  );
};
