import React, { useContext, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { HeaderLogin } from "../landing-page/components/header.login";
import { Footer } from "../landing-page/components/footer";

import { NewsContext } from "../../../context/site/news/news.state";
import { serverPath } from "../../../context/utils/file.path";
import { SiteAuthProvider } from "../../../context/site/siteAuth/site.auth.state";
import ReactHtmlParser from 'react-html-parser'
const urlContents = `${serverPath}news/`;

export const SingleNews = props => {
  const { current, getNewsById } = useContext(NewsContext);
  console.log(current)
  useEffect(() => {
    getNewsById(props.match.params.nid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if(Object.keys(current).length > 0) {
    current.date = current.news_date ? current.news_date.split(' ')[0] : ""
  }
  if (Object.keys(current).length > 0) {
    return (
      <>
        <SiteAuthProvider>
          <HeaderLogin />
        </SiteAuthProvider>
        <div className="single-news">
          <Container>
            <Row className="ml-1 mr-1">
              <Col lg={{ span: 8, offset: 2 }} className="mt-4 video-play-size">
                <div className="w-100 h-100 pos-relative">
                  {/* <img
                    src={`${urlContents}${current.image}`}
                    className="w-100 h-100"
                    alt="noticias"
                  /> */}
                  <div style={{width: "100%", height: "380px", backgroundImage: `url("${urlContents}${current.image}")`, backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}/>
                  <span
                    style={{
                      position: "absolute",
                      top: "0",
                      left: "0",
                      fontSize: "20pt",
                      fontFamily: "Monserrat",
                      color: "white",
                      fontWeight: "bold"
                    }}
                    className="ml-4 mt-4"
                  >
                    {/* Mi noticia favorita */}
                  </span>
                </div>
              </Col>
            </Row>
            <Row className="ml-1 mr-1">
              <Col lg={{ span: 8, offset: 2 }} className="mt-2 mb-2">
                <span
                  style={{
                    fontFamily: "Monserrat",
                    color: "black",
                    fontWeight: "bold",
                    fontSize: "16pt"
                  }}
                >
                  <p className="m-0 p-0" style={{fontSize: "12pt", fontWeight: 500 }}> 
                  {current.date}
                  </p>
                  {current.title}
                </span>
              </Col>
            </Row>
            <Row className="ml-1 mr-1">
              <Col
                lg={{ span: 8, offset: 2 }}
                className="mt-2 mb-4"
                style={
                  {
                    // borderTop: "1.5pt white solid",
                    // borderBottom: "1.5pt white solid",
                    // minHeight: "20vh",
                    // maxHeight: "20vh"
                  }
                }
              >
                <p
                  style={{
                    fontFamily: "Monserrat",
                    color: "black",
                    //   fontWeight: "bold",
                    fontSize: "16pt",
                    textAlign: "justify"
                  }}
                >
                  {ReactHtmlParser(current.body)}
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </>
    );
  }
  return <></>;
};
