import React, { createContext, useReducer } from "react";
import SiteAuthReducer from "./site.auth.reducer";
import { useHttpRequest } from "../../../hooks/useHttpRequest";
import { httpRequest } from "../../utils/http";

const initialState = {
  registered: false,
  registerError: false,
  registerErrorMsg: "",
  login: false,
  loginError: false,
  loginErrorMsg: "",
  logoutError: false,
  send: false,
  sendError: false,
  change: false,
  changeError: false
};

export const SiteAuthContext = createContext(initialState);

export const SiteAuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(SiteAuthReducer, initialState);
  const { execute } = useHttpRequest(httpRequest, false);

  const siteRegister = async user => {
    try {
      let formData = new FormData();
      formData.append("email", user.email);
      formData.append("login_type", 1);
      formData.append("rol_id", 2);
      formData.append("suscription_token", user.token);
      formData.append("name", user.name);
      formData.append("password", user.password);
      let result = await execute({
        url: "security/do_register",
        data: formData
      });
      console.log('vele vele', result)
      if (result.cod === 100) {
        // console.log("state result", result);
        dispatch({
          type: "SITE_REGISTER"
        });
      } else {
        let err = new Error(result.des);
        err.status = 400;
        err.msg = result.des;
        throw err;
      }
    } catch (e) {
      dispatch({
        type: "SITE_REGISTER_ERROR",
        payload: e.msg
      });
    }
  };
  const googleRegister = async user => {
    try {
      let formData = new FormData();
      formData.append("email", user.email);
      formData.append("login_type", 2);
      formData.append("rol_id", 2);
      formData.append("suscription_token", user.token);
      formData.append("name", user.name);
      formData.append("third_party_id", user.third_party_id);
      let result = await execute({
        url: "security/do_register",
        data: formData
      });
      if (result.cod === 100) {
        dispatch({
          type: "GOOGLE_REGISTER"
        });
      } else {
        let err = new Error(result.data.des);
        err.status = 400;
        err.msg = result.data.des;
        throw err;
      }
    } catch (e) {
      dispatch({
        type: "SITE_REGISTER_ERROR"
      });
    }
  };

  const facebookRegister = async user => {
    try {
      let formData = new FormData();
      formData.append("login_type", 3);
      formData.append("rol_id", 2);
      formData.append("suscription_token", user.token);
      formData.append("name", user.name);
      formData.append("third_party_id", user.third_party_id);
      let result = await execute({
        url: "security/do_register",
        data: formData
      });
      if (result.cod === 100) {
        dispatch({
          type: "FACEBOOK_REGISTER"
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      dispatch({
        type: "SITE_REGISTER_ERROR"
      });
    }
  };
  const doLogin = async user => {
    try {
      let formData = new FormData();
      formData.append("email", user.email);
      formData.append("password", user.password);
      const response = await httpRequest({
        url: "security/do_login",
        data: formData
      });
      if (response.data.cod !== 100) {
        let err = new Error(response.data.des);
        err.status = 400;
        err.msg = response.data.des;
        throw err;
      }
      dispatch({
        type: "DO_LOGIN",
        payload: response.data.data
      });
    } catch (error) {
      dispatch({
        type: "LOGIN_ERROR",
        payload: error.msg
      });
    }
  };
  const recoveryPass = async mail => {
    try {
      let formData = new FormData();
      formData.append("email", mail);

      const response = await httpRequest({
        url: "security/request_password_recovery",
        data: formData
      });
      if (response.data.cod !== 100) {
        let err = new Error(response.data.des);
        err.status = 400;
        err.msg = response.data.des;
        throw err;
      }
      dispatch({
        type: "REQUEST_PASSWORD_RECOVERY"
      });
    } catch (error) {
      dispatch({
        type: "REQUEST_PASSWORD_RECOVERY_ERROR"
      });
    }
  };
  const changePass = async (pass, token) => {
    try {
      let formData = new FormData();
      formData.append("password", pass);
      formData.append("token", token);

      const response = await httpRequest({
        url: "security/password_recovery",
        data: formData
      });
      if (response.data.cod !== 100) {
        let err = new Error(response.data.des);
        err.status = 400;
        err.msg = response.data.des;
        throw err;
      }
      dispatch({
        type: "PASSWORD_RECOVERY"
      });
    } catch (error) {
      dispatch({
        type: "PASSWORD_RECOVERY_ERROR"
      });
    }
  };
  const googleLogin = async user => {
    try {
      let formData = new FormData();
      formData.append("email", user.email);
      formData.append("third_party_id", user.third_party_id);
      const response = await httpRequest({
        url: "security/do_login",
        data: formData
      });
      if (response.data.cod !== 100) {
        let err = new Error(response.data.des);
        err.status = 400;
        err.msg = response.data.des;
        throw err;
      }
      dispatch({
        type: "DO_LOGIN",
        payload: response.data.data
      });
    } catch (error) {
      dispatch({
        type: "LOGIN_ERROR"
      });
    }
  };
  const facebookLogin = async user => {
    try {
      let formData = new FormData();
      formData.append("third_party_id", user.third_party_id);
      const response = await httpRequest({
        url: "security/do_login",
        data: formData
      });
      if (response.data.cod !== 100) {
        let err = new Error(response.data.des);
        err.status = 400;
        err.msg = response.data.des;
        throw err;
      }
      dispatch({
        type: "DO_LOGIN",
        payload: response.data.data
      });
    } catch (error) {
      dispatch({
        type: "LOGIN_ERROR"
      });
    }
  };
  const isAuthenticated = () =>
    JSON.parse(localStorage.getItem("main-site-inbeat")) &&
    JSON.parse(localStorage.getItem("main-site-inbeat")).token;

  const doLogout = async () => {
    try {
      let formData = new FormData();
      formData.append(
        "user_id",
        JSON.parse(localStorage.getItem("main-site-inbeat")).user_id
      );
      const response = await httpRequest({
        url: "security/do_logout",
        data: formData
      });
      if (response.data.cod !== 100) {
        let err = new Error(response.data.des);
        err.status = 400;
        err.msg = response.data.des;
        throw err;
      }
      dispatch({
        type: "DO_LOGOUT"
      });
    } catch (error) {
      dispatch({
        type: "LOGOUT_ERROR"
      });
    }
  };
  return (
    <SiteAuthContext.Provider
      value={{
        registered: state.registered,
        registerError: state.registerError,
        login: state.login,
        loginError: state.loginError,
        logoutError: state.logoutError,
        send: state.send,
        sendError: state.sendError,
        change: state.change,
        changeError: state.changeError,
        loginErrorMsg: state.loginErrorMsg,
        registerErrorMsg: state.registerErrorMsg,
        siteRegister,
        googleRegister,
        facebookRegister,
        isAuthenticated,
        doLogin,
        googleLogin,
        facebookLogin,
        doLogout,
        recoveryPass,
        changePass
      }}
    >
      {children}
    </SiteAuthContext.Provider>
  );
};
