import React, { createContext, useReducer } from "react";
import GenresReducer from "./genres.reducer";
import { useHttpRequest } from "../../hooks/useHttpRequest";
import { useModal } from "../../hooks/useModal";
import { useNotification } from "../../hooks/useNotification";
import { httpRequest } from "../utils/http";

const initialState = {
  categories: []
};

const notificationsMessages = {
  error: {
    msg: "Error. Oopps! Algo salió mal!",
    status: "dark"
  },
  delete: {
    msg: "Éxito. El género se ha eliminado satisfactoriamente!",
    status: "success"
  },
  activate: {
    msg: "Éxito. El género se ha activado satisfactoriamente!",
    status: "success"
  },
  add: {
    msg: "Éxito. El género se ha creado satisfactoriamente!",
    status: "success"
  }
};

export const GenresContext = createContext(initialState);

export const GenresProvider = ({ children }) => {
  const [state, dispatch] = useReducer(GenresReducer, initialState);
  const { execute, loading, error } = useHttpRequest(httpRequest, false);
  const { showModal, eid, show, action } = useModal();
  const { showNotification, notify, notificationConfig } = useNotification();

  const getCategories = async () => {
    try {
      let result = await execute({ url: "genres/get" });
      dispatch({
        type: "GET_CATEGORIES",
        payload: result.data
      });
    } catch (e) {
      showNotification(notificationsMessages.error);
    }
  };

  const doActions = async () => {
    try {
      showNotification();
      let formData = new FormData();
      formData.append("genre_id", eid);
      const endpoint = {
        delete: "genres/delete",
        activate: "genres/activate"
      };
      showModal();
      await execute({
        url: endpoint[action],
        data: formData
      });
      dispatch({
        type: "DO_ACTIONS",
        payload: { eid, action }
      });
      showNotification(notificationsMessages[action]);
    } catch (e) {
      showNotification(notificationsMessages.error);
    }
  };

  const addGenre = async name => {
    try {
      showNotification();
      let formData = new FormData();
      formData.append("genre_name", name);
      showModal();
      const result = await execute({
        url: "genres/create",
        data: formData
      });
      dispatch({
        type: "ADD_GENRE",
        payload: result.data
      });
      showNotification(notificationsMessages.add);
    } catch (error) {
      showNotification(notificationsMessages.error);
    }
  };

  return (
    <GenresContext.Provider
      value={{
        categories: state.categories,
        isLoading: loading,
        notify,
        notificationConfig,
        show,
        error,
        getCategories,
        showModal,
        showNotification,
        doActions,
        addGenre
      }}
    >
      {children}
    </GenresContext.Provider>
  );
};
