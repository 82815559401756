const getNews = (state, action) => {
  return {
    ...state,
    news: action.payload.filter(_ => _.status_id === 1)
  };
};
const getNewsById = (state, action) => {
  return {
    ...state,
    current: action.payload
  };
};

export default (state, action) => {
  const actions = {
    GET_NEWS: getNews,
    GET_NEWS_BY_ID: getNewsById
  };
  return actions[action.type](state, action) || state;
};
