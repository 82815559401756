import React from "react";
import { News } from "../components/public-site/news/news";
import { NewsProvider } from "../context/site/news/news.state";

export const NewsPage = () => {
  return (
    <NewsProvider>
      <News />
    </NewsProvider>
  );
};
