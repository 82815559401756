const config = {
  activate: {
    type: "confirmation",
    mainQuestion: "Está seguro?",
    description: "Está seguro que desea activar el contenido?",
    action: "delete"
  },
  delete: {
    type: "confirmation",
    mainQuestion: "Está seguro?",
    description: "Está seguro que desea eliminar el contenido?",
    action: "activate"
  },
  default: {
    type: "confirmation",
    mainQuestion: "",
    description: ""
  }
};

const getContents = (state, action) => {
  return {
    ...state,
    contents: action.payload
  };
};

const SetModalOption = (state, action) => {
  return {
    ...state,
    modalConfig: { ...config[action.payload.option], id: action.payload.id }
  };
};

const getContentCathalogs = (state, action) => {
  return {
    ...state,
    cathalogs: { ...action.payload }
  };
};

const addOrEditContent = (state, action) => {
  return {
    ...state,
    redirect: true
  };
};

const getContentToEdit = (state, action) => {
  let tempGenres = action.payload.toEdit.genres.map(_ => _.id);
  return {
    ...state,
    toEdit: { ...action.payload.toEdit, genres: tempGenres },
    cathalogs: { ...action.payload.cath },
    redirect: false
  };
};

const doActions = (state, action) => {
  let status = { activate: "Eliminado", delete: "Activo" };
  if (action.payload.action) {
    return {
      ...state,
      contents: state.contents.map(_ => {
        if (_.id === action.payload.eid) {
          let temp = { ..._ };
          temp.status_name = status[action.payload.action];
          return temp;
        }
        return _;
      }),
      modalConfig: config.default
    };
  }
  return { ...state };
};

export default (state, action) => {
  const actions = {
    GET_CONTENTS: getContents,
    SET_MODAL_OPTION: SetModalOption,
    GET_CONTENT_CATHALOGS: getContentCathalogs,
    GET_CONTENT_TO_EDIT: getContentToEdit,
    ADD_CONTENT: addOrEditContent,
    EDIT_CONTENT: addOrEditContent,
    DO_ACTIONS: doActions
  };
  return actions[action.type](state, action) || state;
};
