import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faVolumeOff,
  faTimes,
  faPause,
  faForward,
  faBackward
} from "@fortawesome/free-solid-svg-icons";
import "../landing-page/input.css";
import { serverPath } from "../../../context/utils/file.path";
const url = `${serverPath}contents/`;
export const PlayListMusicBox = ({
  showMusic,
  list,
  onClose,
  setCurrentIndexSong,
  currentIndexSong
}) => {
  const audio = useRef("audio_tag");
  const [statevolum, setStateVolum] = useState(0.3);
  const [dur, setDur] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [playing, setPlaying] = useState(true);
  // const [currentIndexSong, setCurrentIndexSong] = useState(0);

  const fmtMSS = s => {
    return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + ~~s;
  };
  const handleProgress = e => {
    let compute = (e.target.value * dur) / 100;
    setCurrentTime(compute);
    audio.current.currentTime = compute;
  };
  const handleVolume = q => {
    setStateVolum(q);
    audio.current.volume = q;
  };
  const handleClose = () => {
    showMusic(false);
    if (onClose) {
      onClose();
    }
  };
  const toggleAudio = () =>
    audio.current.paused ? audio.current.play() : audio.current.pause();
  const togglePlaying = () => setPlaying(!playing);
  const handleEnd = () => {
    if (currentIndexSong === Object.keys(list).length - 1) {
      setCurrentIndexSong(0);
    } else {
      setCurrentIndexSong(currentIndexSong + 1);
    }
  };
  const handleNextSong = () => {
    if (currentIndexSong === Object.keys(list).length - 1) {
      setCurrentIndexSong(0);
    } else {
      setCurrentIndexSong(currentIndexSong + 1);
    }
  };
  const handlePreviusSong = () => {
    if (currentIndexSong === 0) {
      setCurrentIndexSong(Object.keys(list).length - 1);
    } else {
      setCurrentIndexSong(currentIndexSong - 1);
    }
  };
  const playIcon = playing ? faPause : faPlay;
  return (
    <div
      style={{
        width: "90%",
        height: "45px",
        backgroundColor: "#13123d",
        position: "fixed",
        bottom: 0,
        // right: "20%",
        left: "5%",
        zIndex: 1000
        // padding: "10px"
      }}
      
    >
    <div className="d-flex align-items-center justify-content-center">
      <audio
        onTimeUpdate={e => setCurrentTime(e.target.currentTime)}
        onCanPlay={e => setDur(e.target.duration)}
        onEnded={handleEnd}
        ref={audio}
        type="audio/mpeg"
        preload="true"
        src={`${url}${list[currentIndexSong].content}`}
        autoPlay
      />
      <div className="vlme" style={{ marginLeft: "10px", marginRight: "5px" }}>
        
        <span className="volum">
          <FontAwesomeIcon
            icon={faVolumeOff}
            color="white"
            style={{ fontSize: "12pt" }}
          />
        </span>
        <input
          value={Math.round(statevolum * 100)}
          type="range"
          name="volBar"
          id="volBar"
          onChange={e => handleVolume(e.target.value / 100)}
        />
      </div>
      <FontAwesomeIcon
        icon={faBackward}
        color="white"
        style={{
          marginRight: "10px",
          fontSize: "10pt",
          marginLeft: "50px",
          cursor: "pointer"
        }}
        onClick={handlePreviusSong}
      />
      <FontAwesomeIcon
        icon={playIcon}
        color="white"
        style={{
          // marginRight: "40px",
          fontSize: "10pt",
          // marginLeft: "70px",
          cursor: "pointer"
        }}
        onClick={() => {
          togglePlaying();
          toggleAudio();
        }}
      />
      <FontAwesomeIcon
        icon={faForward}
        color="white"
        style={{
          // marginRight: "40px",
          fontSize: "10pt",
          marginLeft: "10px",
          cursor: "pointer"
        }}
        onClick={handleNextSong}
      />
      <div className="progressb" style={{ width: "40%" }}>
        <span
          className="currentT mr-1"
          style={{ color: "white", fontSize: "10px" }}
        >
          {fmtMSS(currentTime)}
        </span>
        <input
          type="range"
          name="progresBar"
          id="prgbar"
          style={{
            height: "3px",
            width: "80px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
          onChange={handleProgress}
          value={dur ? (currentTime * 100) / dur : 0}
        />
        <span
          className="totalT ml-1"
          style={{ color: "white", fontSize: "10px" }}
        >
          {fmtMSS(dur)}
        </span>
        <FontAwesomeIcon
          icon={faTimes}
          color="white"
          style={{
            fontSize: "11pt",
            marginLeft: "0",
            cursor: "pointer",
            marginRight: "0px",
            right: 10,
            position: "absolute"
          }}
          onClick={handleClose}
        />
      </div>
      </div>
      <div style={{color: "white", marginLeft:"1.1rem", lineHeight:"1", fontSize:"8pt"}}>
        {list[currentIndexSong].name} - {list[currentIndexSong].artist_name}
      </div>
    </div>
  );
};
