import React, { useContext, useEffect, useState } from "react";
import "./no.auth.css";
import { Carousel, Button, Row, Container, Col } from "react-bootstrap";
import { HeaderLogin } from "./components/header.login";

import next from "../../../images/carousel/FLECHA_DER.png";
import prev from "../../../images/carousel/FLECHA_IZQ.png";
import { Footer } from "./components/footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

import corazon from "../../../images/ICONO_CORAZON.png";
import newsIcon from "../../../images/MIC_ICON.png";
import headPhone from "../../../images/listened/AUDIFONOS_ICONO.png";

import { SiteAuthProvider } from "../../../context/site/siteAuth/site.auth.state";
import { LandingContext } from "../../../context/site/landing.state";
import { serverPath } from "../../../context/utils/file.path";
import { LandingMusicBox } from "./components/landing.music.box";

const urlContents = `${serverPath}contents/`;
const urlPromotions = `${serverPath}promotions/`;
const urlNews = `${serverPath}news/`;

const nextIcon = <img src={next} height="45" width="25" alt="Next" />;
const prevIcon = <img src={prev} height="45" width="25" alt="Prev" />;

const detailPromotedMap = {
  artist: "Ver artista",
  news: "Leer más",
  music: "Escuchar",
  video: "Reproducir"
};

const Detail = props => {
  if (props.table === "news" || props.table === "artist") {
    return (
      <a
        href={`#/site-${props.table}/${props.content_id}`}
        className="btn btn-light btn-sm details"
      >
        {detailPromotedMap[props.table]}
      </a>
    );
  }
  if (props.table === "video") {
    return (
      <>
        <Button
          variant="light"
          size="sm"
          className="details"
          onClick={() =>
            props.handlePlay(`${urlContents}${props.content}`, props.table)
          }
        >
          {detailPromotedMap[props.table]}
        </Button>
      </>
    );
  }
  return (
    <>
      <Button
        variant="light"
        size="sm"
        className="details"
        onClick={() =>
          props.handlePlay(`${urlContents}${props.content}`, props.table)
        }
      >
        {detailPromotedMap[props.table]}
      </Button>
    </>
  );
};
const stylePlay = {
  music: {
    bottom: "2.2%",
    left: "33px",
    color: "black"
  },
  video: {
    bottom: "3.5%",
    right: "41px",
    color: "white"
  }
};
export const Auth = () => {
  let {
    getPromotions,
    promotions,
    getMostVoted,
    mostVoted,
    getMostListened,
    mostListened,
    promotedNews,
    getPromotedNews
  } = useContext(LandingContext);
  const [showVideo, setshowVideo] = useState(false);
  const [showMusic, setshowMusic] = useState(false);
  let [currentSongInfo, setCurrentSongInfo] = useState({});
  const [style, setStyle] = useState(null);
  const [src, setSrc] = useState("");
  const handlePlay = (url, type, info) => {
    setSrc(url);
    if (type === "music") {
      setshowMusic(true);
      setStyle({ ...stylePlay["music"] });
      setshowVideo(false);
      setCurrentSongInfo(info)
    } else {
      setshowVideo(true);
      setStyle({ ...stylePlay[type] });
      setshowMusic(false);
    }
  };
  const handlePlayMusic = (url, type) => {
    setSrc(url);
    setshowMusic(true);
    setStyle({ ...stylePlay["music"] });
  };

  useEffect(() => {
    getPromotions();
    getMostVoted();
    getMostListened();
    getPromotedNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {showMusic && <LandingMusicBox showMusic={setshowMusic} src={src} info={currentSongInfo} />}
      <SiteAuthProvider>
        <HeaderLogin />
      </SiteAuthProvider>
      <div className="carousel">
        <Carousel nextIcon={nextIcon} prevIcon={prevIcon}>
          {promotions.map(promo => (
            <Carousel.Item key={promo.id}>
              <img
                className="d-block w-100"
                src={`${urlPromotions}${promo.picture}`}
                alt={`${promo.table}`}
                style={{ minHeight: "44vh" }}
              />
              <Carousel.Caption>
                <div className="d-flex align-items-center justify-content-end more-button">
                  {promo.table === "music" && (
                    <Detail {...promo} handlePlay={handlePlayMusic} />
                  )}
                  {promo.table === "video" && (
                    <Detail {...promo} handlePlay={handlePlay} />
                  )}
                  {promo.table === "artist" && <Detail {...promo} />}
                  {promo.table === "news" && <Detail {...promo} />}
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>
      {showVideo && (
        <div style={{ position: "relative" }}>
          <video
            controls
            name="media"
            style={{
              position: "fixed",
              bottom: 0,
              right: 0,
              zIndex: "10000",
              width: "50%"
            }}
            controlsList="nodownload"
          >
            <source src={src} />
          </video>
          <span
            style={{
              position: "fixed",
              ...style,
              zIndex: "20000",
              cursor: "pointer",
              fontWeight: "bold"
            }}
            onClick={() => {
              setshowVideo(false);
            }}
          >
            X
          </span>
        </div>
      )}

      <div
        className="top-rated"
        style={{ minHeight: "400px", backgroundColor: "#000000" }}
      >
        <Container className="mb-4">
          <Row>
            <Col
              lg={{ span: 6, offset: 6 }}
              xs={12}
              md="12"
              className="section-title"
            >
              <img
                src={corazon}
                height="35vh"
                width="30vw"
                alt="corazon"
                className="ml-1"
              />

              <span className="ml-3">Los más votados</span>
            </Col>
          </Row>
        </Container>
        <Container className="mt-0">
          <Row style={{ marginTop: "1rem" }}>
            {mostVoted.map((content, index) => {
              let lg = 3;
              if (index < 4) {
                return (
                  <Col
                    lg={lg}
                    md="6"
                    xs={6}
                    className=""
                    key={content.id}
                    style={{ height: "fit-content" }}
                  >
                    {/* <img
                      src={`${urlContents}${content.image}`}
                      alt="corazon"
                      width="100%"
                      style={{
                        minHeight: "22vh",
                        maxHeight: "22vh",
                        cursor: "pointer"
                      }}
                      title="Reproducir"
                      onClick={() => {
                        handlePlay(
                          `${urlContents}${content.content}`,
                          content.type,
                          content
                        )
                      }
                      }
                    /> */}
                    <div style={{width:"100%", minHeight: "35vh", maxHeight: "35vh", backgroundImage: `url("${urlContents}${content.image}")`, backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundSize: "cover", cursor: "pointer" }}
                    title="Reproducir"
                    onClick={() => {
                      handlePlay(
                        `${urlContents}${content.content}`,
                        content.type,
                        content
                      )
                    }
                    }
                    />

                    <span className="d-flex align-items-center justify-content-center tag-votes">
                      <FontAwesomeIcon
                        icon={faHeart}
                        color="red"
                        className="mr-1"
                      />
                      {content.votes}
                    </span>

                    <p
                      className="mv-artist-content-name pb-0 mb-0"
                      style={{ fontWeight: "bold", fontSize: "10pt" }}
                    >
                      {content.name}
                    </p>
                    <p
                      className="mv-artist-name"
                      style={{
                        marginBottom: "1rem",
                        fontWeight: "normal",
                        lineHeight: ".5"
                      }}
                    >
                      <a
                        href={`#/site-artist/${content.artist_id}`}
                        style={{ fontWeight: "normal", fontSize: "8pt" }}
                      >
                        {content.artist_name}
                      </a>
                    </p>
                  </Col>
                );
              }
              return <></>
            })}
          </Row>
        </Container>
      </div>
      <div className="most-listened" style={{ backgroundColor: "#000000" }}>
        <Container>
          <Row>
            <Col
              lg={{ span: 7, offset: 6 }}
              md="12"
              xs="12"
              className="section-title"
            >
              <img src={headPhone} height="43vh" width="43vw" alt="corazon" />

              <span className="ml-3">5 más escuchados</span>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="mt-4">
            {mostListened.length > 0 && (
              <Col lg={{ span: 8, offset: 0 }} className="most-ranked mb-4" style={{backgroundImage: `url("${urlContents}${mostListened[0].image}")`, backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundSize: "cover", cursor: "pointer" }} title="Reproducir"
              onClick={() =>
                handlePlay(
                  `${urlContents}${mostListened[0].content}`,
                  mostListened[0].type,
                  mostListened[0]
                )
              }
              >
                <p className="mv-artist-content-names m-0 mt-4 font-weight-bold">
                <span style={{fontSize: "14pt", color: "white"}}>#1 </span>
                  <a
                    href={`#/site-artist/${mostListened[0].artist_id}`}
                    style={{ fontSize: "14pt", color: "white" }}
                  >
                    
                    {mostListened[0].name}
                  </a>
                </p>
                <p
                  className="mv-artist-content-name m-0 p-0"
                  style={{ fontSize: "12pt" }}
                >
                  {mostListened[0].artist_name}
                </p>
              </Col>
            )}
            <Col lg="4">
              <Row>
                {mostListened.map((content, index) => {
                  if (index > 0) {
                    return (
                      <React.Fragment key={content.id}>
                        <Col
                          lg="12"
                          xs={6}
                          style={{ paddingLeft: "5px", paddingRight: "5px" }}
                          className=""
                        >
                          <Col lg="12" className="img-listened mb-2 pr-0 pl-0" style={{height: "150px", backgroundImage: `url("${urlContents}${content.image}")`, backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundSize: "cover", cursor: "pointer" }}
                          onClick={() =>
                            handlePlay(
                              `${urlContents}${content.content}`,
                              content.type,
                              content
                            )
                          }
                          title="Reproducir"
                          >
                          </Col>
                          <Col lg="5">
                          
                            <p className="mv-artist-content-name m-0 p-0 pb-2 font-weight-bold">
                              <a
                                href={`#/site-artist/${content.artist_id}`}
                                style={{
                                  color: "white",
                                  fontSize: "8pt",
                                  paddingBottom: "1vh",
                                  paddingTop: 0
                                }}
                              >
                                <span style={{fontSize: "12pt"}}>#{index + 1}</span> {content.name} - {content.artist_name}
                              </a>
                            </p>
                          </Col>
                        </Col>
                      </React.Fragment>
                    );
                  }
                  return <React.Fragment key={content.id}></React.Fragment>;
                })}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        className="news-sections"
        style={{
          backgroundColor: "#000000",
          minHeight: "auto",
          height: "auto",
          paddingBottom: "10px"
        }}
      >
        <Container>
          <Row>
            <Col
              lg={{ span: 4, offset: 8 }}
              md="12"
              xs="12"
              className="section-title mb-4"
            >
              <img
                src={newsIcon}
                height="43vh"
                width="43vw"
                alt="corazon"
                className="ml-1"
              />

              <span className="ml-3">Noticias</span>
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="">
            {promotedNews.map((news, index) => {
              let lg = index === 0 ? { span: 3, offset: 0 } : 3;
              return (
                <Col lg={lg} md="6" xs={6} className="mb-4" key={news.id} style={{ height: "fit-content" }}>
                  <div style={{width:"100%", minHeight: "35vh", maxHeight: "35vh", backgroundImage: `url("${urlNews}${news.image}")`, backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}/>
                  <p className="mv-artist-name">
                    <a href={`#/site-news/${news.id}`}>{`${news.title.substring(0,30)} ...`}</a>
                  </p>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};
